import React, { useState } from "react";

import api from "lib/api";
import { useHistory } from "react-router";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import compress from "lib/compress";

export default function AddClient() {
  const [values, setValues] = useState({
    name: "",
    logo: null,
    logo_url: "",
    address: "",
    payment_term: "forward_dating",
  });
  const [valuesClientUser, setValuesClientUser] = useState({ user_name: "", user_email: "", user_phone_number: "" });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  // const { id } = useParams();
  const { handleError, handleSuccess } = useError();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleChangeClient = (e) => {
    setValuesClientUser({ ...valuesClientUser, [e.target.name]: e.target.value });
  };
  const onFileChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files[0] });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formName = {
      name: "Client Name",
      logo: "Client Logo",
      address: "Client Address",
      user_name: "User Name",
      user_email: "User Email",
      user_phone_number: "User Phone Number",
    };
    const findEmptyKeys = (obj) => {
      let emptyKeys = [];
      for (let key in obj) {
        if (key !== "logo_url" && key !== "logo" && key !== "address" && (obj[key] === "" || obj[key] === null)) {
          emptyKeys.push(key);
        }
      }
      return emptyKeys;
    };

    const emptyValuesKeys = findEmptyKeys(values);
    const emptyClientKeys = findEmptyKeys(valuesClientUser);
    const combineEmptyValue = [...emptyValuesKeys, ...emptyClientKeys].map((item) => formName[item]);

    if (combineEmptyValue.length > 0) {
      handleError({
        message: `${combineEmptyValue.join(", ")} is required`,
      });
      setLoading(false);
    } else {
      const compressedImage = values.logo ? await compress(values.logo) : null;
      const { err, data } = await api.controllers.createClient({
        name: values.name,
        logo: compressedImage,
        address: values.address,
        payment_term: values.payment_term,
      });

      if (err) {
        handleError(err);
      } else {
        const { err } = await api.controllers.createClientUser(data.data.id, {
          name: valuesClientUser.user_name,
          email: valuesClientUser.user_email,
          phone_number: valuesClientUser.user_phone_number,
        });
        setLoading(false);

        if (err) {
          handleError(err);
        } else {
          handleSuccess("Client created");
          history.push("/clients");
        }
      }
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-slate-700 text-xl font-bold">Add client</h6>
            <button
              className="flex bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              onClick={(e) => onSubmit(e)}
            >
              {loading && <Spinner size={4} />} Save
            </button>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={onSubmit} className="grid lg:grid-cols-2 gap-10">
            {/* left  */}
            <div className="">
              <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">Client Information</h6>
              <div className="flex flex-wrap w-full ">
                <div className="w-full">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                      Name{" "}
                      <span className="text-red-500">
                        <sup>*</sup>
                      </span>
                    </label>
                    <input
                      name="name"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Yippy"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="w-full">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Logo</label>
                    <input
                      name="logo"
                      type="file"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow w-full"
                      onChange={onFileChange}
                      accept="image/png, image/jpeg"
                    />
                    <p className="text-xs text-gray-400 mt-2">PNG, JPG up to 2MB</p>

                    {values.logo_url && <img src={values.logo_url} alt="logo" className={"h-24 aspect-square"} />}
                  </div>
                </div>

                <div className="w-full">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Address</label>
                    <input
                      name="address"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Jalan "
                      value={values.address}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* <div className="w-full">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Payment Term</label>
                  <select
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="payment_term"
                    onChange={handleChange}
                    value={values.payment_term}
                  >
                    <option value="advance">Advance</option>
                    <option value="forward_dating">Forward Dating</option>
                  </select>
                </div>
              </div> */}
              </div>
            </div>

            {/* right  */}
            <div className="">
              <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">User Information</h6>
              <div className="flex flex-wrap w-full">
                <div className="w-full lg:w-12/12">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                      Name{" "}
                      <span className="text-red-500">
                        <sup>*</sup>
                      </span>
                    </label>
                    <input
                      name="user_name"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Example"
                      value={valuesClientUser.user_name}
                      onChange={handleChangeClient}
                    />
                  </div>
                </div>

                <div className="w-full lg:w-12/12">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                      Email{" "}
                      <span className="text-red-500">
                        <sup>*</sup>
                      </span>
                    </label>
                    <input
                      name="user_email"
                      type="email"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="email@example.com"
                      value={valuesClientUser.user_email}
                      onChange={handleChangeClient}
                    />
                  </div>
                </div>

                <div className="w-full lg:w-12/12">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                      Phone Number{" "}
                      <span className="text-red-500">
                        <sup>*</sup>
                      </span>
                    </label>
                    <input
                      name="user_phone_number"
                      type="tel"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="08123456789"
                      value={valuesClientUser.user_phone_number}
                      onChange={handleChangeClient}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
