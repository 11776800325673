import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: typeof process !== "undefined" ? `${process.env.REACT_APP_API_BASE_URL}` : "/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (error.response) {
      const { status } = error.response;
      if (status === 401 && originalRequest.url !== "/settings/tokens") {
        Cookies.remove("token");
        delete api.defaults.headers.Authorization;
        window.location.reload();
      }
    }

    return Promise.reject(error);
  }
);

async function get(url, params, options) {
  try {
    params = params || {};
    options = options || {};
    const res = await api.get(url, {
      params: params,
      ...options,
    });

    return {
      err: null,
      data: res?.data,
      res: res,
    };
  } catch (error) {
    const res = error.response;
    var message = error.message;

    return {
      err: {
        message: message,
      },
      data: res ? res?.data : null,
      res: res,
    };
  }
}

const req = async (method, url, data, options) => {
  try {
    const res = await api[method](url, data, options);
    return {
      err: null,
      data: res?.data,
      res: res,
    };
  } catch (error) {
    const res = error.response;
    const data = res?.data;
    const errors = data?.errors ?? null;

    var errMessage = null;
    if (res?.status >= 400 && res?.status < 500) {
      if (errors) {
        for (const prop in errors) {
          if (errors.hasOwnProperty(prop)) {
            for (let i = 0; i < prop.length; i++) {
              errMessage = errors[prop];
              break;
            }
          }
          if (errMessage) break;
        }
      } else {
        for (const prop in data) {
          if (data?.hasOwnProperty(prop)) {
            for (let i = 0; i < prop.length; i++) {
              errMessage = data[prop][0];
              break;
            }
          }
          if (errMessage) break;
        }
      }
    }
    errMessage = errMessage ? errMessage : error.message;

    return {
      err: {
        message: errMessage,
      },
      data: res ? res?.data : null,
      res: res,
    };
  }
};
async function post(url, data, options) {
  return await req("post", url, data, options);
}
async function put(url, data, options) {
  return await req("put", url, data, options);
}
async function patch(url, data, options) {
  return await req("patch", url, data, options);
}
async function del(url, options) {
  return await req("delete", url, {}, options);
}

api.controllers = {
  createToken: (email, password) => {
    return post("/settings/tokens", {
      email: email,
      password: password,
    });
  },
  me: () => {
    return get("/settings/superadmin/staffs/me");
  },

  // STAFF
  getStaff: (id) => {
    return get(`/settings/superadmin/staffs/${id}`);
  },
  getStaffs: (page, limit) => {
    return get(`/settings/superadmin/staffs?page=${page}&limit=${limit}`);
  },
  createStaff: (params) => {
    return post("/settings/superadmin/staffs", params);
  },
  updateStaff: (id, params) => {
    return put(`/settings/superadmin/staffs/${id}`, params);
  },
  getRecipient: (id, recipientId) => {
    return get(`/recipients/superadmin/clients/${id}/recipients/${recipientId}`);
  },
  getRecipients: (page, limit, filters) => {
    var params = {
      page: page || 1,
      limit: limit || 10,
    };
    var _filters = filters || {};
    params = { ...params, ..._filters };
    return get(`/orders/superadmin/order-recipients`, params);
  },
  updateRecipient: (id, recipientId, params) => {
    const values = {
      name: params.name,
      birthdate: params.birthdate,
      onboarding_date: params.onboarding_date,
      active: params.active,
      ...(params.phone_number.length > 2 && { phone_number: params.phone_number }),
      ...(params.email.length > 2 && { email: params.email }),
      ...(params.default_address && { default_address: params.default_address }),
    };
    return put(`/recipients/superadmin/clients/${id}/recipients/${recipientId}`, values);
  },

  // CLIENTS
  getClients: (page, limit, filters) => {
    var params = {
      page: page || 1,
      limit: limit || 10,
    };
    var _filters = filters || {};
    params = { ...params, ..._filters };

    return get(`/clients/superadmin/clients`, params);
  },
  getClient: (id) => {
    return get(`/clients/superadmin/clients/${id}`);
  },
  deleteClient: (id) => {
    return del(`/clients/superadmin/clients/${id}`);
  },
  createClient: (params) => {
    var formData = new FormData();
    formData.append("name", params.name);
    if (params.logo) {
      formData.append("logo", params.logo);
    }
    formData.append("address", params.address);
    formData.append("payment_term", params.payment_term);

    return post(`/clients/superadmin/clients`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateClient: (id, params) => {
    var formData = new FormData();
    formData.append("name", params.name);
    if (params.logo) {
      formData.append("logo", params.logo);
    }
    formData.append("address", params.address);
    formData.append("payment_term", params.payment_term);

    return post(`/clients/superadmin/clients/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  createClientUser: (id, params) => {
    return post(`/clients/superadmin/clients/${id}/users`, params);
  },
  getClientUsers: (clientId, page, limit) => {
    var params = {
      page: page || 1,
      limit: limit || 10,
    };

    return get(`/clients/superadmin/users?client_id=${clientId}`, params);
  },
  getClientRecipients: (clientId, page, limit, filters) => {
    var params = {
      page: page || 1,
      limit: limit || 10,
    };
    var _filters = filters || {};
    params = { ...params, ..._filters };

    return get(`/recipients/superadmin/clients/${clientId}/recipients`, params);
  },
  filterClientRecipients: (clientId, params) => {
    return post(`/recipients/superadmin/clients/${clientId}/recipients/list?limit=-1`, params);
  },
  importClientRecipients: (clientId, params) => {
    var formData = new FormData();
    formData.append("file", params.file);

    return post(`/recipients/superadmin/clients/${clientId}/recipients/import`, formData);
  },

  // GROUPS
  getGroups: (clientId, page, limit) => {
    return get(`/recipients/superadmin/clients/${clientId}/groups?page=${page}&limit=${limit}`);
  },
  getGroupRecipients: (clientId, groupId, page, limit, filters) => {
    var params = {
      page: page || 1,
      limit: limit || 10,
    };
    var _filters = filters || {};
    params = { ...params, ..._filters };

    return get(`/recipients/superadmin/clients/${clientId}/groups/${groupId}/recipients`, params);
  },

  // CATEGORIES
  getCategories: ({ page, limit, parent, subcategories }) => {
    var params = {
      page: page || 1,
      limit: limit || 10,
    };
    if (typeof parent !== "undefined") {
      params.parent = parent;
    }
    if (typeof subcategories !== "undefined") {
      params.subcategories = 1;
    }

    return get(`/products/superadmin/categories`, params);
  },
  importCategories: (params) => {
    var formData = new FormData();
    formData.append("file", params.file);

    return post(`/products/superadmin/categories/import`, formData);
  },
  getCategory: (id) => {
    return get(`/products/superadmin/categories/${id}`);
  },
  createCategory: (params) => {
    if (!params.icon) {
      if (params.parent_id) {
        let param = { name: params.name, type: params.type, parent_id: params.parent_id };
        return post("/products/superadmin/categories", param);
      } else {
        let param = { name: params.name, type: params.type };
        return post("/products/superadmin/categories", param);
      }
    }
    var formData = new FormData();
    formData.append("name", params.name);
    formData.append("type", params.type);
    formData.append("icon", params.icon);
    if (params.parent_id) {
      formData.append("parent_id", params.parent_id);
    }
    return post("/products/superadmin/categories", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteCategory: (id) => {
    return del(`/products/superadmin/categories/${id}`);
  },
  updatecategory: (id, params) => {
    var formData = new FormData();
    formData.append("name", params.name);
    formData.append("type", params.type);
    formData.append("active", params.active);
    if (params.parent_id) {
      formData.append("parent_id", params.parent_id);
    }
    if (params.icon instanceof File || params.icon instanceof Blob) {
      formData.append("icon", params.icon);
    }
    return post(`/products/superadmin/categories/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getVariantTypes: ({ page, limit, categoryId }) => {
    var params = {
      page: page || 1,
      limit: limit || 10,
    };

    return get(`/products/superadmin/categories/${categoryId}/variation-types`, params);
  },

  // TAGS
  getTag: (id) => {
    return get(`/products/superadmin/tags/${id}`);
  },
  getTags: (page, limit, params) => {
    let _params = {
      ...{
        page: page,
        limit: limit,
      },
      ...params,
    };
    return get(`/products/superadmin/tags`, _params);
  },
  createTag: (params) => {
    var formData = new FormData();
    formData.append("name", params.name);
    formData.append("active", params.active);
    formData.append("position", params.position);
    if (params.icon instanceof File || params.icon instanceof Blob) {
      formData.append("icon", params.icon);
    }
    if (params.image instanceof File || params.image instanceof Blob) {
      formData.append("image", params.image);
    }
    return post("/products/superadmin/tags", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateTag: (id, params) => {
    var formData = new FormData();
    formData.append("name", params.name);
    formData.append("active", params.active);
    formData.append("position", params.position);
    if (params.icon instanceof File || params.icon instanceof Blob) {
      formData.append("icon", params.icon);
    }
    if (params.image instanceof File || params.image instanceof Blob) {
      formData.append("image", params.image);
    }
    return post(`/products/superadmin/tags/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  // BRANDS
  getBrands: ({ page, limit }) => {
    var params = {
      page: page || 1,
      limit: limit || 10,
    };
    return get(`/products/superadmin/brands`, params);
  },
  getBrand: (id) => {
    return get(`/products/superadmin/brands/${id}`);
  },
  createBrand: (params) => {
    var formData = new FormData();
    formData.append("name", params.name);
    formData.append("description", params.description);
    formData.append("color", params.color);
    if (params.logo instanceof File || params.logo instanceof Blob) {
      formData.append("logo", params.logo);
    }
    return post(`/products/superadmin/brands`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateBrand: (id, params) => {
    var formData = new FormData();
    formData.append("name", params.name);
    formData.append("description", params.description);
    formData.append("color", params.color);
    formData.append("active", params.active);
    if (params.logo instanceof File || params.logo instanceof Blob) {
      formData.append("logo", params.logo);
    }
    return post(`/products/superadmin/brands/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteBrand: (id) => {
    return del(`/products/superadmin/brands/${id}`);
  },

  // VOUCHER
  getVoucher: (id) => {
    return get(`/orders/superadmin/vouchers/${id}`);
  },
  getVouchers: (page, limit) => {
    return get(`/orders/superadmin/vouchers?page=${page}&limit=${limit}`);
  },
  createVoucher: (params) => {
    params.code = params.code.toLowerCase();
    return post("/orders/superadmin/vouchers", params);
  },
  updateVoucher: (id, params) => {
    params.code = params.code.toLowerCase();
    return put(`/orders/superadmin/vouchers/${id}`, params);
  },

  // PRODUCTS
  getProductCategories: (params) => {
    return get(`/products/superadmin/products/categories`, params);
  },
  getProductSubcategories: (id, params) => {
    return get(`/products/superadmin/products/categories/${id}/subcategories`, params);
  },
  getProduct: (id) => {
    return get(`/products/superadmin/products/${id}`);
  },
  getProducts: (params) => {
    let _params = {
      ...{
        page: 1,
        limit: 20,
      },
      ...params,
    };

    return get(`/products/superadmin/products`, _params);
  },
  downloadProducts: (params) => {
    let _params = {
      ...{
        page: 1,
        limit: 20,
      },
      ...params,
    };

    return get(`/products/superadmin/products/download`, _params);
  },

  createProduct: (params) => {
    var formData = new FormData();
    if (params.client_id) {
      formData.append("client_id", params.client_id);
    }
    formData.append("name", params.name);
    formData.append("sku", params.sku);
    formData.append("category_id", params.category_id);
    formData.append("description", params.description);

    for (var i = 0; i < params.images.length; i++) {
      formData.append("images[]", params.images[i]);
    }

    formData.append("discount", params.discount);
    formData.append("price", params.price);
    formData.append("weight", params.weight);
    formData.append("min_quantity", params.min_quantity);
    formData.append("external_url", params.external_url);
    formData.append("purchase_price", params.purchase_price);
    formData.append("margin", params.margin);
    formData.append("additional_margin", params.additional_margin);
    formData.append("internal_notes", params.internal_notes);
    formData.append("video_url", params.video_url);
    formData.append("quantity", params.quantity);
    formData.append(`custom_design`, params.custom_design ? 1 : 0);
    formData.append(`merchandise`, params.merchandise ? 1 : 0);
    formData.append(`personal`, params.personal ? 1 : 0);
    formData.append(`digital`, params.digital ? 1 : 0);
    formData.append("production_time", params.production_time);
    if (params.provider_product_code) {
      formData.append("provider_product_code", params.provider_product_code);
    }
    if (params.provider) {
      formData.append("provider", params.provider);
    }

    for (let i = 0; i < params.variations.length; i++) {
      var variation = params.variations[i];

      formData.append(`variations[${i}][name]`, variation.name);
      formData.append(`variations[${i}][required]`, variation.required ? 1 : 0);
      formData.append(`variations[${i}][recipient_variation]`, variation.recipient_variation ? 1 : 0);
      formData.append(`variations[${i}][sync]`, variation.sync ? 1 : 0);

      for (var j = 0; j < variation.options.length; j++) {
        formData.append(`variations[${i}][options][${j}][name]`, variation.options[j].name);
        formData.append(`variations[${i}][options][${j}][price]`, variation.options[j].price);
      }
    }
    for (let i = 0; i < params.tags.length; i++) {
      var tag = params.tags[i];
      formData.append(`tags[${i}][id]`, tag.id);
    }

    return post("/products/superadmin/products", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateProduct: (id, params) => {
    return put(`/products/superadmin/products/${id}`, params);
  },
  updateBulkPrice: (id, params) => {
    return put(`/products/superadmin/products/${id}/prices`, params);
  },
  deleteProduct: (id) => {
    return del(`/products/superadmin/products/${id}`);
  },
  importProduct: (params) => {
    var formData = new FormData();
    formData.append("file", params.file);

    return post(`/products/superadmin/products/import`, formData);
  },

  getImages: (id) => {
    return get(`/products/superadmin/products/${id}/images`);
  },
  setImageFeature: (id, imageId) => {
    return put(`/products/superadmin/products/${id}/images/${imageId}/feature`);
  },
  addProductImage: (id, params) => {
    var formData = new FormData();
    formData.append("image", params.image);
    // for (var i = 0; i < params.images.length; i++) {
    //   formData.append("image", params.images[i]);
    // }

    return post(`/products/superadmin/products/${id}/images`, formData);
  },
  removeProductImage: (id, imageId) => {
    return del(`/products/superadmin/products/${id}/images/${imageId}`);
  },
  getProductVariations: (id, { page, limit }) => {
    const params = { page: page, limit: limit };

    return get(`/products/superadmin/products/${id}/variations`, params);
  },

  // ORDERS
  getOrderSummary: () => {
    return get(`/orders/superadmin/orders/summary`);
  },
  getOrderReviews: (page, limit, params) => {
    let _params = {
      ...{
        page: page,
        limit: limit,
      },
      ...params,
    };
    return get(`/orders/superadmin/orders/reviews`, _params);
  },
  getOrders: (page, limit, filters) => {
    var params = {
      page: page || 1,
      limit: limit || 10,
    };
    var _filters = filters || {};
    params = { ...params, ..._filters };
    return get(`/orders/superadmin/orders`, params);
  },
  getOrder: (id) => {
    return get(`/orders/superadmin/orders/${id}`);
  },
  getOrderSchedule: (id, params) => {
    // start_date
    // end_date
    return get(`/orders/superadmin/orders/${id}/recipients/schedules`, params);
  },
  deleteOrder: (id) => {
    return del(`/orders/superadmin/orders/${id}`);
  },
  getOrderProducts: (id) => {
    return get(`/orders/superadmin/orders/${id}/products`);
  },
  getOrderRecipients: (id, params) => {
    return get(`/orders/superadmin/orders/${id}/recipients`, params);
  },
  addOrderRecipients: (id, params) => {
    return post(`/orders/superadmin/orders/${id}/recipients`, {
      recipients: params,
    });
  },
  removeOrderRecipient: (id, recipientId) => {
    return del(`/orders/superadmin/orders/${id}/recipients/${recipientId}`);
  },
  notifOrderRecipient: (id, recipientId) => {
    return post(`/orders/superadmin/orders/${id}/recipients/${recipientId}/notifications`);
  },
  updateOrderRecipient: (id, recipientId, params) => {
    return put(`/orders/superadmin/orders/${id}/recipients/${recipientId}`, params);
  },
  importOrderRecipient: (id, params) => {
    var formData = new FormData();
    formData.append("file", params.file);

    return post(`/orders/superadmin/orders/${id}/recipients/import`, formData);
  },
  importOrderRecipientStatuses: (id, params) => {
    var formData = new FormData();
    formData.append("file", params.file);

    return post(`/orders/superadmin/orders/${id}/recipients/import-statuses`, formData);
  },
  downloadOrderRecipients: (id) => {
    return get(
      `/orders/superadmin/orders/${id}/recipients/download`,
      {},
      {
        responseType: "blob",
      }
    );
  },
  getOrderConditions: (id) => {
    return get(`/orders/superadmin/orders/${id}/conditions`);
  },
  updateOrderRecipientProcurring: (id, recipientId) => {
    return put(`/orders/superadmin/orders/${id}/recipients/${recipientId}`, {
      procurement_status: "procuring",
    });
  },
  addOrderConditions: (id, params) => {
    return post(`/orders/superadmin/orders/${id}/conditions`, params);
  },
  createOrder: (params) => {
    return post("/orders/superadmin/orders", params);
  },
  patchOrder: (id, params) => {
    return patch(`/orders/superadmin/orders/${id}`, params);
  },
  updateOrderVoucher: (id, params) => {
    return put(`/orders/superadmin/orders/${id}/voucher`, params);
  },
  deleteOrderVoucher: (id) => {
    return del(`/orders/superadmin/orders/${id}/voucher`);
  },
  deleteOrderProduct: (id, idProduct) => {
    return del(`/orders/superadmin/orders/${id}/products/${idProduct}`);
  },
  addOrderProduct: (id, params) => {
    return post(`/orders/superadmin/orders/${id}/products`, params);
  },
  downloadInvoice: (id) => {
    return get(
      `/orders/superadmin/orders/${id}/invoice`,
      {},
      {
        responseType: "blob",
      }
    );
  },
  paymentOrder: (id) => {
    return post(`/orders/superadmin/orders/${id}/payment-links`);
  },
  updateDesign: (id, productId, params) => {
    var formData = new FormData();
    formData.append("design", params.design);

    return post(`/orders/superadmin/orders/${id}/products/${productId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateOrderNotification: (id, params) => {
    var formData = new FormData();
    if (params.notification_lang) {
      formData.append("notification_lang", params.notification_lang);
    }
    if (params.notification_image) {
      formData.append("notification_image", params.notification_image);
    }
    if (params.notification_title) {
      formData.append("notification_title", params.notification_title);
    }
    if (params.notification_body) {
      formData.append("notification_body", params.notification_body);
    }
    if (params.notification_action) {
      formData.append("notification_action", params.notification_action);
    }

    return post(`/orders/superadmin/orders/${id}/notification`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  publishOrder: (id) => {
    return post(`/orders/superadmin/orders/${id}/publish`);
  },
  duplicateOrder: (id, values) => {
    return post(`/orders/superadmin/orders/${id}/duplicate`, values);
  },
  addRecipientOrder: (id, params) => {
    return post(`/orders/superadmin/orders/${id}/recipients/append`, params);
  },

  // PICKUP
  getPickups: (id) => {
    return get(`/orders/superadmin/orders/${id}/pickups`);
  },
  getLalamoveQuotation: (id, params) => {
    return post(`/orders/superadmin/orders/${id}/pickups/lalamove-quotation`, params);
  },
  requestPickup: (id, params) => {
    return post(`/orders/superadmin/orders/${id}/pickups`, params);
  },
  requestShippingPrice: (id, params) => {
    return post(`/orders/superadmin/orders/${id}/pickups/kiriminaja-shipping-price`, params);
  },
  requestKiriminAjaCouriers: (id, params) => {
    return post(`/orders/superadmin/orders/${id}/pickups/kiriminaja-couriers`, params);
  },
  getPickupSchedules: () => {
    return get(`/orders/superadmin/orders/pickups/schedules`);
  },
  downloadShippingLabelMulti: (id, pickupId) => {
    return get(
      `/orders/superadmin/orders/${id}/pickups/${pickupId}/label`,
      {},
      {
        responseType: "blob",
      }
    );
  },
  downloadShippingLabel: (id, recipientId) => {
    return get(
      `/orders/superadmin/orders/${id}/recipients/${recipientId}/label`,
      {},
      {
        responseType: "blob",
      }
    );
  },

  // PACKAGES
  getPackages: (page, limit, filters) => {
    var params = {
      page: page || 1,
      limit: limit || 10,
    };
    var _filters = filters || {};
    params = { ...params, ..._filters };

    return get(`/products/superadmin/packages`, params);
  },
  getPackage: (id) => {
    return get(`/products/superadmin/packages/${id}`);
  },
  deletePackage: (id) => {
    return del(`/products/superadmin/packages/${id}`);
  },
  createPackage: (params) => {
    var formData = new FormData();
    formData.append("name", params.name);
    formData.append("position", params.position);
    formData.append("tag_id", params.tag_id);
    if (params.image) {
      formData.append("image", params.image);
    }
    if (params.description) {
      formData.append("description", params.description);
    }

    return post(`/products/superadmin/packages`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updatePackage: (id, params) => {
    var formData = new FormData();
    formData.append("name", params.name);
    formData.append("position", params.position);
    formData.append("tag_id", params.tag_id);
    if (params.image) {
      formData.append("image", params.image);
    }
    if (params.description) {
      formData.append("description", params.description);
    }

    return post(`/products/superadmin/packages/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  addPackageProduct: (id, productId, params) => {
    return post(`/products/superadmin/packages/${id}/products/${productId}`, params);
  },
  deletePackageProduct: (id, productId) => {
    return del(`/products/superadmin/packages/${id}/products/${productId}`);
  },

  // PROPOSALS
  getProposals: (page, limit, filters) => {
    var params = {
      page: page || 1,
      limit: limit || 10,
    };
    var _filters = filters || {};
    params = { ...params, ..._filters };

    return get(`/products/superadmin/proposals`, params);
  },
  createProposal: (params) => {
    var formData = new FormData();
    formData.append("name", params.name);
    formData.append("client_name", params.client_name);
    if (params.logo) {
      formData.append("logo", params.logo);
    }

    return post(`/products/superadmin/proposals`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateProposal: (id, params) => {
    var formData = new FormData();
    formData.append("name", params.name);
    formData.append("client_name", params.client_name);
    if (params.logo) {
      formData.append("logo", params.logo);
    }
    // formData.append("how_to", params.how_to);
    // formData.append("tnc", params.tnc);
    for (var i = 0; i < params.sections.length; i++) {
      formData.append(`sections[${i}]`, params.sections[i]);
    }
    formData.append("decorations_included", params.decorations_included ? 1 : 0);

    return post(`/products/superadmin/proposals/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateProposalProducts: (id, params) => {
    return put(`/products/superadmin/proposals/${id}/products`, params);
  },
  updateProposalPackages: (id, params) => {
    return put(`/products/superadmin/proposals/${id}/packages`, params);
  },
  getProposal: (id) => {
    return get(`/products/superadmin/proposals/${id}`);
  },
  downloadProposal: (id, params) => {
    return post(`/products/superadmin/proposals/${id}/download`, params, {
      responseType: "blob",
    });
  },

  // REGIONS
  getRegions: (_params) => {
    let params = _params || {};

    return get(`/recipients/regions`, params);
  },

  // AUTOMATIONS
  getAutomations: (page, limit) => {
    return get(`/automations/superadmin/automations?page=${page}&limit=${limit}`);
  },
  getAutomation: (id) => {
    return get(`/automations/superadmin/automations/${id}`);
  },
  createAutomation: (params) => {
    return post("/automations/superadmin/automations", params);
  },
  updateAutomation: (id, params) => {
    return put(`/automations/superadmin/automations/${id}`, params);
  },
  publishAutomation: (id) => {
    return post(`/automations/superadmin/automations/${id}/publish`);
  },
  getAutomationProducts: (id) => {
    return get(`/automations/superadmin/automations/${id}/products`);
  },
  addAutomationProduct: (id, params) => {
    return post(`/automations/superadmin/automations/${id}/products`, params);
  },
  removeAutomationProduct: (id, productId) => {
    return del(`/automations/superadmin/automations/${id}/products/${productId}`);
  },

  getShopee: (params) => {
    return post(`/products/superadmin/crawlers`, params);
  },

  // SAMPLING
  updateOrderProduct: (id, productId, params) => {
    if (!params.sample_status && params.type === "sample") {
      let param = { sample_status: params.sample_status };
      return post(`/orders/superadmin/orders/${id}/products/${productId}/production`, param);
    }
    if (!params.production_status && params.type === "production") {
      let param = { production_status: params.production_status };
      return post(`/orders/superadmin/orders/${id}/products/${productId}/production`, param);
    }

    var formData = new FormData();
    if (params.type === "sample") {
      if (params.sample_photo) {
        formData.append("sample_photo", params.sample_photo);
      }
      formData.append("sample_status", params.sample_status);
    }
    if (params.type === "production") {
      if (params.production_photo) {
        formData.append("production_photo", params.production_photo);
      }
      formData.append("production_status", params.production_status);
    }
    return post(`/orders/superadmin/orders/${id}/products/${productId}/production`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default api;
