import api from "lib/api";
import Modal from "components/Modal/Modal";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import { useState } from "react";
import couriers from "lib/couriers";

export default function OrderRecipientStatus({ order, recipient, onSuccess, onClose }) {
  const [values, setValues] = useState({
    status: "",
    address: "",
    tracking_courier: recipient.tracking_courier || "",
    tracking_number: "",
  });
  const [loading, setLoading] = useState(false);
  const { handleError } = useError();

  const courierList = couriers();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { err } = await api.controllers.updateOrderRecipient(order.id, recipient.id, values);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      onClose();
      onSuccess();
    }
  };

  const form = (
    <form className="flex justify-center" onSubmit={onSubmit}>
      <div className="w-full lg:w-6/12">
        <div className="w-full px-4">
          <div className="relative w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
              Status{" "}
              <span className="text-red-500">
                <sup>*</sup>
              </span>
            </label>
            <select
              className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              name="status"
              onChange={handleChange}
            >
              <option value="">Select Status</option>
              {recipient.status === "address_requested" && <option value="processing">Processing</option>}
              {recipient.status === "processing" && <option value="delivering">Delivering</option>}
              {recipient.status === "delivering" && <option value="delivered">Delivered</option>}
            </select>
          </div>
        </div>

        {values.status === "processing" && (
          <>
            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                  Address{" "}
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <input
                  name="address"
                  type="text"
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Jalan ..."
                  value={values.address}
                  onChange={handleChange}
                />
              </div>
            </div>
          </>
        )}
        {values.status === "delivering" && (
          <>
            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                  Courier{" "}
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <select
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  name="tracking_courier"
                  value={values.tracking_courier}
                  onChange={handleChange}
                >
                  <option value="">Select Courier</option>
                  {[...courierList, ...[{ value: "idx", label: "ID Express" }]].map((courier) => (
                    <option key={`courier-${courier.value}`} value={courier.value}>
                      {courier.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                  Tracking number{" "}
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <input
                  name="tracking_number"
                  type="text"
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="JT1231231"
                  value={values.tracking_number}
                  onChange={handleChange}
                />
              </div>
            </div>
          </>
        )}
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative w-full mb-3">
            <button
              className="w-full flex justify-center bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              //onClick={e => onSubmit(e)}
            >
              {loading && <Spinner size={4} />} Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );

  return <Modal title={`Change ${recipient.recipient_name} Status`} onClose={() => onClose()} body={form} />;
}
