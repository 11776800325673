import Modal from "components/Modal/Modal";
import Spinner from "components/Spinner";
import api from "lib/api";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ShowMoreText from "react-show-more-text";

export default function CatalogueProductForm({
  product,
  onSubmit,
  orderType,
  addedItem,
  onDelete,
  suggestionItem,
  hideNote,
  hideHover,
  packages,
  editableQty,
}) {
  let typeOrder = orderType || "branded";
  let isPackages = packages ? true : false;
  let isEditableQty = editableQty ? true : false;
  // let showHoverEffect = hideHover ? false : true;
  const [values, setValues] = useState({
    quantity: 1,
    note: "",
    variations: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [more, setMore] = useState(false);
  const [productDetail, setProductDetail] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const imageUrl =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? product.image_url || product.product_image_url
      : "https://picsum.photos/200";

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onVariationChange = (index, variation, option) => {
    let newVariations = [...values.variations];
    if (option) {
      newVariations[index] = {
        id: variation.id,
        name: variation.name,
        option: option,
      };
    } else {
      newVariations.splice(index, 1);
    }

    setValues({ ...values, variations: newVariations });
  };

  const onClick = async () => {
    setShowModal(true);
    setLoading(true);
    if (suggestionItem === true) {
      const { data, err } = await api.controllers.getProduct(product.product_id);
      setLoading(false);
      if (!err) {
        setProductDetail(data.data);
      }
    } else {
      const { data, err } = await api.controllers.getProduct(product.id);
      setLoading(false);
      if (!err) {
        setProductDetail(data.data);
      }
    }
  };

  const onAddClicked = async () => {
    setSubmitLoading(true);

    let newVariations = [...values.variations].filter((variation) => {
      return typeof variation !== "undefined";
    });

    let success = await onSubmit(product, {
      quantity: values.quantity,
      variations: newVariations,
      note: values.note,
    });

    setSubmitLoading(false);

    if (success) {
      setValues({ quantity: 1, note: "", variations: [] });
      setShowModal(false);
    }
  };

  const renderVariation = (variation, index) => {
    let disable = Boolean(typeOrder !== "branded" ? true : false);

    return (
      <div className={`mb-2 pb-2`} key={`variation-${variation.id}`}>
        <div className={`font-bold mb-2`}>
          {variation.name}
          {typeOrder !== "branded" && <> (chosen by recipient)</>}
          {typeOrder === "branded" && (
            <>
              {" "}
              (choose one){" "}
              {!isPackages && (
                <span className="text-red-500">
                  <sup>*</sup>
                </span>
              )}
            </>
          )}
        </div>

        <div className="flex gap-4 w-full flex-wrap">
          {variation.options.map((option) => {
            let isChosen = values.variations[index] && option.id === values.variations[index].option;

            return (
              <button
                type="button"
                key={`option-${option.id}`}
                className={`px-3 py-1 rounded-full border border-slate-300 ${
                  isChosen ? "bg-primary text-white" : "hover:bg-gray-50"
                }`}
                onClick={(e) => onVariationChange(index, variation, option.id)}
                disabled={disable}
              >
                <div>{option.name}</div>
                {option.price > 0 && (
                  <div className={`text-xs ${isChosen ? "text-white" : "text-gray-400"}`}>
                    +{" "}
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      maximumFractionDigits: 0,
                    }).format(option.price)}
                  </div>
                )}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const modalBody = productDetail && (
    <div className="p-4">
      {loading && (
        <div className="mx-auto text-center">
          <Spinner color={"black"} center={true} />
        </div>
      )}
      {!loading && (
        <>
          <div className="flex gap-5">
            <div className="w-full md:w-1/2">
              <Carousel showIndicators={true} showThumbs={false} autoPlay={true} showArrows={false} showStatus={false}>
                {productDetail.images.map((image) => {
                  const imgUrl =
                    process.env.REACT_APP_ENVIRONMENT === "production" ? image.image_url : "https://picsum.photos/200";

                  return (
                    <div key={`image-${image.id}`}>
                      <img src={imgUrl} alt={product.name} className="rounded-lg shadow-lg" />
                    </div>
                  );
                })}
              </Carousel>

              {typeOrder === "branded" && product.custom_design && (
                <div className="w-full bg-blue-100 text-blue-500 text-lg font-bold text-center py-4 rounded-md border border-blue-500 mt-6 select-none">
                  Can be customized with logo.
                </div>
              )}
            </div>

            <div className="w-full md:w-1/2">
              <h3 className="text-xl font-bold">{productDetail.name}</h3>
              {productDetail.prices.length === 0 && (
                <p className="text-primary mb-4 font-bold">
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    maximumFractionDigits: 0,
                  }).format(productDetail.price)}
                </p>
              )}

              {productDetail.prices.length > 0 && (
                <div className="py-2">
                  <div className="grid grid-cols-3 gap-2">
                    <div className="pr-2 border-r">
                      <p className="text-xs text-gray-400 font-medium">
                        1 -
                        {productDetail.prices.sort(
                          (a, b) => productDetail.price - b.discount - (productDetail.price - a.discount)
                        )[0].range_from - 1}
                        pcs
                      </p>
                      <p className="text-primary font-bold">
                        {new Intl.NumberFormat("id-ID", {
                          currency: "IDR",
                          maximumFractionDigits: 0,
                        }).format(productDetail.price)}
                      </p>
                    </div>

                    {productDetail.prices
                      .sort((a, b) => productDetail.price - b.discount - (productDetail.price - a.discount))
                      .map((price, index) => (
                        <div key={`bulk - ${index}`} className="border-r">
                          <p className="text-xs text-gray-400 font-medium">
                            {index + 1 === productDetail.prices.length
                              ? `${price.range_from}pcs+`
                              : `${price.range_from} -${price.range_to} pcs`}
                          </p>

                          <p className="text-primary font-bold">
                            {new Intl.NumberFormat("id-ID", {
                              currency: "IDR",
                              maximumFractionDigits: 0,
                            }).format(productDetail.price - price.discount)}
                          </p>
                        </div>
                      ))
                      .slice(0, more ? productDetail.length : 2)}
                  </div>
                  {productDetail.prices.length > 2 && !more && (
                    <p
                      onClick={() => setMore(true)}
                      className="text-sm text-blue-500 flex items-center cursor-pointer mt-1"
                    >
                      Show more price{" "}
                      <img
                        src={require("assets/img/catalogue/arrow-down-blue.svg").default}
                        alt={"category-icon"}
                        className={`w-3 ml-2 ${more ? "rotate-180" : ""}`}
                      />
                    </p>
                  )}
                </div>
              )}

              <div className="mb-4 pb-4 whitespace-pre-line">
                <ShowMoreText
                  lines={8}
                  more={<span className="text-primary">more</span>}
                  less={<span className="text-primary">less</span>}
                  keepNewLines={true}
                >
                  {productDetail.description}
                </ShowMoreText>
              </div>

              {product.min_quantity > 1 && (
                <p className="text-gray-500 mb-4">*Minimum Order : {product.min_quantity} pcs</p>
              )}

              <div className="">
                {productDetail.variations.map(renderVariation)}

                {isEditableQty && (
                  <div className="mb-2">
                    <p className="font-bold mb-2">
                      Quantity{" "}
                      <span className="text-red-500">
                        <sup>*</sup>
                      </span>
                    </p>

                    <div className="flex items-center gap-x-5">
                      <button
                        disabled={values.quantity === 1}
                        onClick={() =>
                          setValues((values) => ({
                            ...values,
                            quantity: values.quantity - 1,
                          }))
                        }
                        className="w-fit cursor-pointer"
                      >
                        <img src={require("assets/img/order-edit/min.svg").default} alt={"min"} className="w-4" />
                      </button>
                      <div className="font-medium">{values.quantity}</div>
                      <button
                        onClick={() =>
                          setValues((values) => ({
                            ...values,
                            quantity: values.quantity + 1,
                          }))
                        }
                        className="w-fit cursor-pointer"
                      >
                        <img src={require("assets/img/order-edit/plus.svg").default} alt={"plus"} className="w-4" />
                      </button>
                    </div>
                  </div>
                )}

                {/* <div className="flex items-center justify-between mb-2 border-b pb-2">
                  <span className="text-sm">
                    Quantity{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </span>
                  <div className="w-1/2">
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="1"
                      value={values.quantity}
                      onChange={handleChange}
                    />
                  </div>
                </div> */}

                {!hideNote && (
                  <div className="mb-2 pb-2">
                    <div className="font-bold mb-2">Notes</div>
                    <div className="">
                      <textarea
                        name="note"
                        rows={1}
                        placeholder="Write Something....."
                        className="px-3 py-3 border-slate-300 placeholder-slate-300 text-slate-600 bg-white rounded text-sm focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                        value={values.note}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full pt-10 pb-5 flex justify-center">
            {typeOrder !== "branded" && (
              <>
                {addedItem?.indexOf(product) === -1 ? (
                  <button
                    className="w-fit mx-auto bg-primary text-white font-semibold px-16 py-2 rounded-md"
                    type="button"
                    onClick={onAddClicked}
                  >
                    {submitLoading && <Spinner size={4} />}
                    {!submitLoading && "Mark as suggetion"}
                  </button>
                ) : (
                  <button
                    className="w-fit mx-auto bg-white text-primary font-semibold px-16 py-2 rounded-md border border-primary"
                    type="button"
                    onClick={() => {
                      onDelete(product.id, true);
                      setShowModal(false);
                    }}
                  >
                    {submitLoading && <Spinner size={4} />}
                    {!submitLoading && "Remove Suggestion"}
                  </button>
                )}
              </>
            )}

            {typeOrder === "branded" && (
              <button
                className="w-fit mx-auto bg-primary text-white font-semibold px-16 py-2 rounded-md"
                type="button"
                onClick={onAddClicked}
              >
                {submitLoading && <Spinner size={4} />}
                {!submitLoading && "Add"}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );

  return (
    <div key={product.id}>
      <div className="group relative w-full h-full rounded-xl bg-white overflow-hidden border shadow-md">
        {typeOrder === "branded" && product.custom_design && (
          <div
            className="bg-[#F87F00] absolute right-0 top-0 text-white font-bold text-xs 
                px-5 py-2 opacity-70 rounded-tr-xl rounded-bl-xl select-none"
          >
            Custom
          </div>
        )}

        {addedItem &&
          typeOrder !== "branded" &&
          addedItem.map((item) => (
            <>
              {item.product_id === product.product_id && (
                <div
                  className="bg-[#F87F00] absolute left-0 top-5 text-white font-bold text-xs 
                pr-5 pl-3 py-2 opacity-70 rounded-r-xl flex select-none"
                >
                  <img
                    src={require("assets/img/order-edit/suggestion-check.svg").default}
                    alt={"suggestion-check"}
                    className="w-3 mr-2"
                  />{" "}
                  Suggestion
                </div>
              )}
            </>
          ))}

        <div className="">
          <img
            onClick={() => onClick()}
            src={imageUrl}
            alt={product.name}
            className="w-full aspect-square object-cover cursor-pointer"
          />

          <div className=" py-2">
            <div className="absolute px-3 top-0 bottom-0 right-0 left-0 bg-[#25252566] hidden group-hover:flex flex-col gap-2 justify-center items-center">
              <>
                {addedItem?.indexOf(product) === -1 ? (
                  <button
                    className="bg-primary text-white w-full p-2 font-semibold rounded-full text-sm"
                    type="button"
                    onClick={onAddClicked}
                  >
                    <p className="w-fit mx-auto">
                      {submitLoading && <Spinner size={4} />}
                      {!submitLoading && <>{typeOrder !== "branded" ? "Recommend" : "Add"}</>}
                    </p>
                  </button>
                ) : (
                  <button
                    className="w-full p-2 bg-white text-primary text-sm font-semibold rounded-full border border-primary"
                    type="button"
                    onClick={() => {
                      onDelete(product.id, true);
                    }}
                  >
                    {submitLoading && <Spinner size={4} />}
                    {!submitLoading && "Remove"}
                  </button>
                )}
              </>

              <button
                className="bg-primaryTwo text-white w-full p-2 font-semibold rounded-full text-sm"
                type="button"
                onClick={() => onClick()}
              >
                Details
              </button>
            </div>

            <h4 className="line-clamp-2 mt-1 px-2 h-12 mb-2">{product.name || product.product_name}</h4>
            <p
              className={`font-bold text-sm px-2 line-clamp-1 h-5 mb-2 ${
                !product.active ? "text-gray-500" : "text-primary"
              }`}
            >
              {product.price_lowest > 0 && (
                <>
                  <span className="font-medium">Rp</span>
                  {new Intl.NumberFormat("id-ID", {
                    currency: "IDR",
                    maximumFractionDigits: 0,
                  }).format(product.price_lowest)}
                  <span className="font-bold"> - </span>
                </>
              )}
              <span className="font-medium">Rp</span>
              {new Intl.NumberFormat("id-ID", {
                currency: "IDR",
                maximumFractionDigits: 0,
              }).format(product.price)}
            </p>
          </div>
        </div>
      </div>

      {showModal && (
        <Modal
          title={``}
          hideHeader={true}
          body={modalBody}
          onClose={() => {
            setShowModal(false);
            setMore(false);
          }}
        />
      )}
    </div>
  );
}
