import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
//import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import { ErrorProvider } from "contexts/error";
import { useAuth } from "contexts/auth";

import ClientList from "views/admin/Clients/ClientList";
import AddClient from "views/admin/Clients/AddClient";
import EditClient from "views/admin/Clients/EditClient";
import ClientRecipientList from "views/admin/Clients/ClientRecipientList";
import ClientUserList from "views/admin/Clients/ClientUserList";
import AddClientUser from "views/admin/Clients/AddClientUser";

import CategoryList from "views/admin/Category/CategoryList";

import OrderList from "views/admin/Orders/OrderList";
// import OrderNew from "views/admin/Orders/OrderNew";
import OrderEdit from "views/admin/Orders/OrderEdit";
import OrderDetail from "views/admin/Orders/OrderDetail";

import UserList from "views/admin/Users/UserList";
import AddUser from "views/admin/Users/AddUser";
import EditUser from "views/admin/Users/EditUser";

import TagList from "views/admin/Tags/TagList";
import AddTag from "views/admin/Tags/AddTag";
import EditTag from "views/admin/Tags/EditTag";

import VoucherList from "views/admin/Vouchers/VoucherList";

import ProductList from "views/admin/Products/ProductList";
import AddProduct from "views/admin/Products/AddProduct";
import EditProduct from "views/admin/Products/EditProduct";

import AddProposal from "views/admin/Proposal/AddProposal";

import AddVoucher from "views/admin/Vouchers/AddVoucher";
import EditVoucher from "views/admin/Vouchers/EditVoucher";
import ProposalList from "views/admin/Proposal/ProposalList";
import EditProposal from "views/admin/Proposal/EditProposal";
import ReviewsList from "views/admin/Reviews/ReviewsList";
import AddCategory from "views/admin/Category/AddCategory";
import EditCategory from "views/admin/Category/EditCategory";
import ClientEditRecipient from "views/admin/Clients/ClientEditRecipient";

import AddPackages from "views/admin/Packages/AddPackages";
import PackagesList from "views/admin/Packages/PackagesList";
import EditPackages from "views/admin/Packages/EditPackages";

import EditBrands from "views/admin/Brands/EditBrands";
import BrandsList from "views/admin/Brands/BrandsList";
import AddBrands from "views/admin/Brands/AddBrands";

export default function Admin() {
  const { loading, user } = useAuth();

  useEffect(() => {
    if (!loading && !user) {
      window.location = "/auth/login";
    } else if (user && user.role === "catalogue_manager" && !window.location.pathname.startsWith("/products")) {
      window.location = "/products";
    }
  }, [loading, user]);

  return (
    <ErrorProvider>
      {user && (
        <>
          <div className="relative">
            <AdminNavbar />
            {/* Header */}
            {<HeaderStats />}
            <div className=" md:px-10 mx-auto w-full">
              <Switch>
                {/*<Route path="/" exact component={Dashboard} />*/}
                <Route path="/" exact component={OrderList} />
                <Route path="/reviews" exact component={ReviewsList} />
                {/* <Route path="/orders/new" exact component={OrderNew} /> */}
                <Route path="/orders/edit/:id" exact component={OrderEdit} />
                <Route path="/orders/:id" exact component={OrderDetail} />

                {/* clients */}
                <Route path="/clients" exact component={ClientList} />
                <Route path="/clients/new" exact component={AddClient} />
                <Route path="/clients/:id/recipients" exact component={ClientRecipientList} />
                <Route path="/clients/:id/recipients/:RecipientId" exact component={ClientEditRecipient} />
                <Route path="/clients/:id/users" exact component={ClientUserList} />
                <Route path="/clients/:id/users/new" exact component={AddClientUser} />
                <Route path="/clients/:id" exact component={EditClient} />

                {/* users */}
                <Route path="/users" exact component={UserList} />
                <Route path="/users/new" exact component={AddUser} />
                <Route path="/users/:id" exact component={EditUser} />

                {/* category */}
                <Route path="/categories" exact component={CategoryList} />
                <Route path="/categories/new" exact component={AddCategory} />
                <Route path="/categories/:id" exact component={EditCategory} />

                {/* brands */}
                <Route path="/brands" exact component={BrandsList} />
                <Route path="/brands/new" exact component={AddBrands} />
                <Route path="/brands/:id" exact component={EditBrands} />

                {/* tags */}
                <Route path="/tags" exact component={TagList} />
                <Route path="/tags/new" exact component={AddTag} />
                <Route path="/tags/:id" exact component={EditTag} />

                {/* vouchers */}
                <Route path="/vouchers" exact component={VoucherList} />
                <Route path="/vouchers/new" exact component={AddVoucher} />
                <Route path="/vouchers/:id" exact component={EditVoucher} />

                {/* products */}
                <Route path="/products" exact component={ProductList} />
                <Route path="/products/new" exact component={AddProduct} />
                <Route path="/products/:id" exact component={EditProduct} />

                {/* proposals */}
                <Route path="/proposals" exact component={ProposalList} />
                <Route path="/proposals/new" exact component={AddProposal} />
                <Route path="/proposals/:id" exact component={EditProposal} />

                {/* packages */}
                <Route path="/packages" exact component={PackagesList} />
                <Route path="/packages/new" exact component={AddPackages} />
                <Route path="/packages/:id" exact component={EditPackages} />
              </Switch>
            </div>
          </div>
        </>
      )}
    </ErrorProvider>
  );
}
