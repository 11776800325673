import api from "lib/api";
import Modal from "components/Modal/Modal";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import { useEffect, useState } from "react";
import useDidMountEffect from "lib/useDidMountEffect";

export default function OrderPickup({ order, recipients, onSuccess, onClose }) {
  const [loading, setLoading] = useState(false);
  const [loadingSchedules, setLoadingSchedules] = useState(true);
  const [loadingShipping, setLoadingShipping] = useState(true);
  const [loadingLalamoveQuotation, setLoadingLalamoveQuotation] = useState(false);
  const [lalamoveQuotation, setLalamoveQuotation] = useState(null);
  const [formBody, setFormBody] = useState("");
  const [error, setError] = useState(null);
  const [lalamoveSchedule, setLalamoveSchedule] = useState({
    date: "",
    time: "",
  });

  const { handleError } = useError();
  const [values, setValues] = useState({
    provider: null,
    schedule: "",
    courier: "",
    courier_group: "regular",
    courier_service: "",
    item_name: "Souvenir/Merchandise",
    sender_name: "",
    sender_phone_number: "",
  });

  const [valuesLalamove, setValuesLalamove] = useState({
    provider: null,
    quotation_id: null,
    sender_name: "",
    sender_phone_number: "",
    recipients: recipients,
    schedules: "immediately",
    service_type: null,
    schedule_at: null,
    special_requests: [],
  });

  const [valuesKiriminAja, setValuesKiriminAja] = useState({
    width: 0,
    height: 0,
    length: 0,
  });
  const [schedules, setSchedules] = useState([]);
  const [kiriminAjaCouriers, setKiriminAjaCouriers] = useState([]);

  let courierServices = [
    {
      value: "regular",
      label: "Regular",
    },
    {
      value: "one_day",
      label: "One Day",
    },
    {
      value: "next_day",
      label: "Next Day",
    },
    {
      value: "economy",
      label: "Economy",
    },
    {
      value: "cargo",
      label: "Cargo",
    },
  ];

  let lalamoveService = [
    {
      image: require("assets/img/pick-up/motor.png").default,
      title: "Motor",
      value: "MOTORCYCLE",
      specialRequests: [
        {
          name: "WAITING_TIME_060MIN",
          description: "Extra waiting time (over 60 min)",
        },
        {
          name: "THERMAL_BAG_1",
          description: "Thermal bag",
        },
        {
          name: "ROUND_TRIP",
          description: "Round Trip",
        },
        {
          name: "DOOR_TO_DOOR_1DRIVER",
          description: "Door to Door Delivery",
        },
      ],
    },
    {
      image: require("assets/img/pick-up/sedan.png").default,
      title: "Sedan",
      value: "SEDAN",
      specialRequests: [
        {
          name: "DOOR_TO_DOOR_1DRIVER",
          description: "Door-to-door (pickup & drop off by driver)",
        },
        {
          name: "TOLL_FEE",
          description: "Toll fee",
        },
        {
          name: "ROUND_TRIP",
          description: "Round Trip",
        },
      ],
    },
    {
      image: require("assets/img/pick-up/mobil.png").default,
      title: "MPV",
      value: "MPV",
      specialRequests: [
        {
          name: "DOOR_TO_DOOR_1DRIVER",
          description: "Door-to-door (pickup & drop off by driver)",
        },
        {
          name: "TOLL_FEE",
          description: "Toll fee",
        },
        {
          name: "ROUND_TRIP",
          description: "Round trip",
        },
        {
          name: "WAITING_TIME_060MIN",
          description: "Extra waiting time (over 60 min)",
        },
      ],
    },
    {
      image: require("assets/img/pick-up/van.png").default,
      title: "Van",
      value: "VAN",
      specialRequests: [
        {
          name: "DOOR_TO_DOOR_1DRIVER",
          description: "Door-to-door (pickup & drop off by driver)",
        },
        {
          name: "LOADING_1HELPER",
          description: "Loading & unloading by a helper",
        },
        {
          name: "TOLL_FEE",
          description: "Toll fee",
        },
        {
          name: "ROUND_TRIP",
          description: "Round trip",
        },
        {
          name: "WAITING_TIME_060MIN",
          description: "Extra waiting time (over 60 min)",
        },
      ],
    },
    {
      image: require("assets/img/pick-up/pick-up-bak.png").default,
      title: "Pick Up Bak",
      value: "TRUCK175",
      specialRequests: [
        {
          name: "DOOR_TO_DOOR_1DRIVER",
          description: "Door-to-door (pickup & drop off by driver)",
        },
        {
          name: "LOADING_1HELPER",
          description: "Loading & unloading by a helper",
        },
        {
          name: "TOLL_FEE",
          description: "Toll fee",
        },
        {
          name: "ROUND_TRIP",
          description: "Round Trip",
        },
        {
          name: "WAITING_TIME_060MIN",
          description: "Extra waiting time (over 60 min)",
        },
      ],
    },
    {
      image: require("assets/img/pick-up/pick-up-box.png").default,
      title: "Pickup Box (1 Ton)",
      value: "TRUCK330",
      specialRequests: [
        {
          name: "DOOR_TO_DOOR_1DRIVER",
          description: "Door-to-door (pickup & drop off by driver)",
        },
        {
          name: "LOADING_1HELPER",
          description: "Loading & unloading by a helper",
        },
        {
          name: "TOLL_FEE",
          description: "Toll fee",
        },
        {
          name: "ROUND_TRIP",
          description: "Round Trip",
        },
        {
          name: "WAITING_TIME_060MIN",
          description: "Extra waiting time (over 60 min)",
        },
        {
          name: "FROZEN_VEHICLE",
          description: "Frozen Truck Pickup Box 1 (Ton)",
        },
      ],
    },
    {
      image: require("assets/img/pick-up/engkel-box.png").default,
      title: "Engkel Box (2 Ton)",
      value: "LALANOW",
      specialRequests: [
        {
          name: "WAITING_TIME_060MIN",
          description: "Extra loading time (over 1 hr)",
        },
        {
          name: "TOLL_FEE_03",
          description: "Toll to Bandung",
        },
        {
          name: "LOADING_1HELPER",
          description: "Loading & unloading by a helper",
        },
        {
          name: "TOLL_FEE_04",
          description: "Toll to Cirebon",
        },
        {
          name: "TOLL_FEE_02",
          description: "Toll to Surabaya",
        },
        {
          name: "TOLL_FEE_05",
          description: "Toll to Malang",
        },

        {
          name: "TOLL_FEE_07",
          description: "Toll to Semarang",
        },
        {
          name: "TOLL_FEE_06",
          description: "Toll to Yogayakarta (Via SMG-Solo)",
        },
        {
          name: "FROZEN_VEHICLE",
          description: "Frozen Truck Engkel Box 2 (Ton)",
        },
      ],
    },
  ];

  useDidMountEffect(async () => {
    if (formBody === "lalamove") {
      const value = {
        recipients: valuesLalamove.recipients,
        service_type: valuesLalamove.service_type,
        ...(valuesLalamove.schedule_at && { schedule_at: valuesLalamove.schedule_at }),
        ...(valuesLalamove.special_requests.length > 0 && { special_requests: valuesLalamove.special_requests }),
      };

      !valuesLalamove.schedule_at && delete value.schedule_at;
      valuesLalamove.special_requests === 0 && delete value.special_requests;

      const getLalamoveQuotation = async () => {
        setLoadingLalamoveQuotation(true);
        const { data, err } = await api.controllers.getLalamoveQuotation(order.id, value);
        setLoadingLalamoveQuotation(false);

        if (!err) {
          if (data.data) {
            setLalamoveQuotation(data.data);
            setValuesLalamove((valuesLalamove) => ({
              ...valuesLalamove,
              quotation_id: data.data.quotationId,
            }));
          } else {
            handleError({ message: "Invalid quotation" });
          }
        } else handleError(err);
      };
      getLalamoveQuotation();
    }
  }, [valuesLalamove.service_type, valuesLalamove.schedule_at, valuesLalamove.special_requests]);

  useEffect(() => {
    if (order.deliver_to !== "company") {
      if (!order.request_location) {
        setFormBody("kiriminaja");
      } else {
        setFormBody("");
      }
    }
  }, [order]);

  useEffect(() => {
    setValues((values) => ({ ...values, provider: formBody }));
    setValuesLalamove((valuesLalamove) => ({ ...valuesLalamove, provider: formBody }));
  }, [formBody]);

  useEffect(() => {
    if (formBody === "kiriminaja") {
      const getPickupSchedules = async () => {
        setLoadingSchedules(true);
        const { data, err } = await api.controllers.getPickupSchedules();
        setLoadingSchedules(false);

        if (err) {
          handleError(err);
        } else {
          setSchedules(data.data);
        }
      };
      getPickupSchedules();
    }
  }, [handleError, formBody]);

  let onValueChange = (key, value) => {
    setValues({ ...values, [key]: value });
  };

  let onKiriminAjaChange = (key, value) => {
    setValuesKiriminAja({ ...valuesKiriminAja, [key]: value });
  };

  let onLalamoveValueChange = (key, value) => {
    setValuesLalamove({ ...valuesLalamove, [key]: value });
  };

  useEffect(() => {
    if (lalamoveSchedule.date.length > 0 && lalamoveSchedule.time.length > 0) {
      setValuesLalamove((valuesLalamove) => ({
        ...valuesLalamove,
        schedule_at: `${lalamoveSchedule.date} ${lalamoveSchedule.time}:00`,
      }));
    }
  }, [lalamoveSchedule]);

  let onLalamoveScheduleChange = (name, value) => {
    setLalamoveSchedule({ ...lalamoveSchedule, [name]: value });
  };

  let onSubmit = async (e) => {
    if (formBody === "kiriminaja") {
      e.preventDefault();
      setLoading(true);
      const { err } = await api.controllers.requestPickup(order.id, {
        provider: values.provider,
        schedule: values.schedule,
        courier: values.courier,
        courier_service: values.courier_service,
        item_name: values.item_name,
        sender_name: values.sender_name,
        sender_phone_number: values.sender_phone_number,
        recipients: recipients,

        width: valuesKiriminAja.width,
        height: valuesKiriminAja.height,
        length: valuesKiriminAja.length,
      });
      setLoading(false);

      if (err) {
        handleError(err);
      } else {
        onClose();
        onSuccess();
      }
    } else if (formBody === "lalamove") {
      e.preventDefault();
      setLoading(true);
      const { err } = await api.controllers.requestPickup(order.id, {
        provider: values.provider,
        quotation_id: valuesLalamove.quotation_id,
        sender_name: valuesLalamove.sender_name,
        sender_phone_number: valuesLalamove.sender_phone_number,
        recipients: recipients,
      });
      setLoading(false);

      if (err) {
        handleError(err);
      } else {
        onClose();
        onSuccess();
      }
    }
  };

  // for development
  // let _schedules = [
  //   { clock: "2023-02-03 11:00:00", expired: 1675405800, libur: false, until: "22:00" },
  //   {
  //     clock: "2023-02-03 14:00:00",
  //     expired: 1675413000,
  //     libur: false,
  //     until: "22:00",
  //   },
  //   {
  //     clock: "2023-02-04 08:00:00",
  //     expired: 1675481400,
  //     libur: false,
  //     until: "22:00",
  //   },
  //   {
  //     clock: "2023-02-04 11:00:00",
  //     expired: 1675492200,
  //     libur: false,
  //     until: "22:00",
  //   },
  //   {
  //     clock: "2023-02-04 14:00:00",
  //     expired: 1675499400,
  //     libur: false,
  //     until: "22:00",
  //   },
  //   {
  //     clock: "2023-02-05 08:00:00",
  //     expired: 1675567800,
  //     libur: false,
  //     until: "22:00",
  //   },
  //   {
  //     clock: "2023-02-05 11:00:00",
  //     expired: 1675578600,
  //     libur: false,
  //     until: "22:00",
  //   },
  //   {
  //     clock: "2023-02-05 14:00:00",
  //     expired: 1675585800,
  //     libur: false,
  //     until: "22:00",
  //   },
  // ];

  const getKiriminAjaCouriers = async () => {
    setLoadingShipping(true);
    const { data, err } = await api.controllers.requestKiriminAjaCouriers(order.id, {
      width: valuesKiriminAja.width,
      height: valuesKiriminAja.height,
      length: valuesKiriminAja.length,

      recipients: recipients,
    });
    setLoadingShipping(false);

    if (err) {
      setError(err.message);
    } else {
      setKiriminAjaCouriers(data.data.couriers);
    }
  };

  useEffect(() => {
    if (formBody === "kiriminaja") {
      getKiriminAjaCouriers();
    }
  }, [formBody]); // eslint-disable-line react-hooks/exhaustive-deps

  const kiriminAjaForm = (
    <>
      <div>
        <div className="pt-4 mb-5 relative">
          {order.deliver_to !== "company" && !order.request_location && (
            <i
              onClick={() => setFormBody("")}
              className={` fas fa-arrow-left text-2xl absolute block px-3 py-1 cursor-pointer`}
            />
          )}
          <h3 className="text-2xl font-bold text-center">{`Pickup (${recipients.length} recipients)`}</h3>
        </div>

        <div className="px-4">
          {/* length widht height  */}
          <div className="md:grid grid-cols-5 gap-5 items-end mb-2">
            <div className="w-full mb-3">
              <label className="block font-medium text-sm mb-2">
                Length (cm){" "}
                <span className="text-red-500">
                  <sup>*</sup>
                </span>
              </label>
              <input
                name="length"
                type="number"
                min={0}
                className="border-0 px-3 py-3 bg-white rounded shadow w-full"
                value={valuesKiriminAja.length}
                onChange={(e) => onKiriminAjaChange("length", Number(e.target.value))}
              />
            </div>

            <div className="w-full mb-3">
              <label className="block font-medium text-sm mb-2">
                Width (cm){" "}
                <span className="text-red-500">
                  <sup>*</sup>
                </span>
              </label>
              <input
                name="width"
                type="number"
                min={0}
                className="border-0 px-3 py-3 bg-white rounded shadow w-full"
                value={valuesKiriminAja.width}
                onChange={(e) => onKiriminAjaChange("width", Number(e.target.value))}
              />
            </div>

            <div className="w-full mb-3">
              <label className="block font-medium text-sm mb-2">
                Height (cm){" "}
                <span className="text-red-500">
                  <sup>*</sup>
                </span>
              </label>
              <input
                name="height"
                type="number"
                min={0}
                className="border-0 px-3 py-3 bg-white rounded shadow w-full"
                value={valuesKiriminAja.height}
                onChange={(e) => onKiriminAjaChange("height", Number(e.target.value))}
              />
            </div>

            <button
              className="border-0 flex justify-center items-center px-4 py-3 mb-3 bg-gray-600 text-white rounded text-sm shadow w-full md:w-fit h-fit font-bold"
              type="submit"
              onClick={() => {
                getKiriminAjaCouriers();
                setValues((values) => ({ ...values, courier: "", courier_service: "" }));
              }}
            >
              {/* {loadingShipping && <Spinner size={4} />}  */}
              Update
            </button>
          </div>

          <>
            <div className="bg-[#F0F5FA] p-4 rounded-md mb-4">
              {/* Courier Service  */}
              <div className="w-full mb-5">
                {loadingShipping ? (
                  <div className="w-fit mx-auto">
                    <Spinner size={4} color={"black"} />
                  </div>
                ) : (
                  <>
                    {error ? (
                      <div className="text-center">
                        <p className="text-red-500 mb-1">{error}</p>
                        <p
                          onClick={() => {
                            getKiriminAjaCouriers();
                            setValues((values) => ({ ...values, courier: "", courier_service: "" }));
                            setError(null);
                          }}
                          className="cursor-pointer"
                        >
                          <i className="fas fa-sync text-black mr-2" />
                          Reload
                        </p>
                      </div>
                    ) : (
                      <>
                        <label className="block text-sm font-bold mb-2">Courier Service</label>
                        <div className="flex flex-wrap gap-2 text-sm">
                          {courierServices &&
                            courierServices
                              .filter((courierService) =>
                                kiriminAjaCouriers.some((item) => item.group === courierService.value)
                              )
                              .map((courierService, index) => (
                                <div
                                  className={`p-2 border rounded-lg cursor-pointer ${
                                    courierService.value === values.courier_group
                                      ? "bg-primaryTwo text-white"
                                      : "bg-white"
                                  }`}
                                  key={`courier-group-${index}`}
                                  onClick={() => {
                                    onValueChange("courier_group", courierService.value);
                                    setValues((values) => ({ ...values, courier: "", courier_service: "" }));
                                  }}
                                >
                                  {courierService?.label}
                                </div>
                              ))}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              {/* Courier   */}
              {!loadingShipping && (
                <div className="w-full">
                  {/* <label className="block text-sm font-bold mb-2">Courier</label> */}
                  <div className="grid md:grid-cols-2 gap-2 text-sm">
                    {kiriminAjaCouriers
                      .filter((courier) => courier.group === values.courier_group)
                      .map((courier, index) => {
                        return (
                          <div
                            className={`p-2 w-full border rounded-md cursor-pointer grid grid-cols-3 gap-4 bg-white ${
                              courier.service_type === values.courier_service && courier.service === values.courier
                                ? "border-primaryTwo"
                                : ""
                            }`}
                            key={`courier-${index}`}
                            onClick={() =>
                              setValues((values) => ({
                                ...values,
                                courier: courier.service,
                                courier_service: courier.service_type,
                              }))
                            }
                          >
                            <div>
                              <img
                                src={require(`assets/img/couriers/${courier.service}.png`).default}
                                alt={courier.service}
                                className="h-4 object-contain mb-1"
                              />
                              <p className="line-clamp-1">{courier.service_name}</p>
                            </div>
                            <div>
                              <p className="text-xs text-gray-500 mb-1">Ongkir</p>
                              <p>
                                {new Intl.NumberFormat("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                  maximumFractionDigits: 0,
                                }).format(courier.total_delivery_cost)}
                              </p>
                            </div>
                            <div>
                              <p className="text-xs text-gray-500 mb-1">Asuransi</p>
                              <p>
                                {" "}
                                {new Intl.NumberFormat("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                  maximumFractionDigits: 0,
                                }).format(courier.total_insurance_cost)}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>

            {values.courier !== "" && values.courier_service !== "" && (
              <>
                {/* schedules / _schedules */}
                <>
                  <div className="w-full mb-4 md:flex md:flex-col md:justify-end">
                    <label className="block text-sm font-bold mb-2">
                      Pickup Schedule
                      <span className="text-red-500">
                        <sup>*</sup>
                      </span>
                    </label>
                    {loadingSchedules ? (
                      <Spinner color="black" />
                    ) : (
                      <select
                        name="country"
                        className="border-0 rounded-sm bg-gray-200 w-fit py-3 pl-3 pr-10 text-black md:text-gray-700 text-sm"
                        onChange={(e) => onValueChange("schedule", e.target.value)}
                        value={values.schedule}
                      >
                        <option disabled value={""}>
                          Choose Schedule
                        </option>
                        {schedules.map((schedule, index) => (
                          <option key={`schedule-${index}`} value={schedule.clock}>
                            {schedule.clock} - {schedule.until}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </>

                {/* Item Name  */}
                <div className="md:grid grid-cols-3 gap-5">
                  <div className="w-full mb-3">
                    <label className="block  text-sm font-bold mb-2">
                      Item Name{" "}
                      <span className="text-red-500">
                        <sup>*</sup>
                      </span>
                    </label>
                    <input
                      name="item_name"
                      type="text"
                      className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                      value={values.item_name}
                      onChange={(e) => onValueChange("item_name", e.target.value)}
                    />
                  </div>

                  <div className="w-full mb-3">
                    <label className="block  text-sm font-bold mb-2">
                      Sender Name{" "}
                      <span className="text-red-500">
                        <sup>*</sup>
                      </span>
                    </label>
                    <input
                      name="sender_name"
                      type="text"
                      className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                      value={values.sender_name}
                      onChange={(e) => onValueChange("sender_name", e.target.value)}
                    />
                  </div>

                  <div className="w-full mb-3">
                    <label className="block  text-sm font-bold mb-2">
                      Sender Phone Number{" "}
                      <span className="text-red-500">
                        <sup>*</sup>
                      </span>
                    </label>
                    <input
                      name="sender_phone_number"
                      type="text"
                      className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                      value={values.sender_phone_number}
                      onChange={(e) => onValueChange("sender_phone_number", e.target.value)}
                    />
                  </div>
                </div>

                {/* submit  */}
                <div className="mt-10 w-fit mx-auto flex items-center gap-4">
                  <button
                    className="w-fit flex justify-center bg-primary text-white font-bold px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="submit"
                    onClick={(e) => onSubmit(e)}
                  >
                    {loading && <Spinner size={4} />} Request Pickup
                  </button>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );

  const lalamoveForm = (
    <>
      <div>
        <div className="pt-4 mb-5 relative">
          <i
            onClick={() => {
              setFormBody("");
              setValuesLalamove((valuesLalamove) => ({ ...valuesLalamove, service_type: null, special_requests: [] }));
              setLalamoveQuotation(null);
            }}
            className={` fas fa-arrow-left text-2xl absolute block px-3 py-1 cursor-pointer`}
          />
          <h3 className="text-2xl font-bold text-center">{`Pickup (${recipients.length} recipients)`}</h3>
        </div>

        <>
          <div className="w-full mb-3 px-3">
            <label className="block font-bold mb-2">Pickup Schedule</label>
            <div className="flex flex-wrap gap-2 ">
              <div
                onClick={() => {
                  setValuesLalamove((valuesLalamove) => ({
                    ...valuesLalamove,
                    schedules: "immediately",
                    schedule_at: null,
                  }));
                  setLalamoveSchedule((lalamoveSchedule) => ({
                    ...lalamoveSchedule,
                    date: "",
                    time: "",
                  }));
                }}
                className={`${
                  valuesLalamove.schedules === "immediately" ? "bg-primaryTwo text-white" : "bg-gray-300"
                }  px-4 py-2 rounded-full cursor-pointer`}
              >
                {" "}
                <i
                  className={` ${
                    valuesLalamove.schedules === "immediately" ? "fas fa-check-circle" : "far fa-circle"
                  } mr-2`}
                />
                immediately
              </div>
              <div
                onClick={() => setValuesLalamove((valuesLalamove) => ({ ...valuesLalamove, schedules: "scheduled" }))}
                className={`${
                  valuesLalamove.schedules === "scheduled" ? "bg-primaryTwo text-white" : "bg-gray-300"
                }  px-4 py-2 rounded-full cursor-pointer`}
              >
                {" "}
                <i
                  className={`  ${
                    valuesLalamove.schedules === "scheduled" ? "fas fa-check-circle" : "far fa-circle"
                  } mr-2`}
                />
                Scheduled
              </div>
            </div>

            {valuesLalamove.schedules === "scheduled" && (
              <div className="flex gap-3 mt-3">
                <input
                  name="date"
                  type="date"
                  className="w-8/12 border-0 px-3 py-3 mb-2 bg-tertiary_gray_one placeholder-slate-300 text-slate-600 border-b-2 border-blue-400 focus:border-blue-400 rounded-md focus:ring-0"
                  placeholder="Date"
                  value={lalamoveSchedule.date}
                  onChange={(e) => onLalamoveScheduleChange("date", e.target.value)}
                />

                <input
                  name="time"
                  type="time"
                  className="w-4/12 border-0 px-3 py-3 mb-2 bg-tertiary_gray_one placeholder-slate-300 text-slate-600 border-b-2 border-blue-400 focus:border-blue-400 rounded-md focus:ring-0"
                  placeholder="Date"
                  value={lalamoveSchedule.time}
                  onChange={(e) => onLalamoveScheduleChange("time", e.target.value)}
                />
              </div>
            )}
          </div>

          <div className="w-full mb-3 px-3">
            <label className="block font-bold mb-2">Service Type</label>
            <div className="grid grid-cols-5 gap-4">
              {lalamoveService.map((type, index) => (
                <div
                  onClick={() => {
                    setValuesLalamove((valuesLalamove) => ({ ...valuesLalamove, service_type: type.value }));
                    setValuesLalamove((valuesLalamove) => ({
                      ...valuesLalamove,
                      special_requests: [],
                    }));
                  }}
                  key={`type - ${index}`}
                  className={`${
                    valuesLalamove.service_type === type.value ? "bg-primaryTwo" : "bg-tertiary_gray_one"
                  }  rounded-md cursor-pointer p-4`}
                >
                  <img src={type.image} alt={type.title} className="w-20  mx-auto pb-2" />
                  <p
                    className={`text-center font-medium ${
                      valuesLalamove.service_type === type.value ? "text-white" : ""
                    }`}
                  >
                    {type.title}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {valuesLalamove.service_type && (
            <div className="w-full mb-3 px-3">
              <label className="block font-bold my-4">Special Request</label>
              <div className="grid grid-cols-4 gap-4">
                {lalamoveService
                  .filter((type) => type.value === valuesLalamove.service_type)
                  .map((type) =>
                    type.specialRequests.map((service, index) => (
                      <div
                        onClick={() => {
                          valuesLalamove.special_requests.indexOf(service.name) === -1
                            ? setValuesLalamove((valuesLalamove) => ({
                                ...valuesLalamove,
                                special_requests: [...valuesLalamove.special_requests, service.name],
                              }))
                            : setValuesLalamove((valuesLalamove) => ({
                                ...valuesLalamove,
                                special_requests: valuesLalamove.special_requests.filter(
                                  (request) => request !== service.name
                                ),
                              }));
                        }}
                        key={`type - ${index}`}
                        className={` ${
                          valuesLalamove.special_requests.indexOf(service.name) !== -1 ? "border border-slate-400" : ""
                        } rounded-md cursor-pointer p-3 text-xs bg-tertiary_gray_one flex items-center font-medium`}
                      >
                        <input
                          name="request_address"
                          type="checkbox"
                          className="border-black rounded cursor-pointer shadow mr-2"
                          checked={valuesLalamove.special_requests.indexOf(service.name) !== -1 ? true : false}
                          readOnly
                        />
                        <label className="cursor-pointer">{service.description}</label>
                      </div>
                    ))
                  )}
              </div>
            </div>
          )}

          {loadingLalamoveQuotation && (
            <div className="mx-auto w-fit">
              <Spinner color="black" />
            </div>
          )}

          {!loadingLalamoveQuotation && lalamoveQuotation && (
            <>
              <div className="w-full mb-3 px-3 flex gap-3">
                <div className="w-full mb-3">
                  <label className="block font-bold mb-2">
                    Sender Name{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="sender_name"
                    type="text"
                    className="w-full border-0 px-3 py-3 mb-2 bg-tertiary_gray_one placeholder-slate-300 text-slate-600 border-b-2 border-blue-400 focus:border-blue-400 rounded-md focus:ring-0"
                    value={valuesLalamove.sender_name}
                    placeholder="xxx"
                    onChange={(e) => onLalamoveValueChange("sender_name", e.target.value)}
                  />
                </div>
                <div className="w-full mb-3">
                  <label className="block font-bold mb-2">
                    Sender Phone Number{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="sender_phone_number"
                    type="text"
                    className="w-full border-0 px-3 py-3 mb-2 bg-tertiary_gray_one placeholder-slate-300 text-slate-600 border-b-2 border-blue-400 focus:border-blue-400 rounded-md focus:ring-0"
                    value={valuesLalamove.sender_phone_number}
                    placeholder="xxx"
                    onChange={(e) => onLalamoveValueChange("sender_phone_number", e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full px-3 ">
                <div className="flex justify-between font-medium">
                  <p>Base Price</p>
                  <p>
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      maximumFractionDigits: 0,
                    }).format(lalamoveQuotation.priceBreakdown.base)}
                  </p>
                </div>
                {lalamoveQuotation.priceBreakdown.specialRequests && (
                  <div className="flex justify-between font-medium">
                    <p>Special Request</p>
                    <p>
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      }).format(lalamoveQuotation.priceBreakdown.specialRequests)}
                    </p>
                  </div>
                )}
                {lalamoveQuotation.priceBreakdown.extraMileage && (
                  <div className="flex justify-between font-medium">
                    <p>Extra Mileage</p>
                    <p>
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      }).format(lalamoveQuotation.priceBreakdown.extraMileage)}
                    </p>
                  </div>
                )}
                <div className="flex justify-between font-medium">
                  <p>PPn</p>
                  <p>
                    {lalamoveQuotation.priceBreakdown.vat > 0
                      ? new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          maximumFractionDigits: 0,
                        }).format(lalamoveQuotation.priceBreakdown.vat)
                      : "Rp 0"}
                  </p>
                </div>
                <div className="flex justify-between font-bold">
                  <p>Total</p>
                  <p>
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      maximumFractionDigits: 0,
                    }).format(lalamoveQuotation.priceBreakdown.total)}
                  </p>
                </div>
              </div>
            </>
          )}

          <div className="mt-10 w-full flex justify-center">
            <button
              className="w-fit flex justify-center bg-primary text-white font-bold px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              onClick={(e) => onSubmit(e)}
            >
              {loading && <Spinner size={4} />} Request Pickup
            </button>
          </div>
        </>
      </div>
    </>
  );

  const choosePickUpService = (
    <>
      <div className="pt-4">
        <h3 className="text-2xl font-bold text-center">Choose Provider</h3>
      </div>

      <div className="grid grid-cols-2 w-8/12 mx-auto gap-5 py-12">
        <div onClick={() => setFormBody("kiriminaja")} className=" bg-secondaryTwo rounded-md cursor-pointer">
          <img
            src={require("assets/img/pick-up/kirimin-aja.png").default}
            alt={"kirimin-aja-icon"}
            className="w-9/12 mx-auto py-6"
          />
        </div>

        <div onClick={() => setFormBody("lalamove")} className=" bg-secondaryTwo rounded-md cursor-pointer">
          <img
            src={require("assets/img/pick-up/lalamove.png").default}
            alt={"lalamove-icon"}
            className="w-9/12 mx-auto py-6"
          />
        </div>
      </div>
    </>
  );

  const body = (
    <>
      {formBody === "" && choosePickUpService}

      {formBody === "kiriminaja" && kiriminAjaForm}

      {formBody === "lalamove" && lalamoveForm}
    </>
  );

  // title={`Pickup (${recipients.length} recipients)`}
  return <Modal hideHeader={true} title={``} widthClass="min-w-[75%]" onClose={() => onClose()} body={body} />;
}
