import imageCompression from "browser-image-compression";

const compress = async (file) => {
  const compressionOtions = {
    maxSizeMB: 0.2,
    maxWidthOrHeight: 1024,
    useWebWorker: true,
  };

  return await imageCompression(file, compressionOtions);
};

export default compress;
