import React, { createContext, useState, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import api from 'lib/api'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)

    const loadUser = async () => {
        const token = Cookies.get('token')
        if (token) {
            const {err, data} = await api.controllers.me()
            if (!err) {
                setUser(data.data)
            }
        }
    }

    useEffect(() => {
        async function loadUserFromCookies() {
            await loadUser()
            setLoading(false)
        }
        loadUserFromCookies()
    }, [])

    const login = async token => {
        Cookies.set('token', token, {expires: 30})
        const {err, data, res} = await api.controllers.me()

        if (!err) {
            setUser(data.data)
        }

        return {err, data, res}
    }

    const logout = () => {
        Cookies.remove('token')
        setUser(null)
        window.location = '/auth/login'
    }

    return (
        <AuthContext.Provider value={{
            isAuthenticated: !!user, user, loading,
            setUser, login, logout,
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)

export const ProtectRoute = ({ children }) => {
    const { isLoading } = useAuth()
    if (isLoading){
        return <p>Loading...</p>
    }
    return children
}
