import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Table from "components/Table/Table.js";
import api from "lib/api";

export default function VoucherList() {
  const [data, setData] = useState({});
  const history = useHistory();
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    const get = async () => {
      const { data, err } = await api.controllers.getVouchers(pagination.page, pagination.limit);
      if (!err) {
        setData(data);
      }
    };
    pagination && get();
  }, [pagination]);

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Table
            settings={{
              title: "Vouchers",
              button: {
                label: "Add",
                onClick: () => {
                  history.push("/vouchers/new");
                },
              },
              headers: [
                {
                  label: "Code",
                  render: (element) => {
                    return (
                      <Link to={`/vouchers/${element.id}`} className={"underline"}>
                        {element.code.toUpperCase()}
                      </Link>
                    );
                  },
                },
                {
                  label: "Client",
                  field: "client_name",
                },
                {
                  label: "Type",
                  field: "type",
                },
                {
                  label: "Amount",
                  field: "amount",
                },
                {
                  label: "Status",
                  render: (element) => {
                    return element.active ? "Active" : "Not Active";
                  },
                },
              ],
            }}
            data={data}
            setPagination={setPagination}
          />
        </div>
      </div>
    </>
  );
}
