import api from "lib/api";
import CatalogueProductForm from "components/Catalogue/CatalogueProductForm";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import { useState } from "react";
import Cataloguehampers from "components/Catalogue/Cataloguehampers";

export default function Hampers({ proposal }) {
  const [groups, setGroups] = useState(
    proposal.hampers_groups.length > 0
      ? proposal.hampers_groups
      : [
          {
            id: "",
            name: "",
            position: 1,
            show_catalogue: false,
            hampers: [],
          },
        ]
  );

  const [loading, setLoading] = useState(false);

  const { handleError, handleSuccess } = useError();

  let onShowCatalogueChange = (index, value) => {
    onValuesChange(index, {
      target: {
        name: "show_catalogue",
        value: value,
      },
    });
  };

  let onValuesChange = (index, e) => {
    let newGroups = [...groups];
    newGroups[index] = {
      ...groups[index],
      [e.target.name]: e.target.value,
    };

    setGroups(newGroups);
  };

  let onHampersValuesChange = (index, productIndex, e, isCopy = false) => {
    let newGroups = [...groups];
    let group = newGroups[index];

    group.hampers[productIndex] = {
      ...group.hampers[productIndex],
      [e.target.name]: e.target.value,
    };

    let isPurchaseUpdate = e.target.name === "purchase";
    let isMarginUpdate = e.target.name === "margin";

    if (isPurchaseUpdate || isMarginUpdate) {
      let number =
        (isPurchaseUpdate
          ? parseInt(e.target.value.replace(/\./g, ""), 10)
          : parseInt(group.hampers[productIndex].purchase.replace(/\./g, ""), 10)) /
        (1 - (isMarginUpdate ? parseInt(e.target.value, 10) : group.hampers[productIndex].margin) / 100);

      let decimal = (number / 1000).toFixed(3);
      let round = parseInt(Math.ceil(decimal).toString() + "000");

      group.hampers[productIndex] = {
        ...group.hampers[productIndex],
        total: round,
      };
    }

    if (isCopy) {
      group.hampers[productIndex] = {
        ...group.hampers[productIndex],
        price: group.hampers[productIndex].total ? group.hampers[productIndex].total : 0,
      };
    }

    setGroups(newGroups);
  };

  let addGroup = () => {
    let len = groups.length;
    let newGroups = [...groups];
    newGroups.push({
      name: "",
      position: len + 1,
      hampers: [],
    });
    setGroups(newGroups);
  };

  let removeGroup = (index) => {
    let newGroups = [];
    var position = 1;
    groups.forEach((group, idx) => {
      if (idx !== index) {
        newGroups.push({
          name: group.name,
          position: position++,
          hampers: group.hampers,
        });
      }
    });

    setGroups(newGroups);
  };

  let addProduct = (index, product, values) => {
    let newGroups = [...groups];
    let group = newGroups[index];
    let len = group.hampers.length;
    group.hampers.push({
      name: product.name,
      package_id: product.id,
      position: len + 1,
      package: product,
      price: product.price,
    });

    setGroups(newGroups);
    // onShowCatalogueChange(index, false);

    return true;
  };

  let removeProduct = (index, productIndex) => {
    let newGroups = [...groups];

    let group = newGroups[index];

    let newProducts = [];
    var position = 1;
    group.hampers.forEach((product, idx) => {
      if (idx !== productIndex) {
        newProducts.push({
          id: product.id,
          package_id: product.package_id,
          position: position++,
          product: product.product,
          price: product.price,
        });
      }
    });
    group.hampers = newProducts;

    setGroups(newGroups);
  };

  let renderProduct = (index, product, addedProduct) => {
    return (
      <CatalogueProductForm
        isHamper={true}
        addedItem={addedProduct}
        key={product.id}
        product={product}
        onSubmit={(product, values) => addProduct(index, product, values)}
        hideNote={true}
        hideVariations={true}
        hideHover={true}
      />
    );
  };

  let onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const { err } = await api.controllers.updateProposalHampers(proposal.id, {
      groups: groups.map((item) => {
        item.hampers = item.hampers.map((product) => {
          return {
            ...product,
            price: typeof product.price === "string" ? parseInt(product.price.replace(/\./g, ""), 10) : product.price,
          };
        });
        return item;
      }),
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      handleSuccess("Hampers updated");
      window.location && window.location.reload();
    }
  };

  const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  return (
    <>
      <form onSubmit={onSubmit}>
        {groups &&
          groups.map((group, index) => (
            <div className="w-full mb-4" key={`group-${index}`}>
              <div className="bg-gray-100 p-2 flex justify-between">
                <div className="w-1/2 flex">
                  <div className="w-1/2 mr-4">
                    <input
                      name="name"
                      type="text"
                      className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                      placeholder="Product group"
                      value={group.name}
                      required
                      onChange={(e) => onValuesChange(index, e)}
                    />
                  </div>
                </div>
                <div className="w-1/5 text-right">
                  <button className="bg-gray-100 p-2 rounded-lg" type="button" onClick={() => removeGroup(index)}>
                    <i className="fas fa-trash" />
                  </button>
                </div>
              </div>

              <div className="bg-gray-50 grid grid-cols-2 xl:grid-cols-4 gap-4 p-2">
                {group.hampers &&
                  group.hampers.length > 0 &&
                  group.hampers.map((product, productIndex) => {
                    let imageUrl =
                      process.env.REACT_APP_ENVIRONMENT === "production"
                        ? product.package?.image_url || "https://picsum.photos/200"
                        : "https://picsum.photos/200";

                    return (
                      <div className="bg-white shadow-lg relative" key={`product-${productIndex}`}>
                        <div>
                          <img src={imageUrl} alt={product.package?.name} className="w-full aspect-square rounded-t" />
                          <div className="p-4">
                            <h4 className="mb-2">{product.package?.name}</h4>
                            <div className="grid grid-cols-[1fr_1fr_1fr_40px] gap-2 mb-2">
                              <div className="grid  items-center">
                                <input
                                  name="purchase"
                                  type="text"
                                  maxLength={20}
                                  className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                                  placeholder="Purchase"
                                  value={product.purchase ? addCommas(removeNonNumeric(product.purchase)) : ""}
                                  // value={product.purchase}
                                  onChange={(e) => onHampersValuesChange(index, productIndex, e)}
                                />
                              </div>
                              <div className="grid  items-center">
                                <input
                                  name="margin"
                                  type="number"
                                  min={0}
                                  className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                                  placeholder="Margin"
                                  value={product.margin || 0}
                                  // value={product.margin ? addCommas(removeNonNumeric(product.margin)) : ""}
                                  onChange={(e) => onHampersValuesChange(index, productIndex, e)}
                                />
                              </div>
                              <div className="grid  items-center">
                                <p className="border-0 px-3 py-3 text-slate-600 bg-gray-100 rounded text-sm shadow w-full">
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    maximumFractionDigits: 0,
                                  }).format(product.total || 0)}
                                </p>
                              </div>
                              <div
                                onClick={(e) => onHampersValuesChange(index, productIndex, e, true)}
                                className="w-full flex items-center justify-center cursor-pointer text-lg text-white bg-primary py-2 rounded-md"
                              >
                                🢃
                              </div>
                            </div>

                            <div className="grid grid-cols-2 items-center mb-2">
                              <p className="mr-2">Final Price</p>
                              <input
                                name="price"
                                maxLength={20}
                                type="text"
                                className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                                placeholder="Price"
                                // value={product.price.toString().replace(/,/g, "").toLocaleString()}
                                value={addCommas(removeNonNumeric(product.price))}
                                onChange={(e) => onHampersValuesChange(index, productIndex, e)}
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          className="absolute"
                          type="button"
                          onClick={() => removeProduct(index, productIndex)}
                          style={{
                            top: "-8px",
                            right: "-8px",
                          }}
                        >
                          <i className="fas fa-times-circle" />
                        </button>
                      </div>
                    );
                  })}

                {!group.show_catalogue && (
                  <button
                    className="bg-white p-2 h-24 border"
                    type="button"
                    onClick={() => onShowCatalogueChange(index, true)}
                  >
                    Add <i className="fas fa-plus" />
                  </button>
                )}
              </div>

              <div>
                {group.show_catalogue && (
                  <div className="pl-4 pr-8 my-8">
                    <h3 className="text-center font-bold text-2xl mb-4">Add a Hamper</h3>
                    <Cataloguehampers renderProduct={(product) => renderProduct(index, product, [])} />
                  </div>
                )}
              </div>
            </div>
          ))}

        <div className="text-center flex justify-center">
          <button className="bg-gray-100 p-2 rounded-lg mr-4" type="button" onClick={addGroup}>
            Add Group
          </button>
          <button className="bg-primary p-2 rounded-lg text-white font-bold flex" type="submit">
            {loading && <Spinner />} Save
          </button>
        </div>
      </form>
    </>
  );
}
