import { useEffect, useState } from "react";

export default function ProductVariations({
  onVariationsChange,
  defaultVariations,
  shopeeVariation,
  shopeeVariationPrice,
}) {
  const [variations, setVariations] = useState([]);

  let addVariations = (_variations) => {
    let newVariations = [...variations];
    if (_variations) {
      _variations.forEach((_variation) => newVariations.push(_variation));
    } else {
      newVariations.push({
        name: "",
        required: true,
        recipient_variation: false,
        sync: false,
        options: [
          {
            name: "",
            price: 0,
          },
        ],
      });
    }

    setVariations(newVariations);
  };

  useEffect(() => {
    if (shopeeVariation) {
      const newVariations = shopeeVariation.map((variation) => ({
        name: variation.name,
        required: true,
        recipient_variation: false,
        sync: true,
        options: variation.options,
      }));

      setVariations(newVariations);
    } else {
      setVariations([]);
    }
  }, [shopeeVariation, shopeeVariationPrice]); // eslint-disable-line react-hooks/exhaustive-deps

  let removeVariation = (index) => {
    let newVariations = [];
    variations.forEach((variation, idx) => {
      if (idx !== index) {
        newVariations.push(variation);
      }
    });

    setVariations(newVariations);
  };

  useEffect(() => {
    if (defaultVariations) {
      addVariations(defaultVariations);
    }
  }, [defaultVariations]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onVariationsChange(variations);
  }, [variations]); // eslint-disable-line react-hooks/exhaustive-deps

  let onValuesChecked = (index, e) => {
    let variation = variations[index];
    let newVariations = [...variations];

    newVariations[index] = {
      ...variation,
      [e.target.name]: !variation[e.target.name],
    };

    setVariations(newVariations);
  };

  let onValuesChange = (index, e) => {
    let newVariations = [...variations];
    newVariations[index] = {
      ...variations[index],
      [e.target.name]: e.target.value,
    };

    setVariations(newVariations);
  };

  let addVariationOptions = (index, options) => {
    let newVariations = [...variations];

    if (options) {
      options.forEach((option) => newVariations[index].options.push(option));
    } else {
      newVariations[index].options.push({
        name: "",
        price: 0,
      });
    }

    setVariations(newVariations);
  };

  let removeVariationOption = (index, optionIndex) => {
    let newVariations = [...variations];
    let options = [];
    newVariations[index].options.forEach((option, idx) => {
      if (idx !== optionIndex) {
        options.push(option);
      }
    });
    newVariations[index].options = options;

    setVariations(newVariations);
  };

  let onOptionValueChange = (variationIndex, optionIndex, e) => {
    let newVariations = [...variations];
    newVariations[variationIndex].options[optionIndex] = {
      ...newVariations[variationIndex].options[optionIndex],
      [e.target.name]: e.target.value,
    };

    setVariations(newVariations);
  };

  return (
    <>
      <div className="w-full">
        {variations.map((variation, index) => {
          return (
            <div className="py-4 px-4 rounded bg-white mb-4 relative" key={`variation-${index}`}>
              <button
                className="absolute"
                style={{
                  top: "-.5rem",
                  right: "-.5rem",
                }}
                onClick={(e) => removeVariation(index)}
              >
                <i className="fas fa-times-circle"></i>
              </button>

              <div className="w-full items-center mb-4">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                  Variation{" "}
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <input
                  name="name"
                  type="text"
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow w-full"
                  placeholder="Size"
                  value={variation.name}
                  onChange={(e) => onValuesChange(index, e)}
                />
              </div>

              <div className="flex mt-2 items-center mb-4">
                <label className="text-xs text-gray-400 mr-4">
                  <input
                    name="required"
                    type="checkbox"
                    checked={variation.required}
                    onChange={(e) => onValuesChecked(index, e)}
                    className="mr-2 border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow"
                  />
                  Required
                </label>

                <label className="text-xs text-gray-400 mr-4">
                  <input
                    name="recipient_variation"
                    type="checkbox"
                    checked={variation.recipient_variation}
                    onChange={(e) => onValuesChecked(index, e)}
                    className="mr-2 border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow"
                  />
                  Chosen by recipient?
                </label>

                <label className="text-xs text-gray-400">
                  <input
                    name="sync"
                    type="checkbox"
                    checked={variation.sync}
                    onChange={(e) => onValuesChecked(index, e)}
                    className="mr-2 border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow"
                  />
                  sync?
                </label>
              </div>

              {variation.options.map((option, optionIndex) => (
                <div
                  key={`variation-${index}-option-${optionIndex}`}
                  className="bg-gray-100 p-2 rounded-lg mb-4 relative"
                >
                  <button
                    className="absolute text-gray-400"
                    style={{
                      top: "-.5rem",
                      right: "-.5rem",
                    }}
                    onClick={(e) => removeVariationOption(index, optionIndex)}
                  >
                    <i className="fas fa-times-circle"></i>
                  </button>

                  <div className="flex gap-x-4">
                    <div className="w-1/2">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Option{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="name"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="XL"
                        value={option.name}
                        onChange={(e) => onOptionValueChange(index, optionIndex, e)}
                      />
                    </div>
                    <div className="w-1/2">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Add Cost{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="price"
                        type="number"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="0"
                        value={option.price}
                        onChange={(e) => onOptionValueChange(index, optionIndex, e)}
                      />
                    </div>
                  </div>
                </div>
              ))}

              <button
                className="w-full bg-gray-400 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow"
                type="button"
                onClick={(e) => addVariationOptions(index)}
              >
                Add Option
              </button>
            </div>
          );
        })}
      </div>

      <button
        className="w-full bg-primary text-white font-bold uppercase text-xs px-4 py-2 mb-5 rounded shadow"
        type="button"
        onClick={(e) => addVariations()}
      >
        Add Variation
      </button>
    </>
  );
}
