import React from "react";
import { Link } from "react-router-dom";

import UserDropdown from "components/Dropdowns/UserDropdown.js";
import MenuDropdown from "components/Dropdowns/MenuDropdown";
import BurgerMenu from "./BurgerMenu";

export default function Navbar() {
  const logoUrl = require("assets/img/logo-font.png").default;
  let pathName = window.location.pathname;

  const menus = [];
  menus.push({
    path: "/",
    label: "Campaigns",
    icon: "gift",
  });
  menus.push({
    label: "Catalogue",
    icon: "search",
    submenus: [
      {
        path: "/products",
        label: "Products",
        icon: "gift",
      },
      {
        path: "/tags",
        label: "Tags",
        icon: "tag",
      },
      {
        path: "/categories",
        label: "Categories",
        icon: "star",
      },
      {
        path: "/packages",
        label: "Packages",
        icon: "star",
      },
      {
        path: "/brands",
        label: "Brands",
        icon: "star",
      },
    ],
  });
  menus.push({
    path: "/proposals",
    label: "Proposals",
    icon: "file-invoice",
  });
  menus.push({
    path: "/clients",
    label: "Clients",
    icon: "building",
  });
  menus.push({
    label: "Settings",
    icon: "cogs",
    submenus: [
      {
        path: "/vouchers",
        label: "Vouchers",
        icon: "discount",
      },
      {
        path: "/users",
        label: "Users",
        icon: "user",
      },
    ],
  });

  return (
    <>
      {/* Navbar */}
      <nav className="bg-white shadow-lg fixed top-0 left-0 w-full z-50 justify-between flex items-center p-4">
        <div className="items-center flex md:px-10">
          <Link className="block mr-20 text-sm uppercase font-bold" to="/">
            <img alt="Yippy" className="h-8" src={logoUrl} />
          </Link>

          <ul className="hidden sm:flex list-none">
            {menus.map((menu, index) => (
              <li className="items-center mr-8" key={`menu-${index}`}>
                {!menu.submenus && (
                  <>
                    <Link
                      className={
                        "flex items-center text-xs uppercase py-3 font-bold " +
                        (pathName === menu.path ? "text-primary" : "text-slate-700 hover:text-primary")
                      }
                      to={menu.path}
                    >
                      <i className={`fas fa-${menu.icon} mr-4 text-sm`}></i> {menu.label}
                    </Link>
                  </>
                )}

                {menu.submenus && <MenuDropdown menu={menu} />}
              </li>
            ))}
          </ul>
        </div>

        <div className="hidden sm:block px-10">
          {/* User */}
          <UserDropdown />
        </div>

        <BurgerMenu />
      </nav>
      {/* End Navbar */}
    </>
  );
}
