import React, { useEffect, useState } from "react";
import api from "lib/api";
import { useHistory, useParams } from "react-router";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";

export default function ClientEditRecipient() {
  const [recipient, setRecipient] = useState(null);
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone_number: "",
    birthdate: "",
    onboarding_date: "",
    default_address: "",
    active: true,
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id, RecipientId } = useParams();
  const { handleError } = useError();

  useEffect(() => {
    const get = async () => {
      const { data, err } = await api.controllers.getRecipient(id, RecipientId);
      if (!err) {
        const _recipient = data.data;
        setRecipient(_recipient);
        setValues({
          name: _recipient.name ? _recipient.name : "",
          email: _recipient.email ? _recipient.email : "",
          phone_number: _recipient.phone_number ? _recipient.phone_number : "",
          default_address: _recipient.default_address ? _recipient.default_address : "",
          birthdate: _recipient.birthdate ? _recipient.birthdate : "",
          onboarding_date: _recipient.onboarding_date ? _recipient.onboarding_date : "",
          active: _recipient.active,
        });
      }
    };
    get();
  }, [id, RecipientId]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const onCheckboxClicked = (e) => {
    setValues({ ...values, [e.target.name]: !values[e.target.name] });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { err } = await api.controllers.updateRecipient(id, RecipientId, values);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      history.push(`/clients/${id}/recipients`);
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-slate-700 text-xl font-bold">Edit {recipient && recipient.name}</h6>
            <button
              className="flex bg-primary text-white font-bold uppercase text-xs px-4 py-2 rounded shadow mr-1"
              type="submit"
              onClick={(e) => onSubmit(e)}
            >
              {loading && <Spinner size={4} />} Save
            </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={onSubmit}>
            <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">Recipient Information</h6>
            <div className="flex flex-wrap w-full lg:w-6/12">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Name{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="name"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Budi"
                    required={true}
                    value={values.name || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Email address</label>
                  <input
                    name="email"
                    type="email"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="email@example.com"
                    value={values.email || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Phone number </label>
                  <input
                    name="phone_number"
                    type="tel"
                    pattern="[0-9]{7,}"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="08123456789"
                    value={values.phone_number || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Birthdate</label>
                  <input
                    name="birthdate"
                    type="date"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={values.birthdate || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Onboarding Date</label>
                  <input
                    name="onboarding_date"
                    type="date"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={values.onboarding_date || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Address</label>
                  <input
                    name="default_address"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={values.default_address || ""}
                    placeholder="Jalan ..."
                    required={true}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="w-full px-4">
                <div className="flex relative mb-3 items-center">
                  <input
                    name="active"
                    type="checkbox"
                    className="border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2"
                    checked={values.active || values.active === "true" ? true : false}
                    onChange={onCheckboxClicked}
                  />
                  <label className="block uppercase text-slate-600 text-xs font-bold">Active</label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
