export default function couriers() {
  return [
    {
      value: "gojek",
      label: "Gojek",
    },
    {
      value: "grab",
      label: "Grab",
    },
    {
      value: "shopee",
      label: "Shopee",
    },
    {
      value: "lalamove",
      label: "Lalamove",
    },
    {
      value: "jne",
      label: "JNE",
    },
    {
      value: "jnt",
      label: "JNT",
    },
    {
      value: "jnt_cargo",
      label: "JNT Cargo",
    },
    {
      value: "pos",
      label: "POS Indonesia",
    },
    {
      value: "lion",
      label: "Lion Parcel",
    },
    {
      value: "ninja",
      label: "Ninja Xpress",
    },
    {
      value: "sicepat",
      label: "SiCepat Express",
    },
    {
      value: "sap",
      label: "SAP Express",
    },
    {
      value: "anteraja",
      label: "AnterAja",
    },
    {
      value: "jet",
      label: "JET Express",
    },
    {
      value: "tiki",
      label: "TIKI",
    },
    {
      value: "rpx",
      label: "RPX",
    },
    {
      value: "wahana",
      label: "Wahana",
    },
    {
      value: "rayspeed",
      label: "Rayspeed",
    },
    {
      value: "borzo",
      label: "Borzo",
    },
  ];
}
