import React, { useEffect, useState } from "react";

import api from "lib/api"
import CardStats from "components/Cards/CardStats";

export default function OrderStats() {
    const [data, setData] = useState({
        recipient_count: 0,
        address_completed_count: 0,
        processing_count: 0,
        delivering_count: 0,
        delivered_count: 0
    })

    useEffect(() => {
        const get = async () => {
            const {data: _data, err} = await api.controllers.getOrderSummary()
            if (!err) {
                setData(_data.data)
            }
        }
        get()
    }, [])

    return <>
        <div className="mx-auto w-full">
            <div>
                {/* Card stats */}
                <div className="flex flex-wrap">
                    <div className="w-full md:w-4/12 px-4">
                        <CardStats
                            statSubtitle="UNCLAIMED"
                            statTitle={`${data.recipient_count - data.address_completed_count}`}
                            statIconName="fas fa-building"
                            statIconColor="bg-red-500"
                        />
                    </div>
                    <div className="w-full md:w-4/12 px-4">
                        <CardStats
                            statSubtitle="PROCESSING"
                            statTitle={`${data.processing_count - data.delivering_count}`}
                            statIconName="fas fa-gifts"
                            statIconColor="bg-orange-500"
                        />
                    </div>
                    <div className="w-full md:w-4/12 px-4">
                        <CardStats
                            statSubtitle="DELIVERING"
                            statTitle={`${data.delivering_count - data.delivered_count}`}
                            statIconName="fas fa-truck"
                            statIconColor="bg-green-500"
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
}