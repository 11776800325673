import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
import { AuthProvider } from "contexts/auth";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || "https://b61fc86d27d34b3986587b684c5fb786@o1194500.ingest.sentry.io/6317348",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

// views without layouts

ReactDOM.render(
  <AuthProvider>
    <BrowserRouter>
      <Switch>
        {/* add routes with layouts */}
        <Route path="/auth" component={Auth} />
        <Route path="/" component={Admin} />
        {/* add redirect for first page */}
      </Switch>
    </BrowserRouter>
  </AuthProvider>,
  document.getElementById("root")
);
