import { useEffect, useState } from "react";

export default function ProductVariations({ onDiscountsChange, defaultDiscounts }) {
  const [discounts, setDiscounts] = useState([]);

  let addDiscounts = (_discounts) => {
    let newDiscounts = [...discounts];
    if (_discounts) {
      _discounts.forEach((_discount) => newDiscounts.push(_discount));
    } else {
      newDiscounts.push({
        range_from: 0,
        range_to: 0,
        discount: 0,
      });
    }

    setDiscounts(newDiscounts);
  };

  let removeDiscount = (index) => {
    let newDiscounts = [];
    discounts.forEach((Discount, idx) => {
      if (idx !== index) {
        newDiscounts.push(Discount);
      }
    });

    setDiscounts(newDiscounts);
  };

  useEffect(() => {
    if (defaultDiscounts) {
      addDiscounts(defaultDiscounts);
    }
  }, [defaultDiscounts]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onDiscountsChange(discounts);
  }, [discounts]); // eslint-disable-line react-hooks/exhaustive-deps

  let onValuesChange = (index, e) => {
    let newDiscounts = [...discounts];
    newDiscounts[index] = {
      ...discounts[index],
      [e.target.name]: parseInt(e.target.value),
    };

    setDiscounts(newDiscounts);
  };

  return (
    <>
      <div className={`w-full ${discounts.length > 0 ? "bg-white py-5 px-3 rounded-md" : ""}`}>
        {discounts.length > 0 && (
          <>
            <p className="text-slate-700 text-lg font-bold mb-1">Bulk Price</p>
            {discounts.map((Discount, index) => {
              return (
                <div className="py-4 px-4 rounded-md bg-white mb-4 relative border" key={`variation-${index}`}>
                  <button
                    className="absolute"
                    style={{
                      top: "-.5rem",
                      right: "-.5rem",
                    }}
                    onClick={(e) => removeDiscount(index)}
                  >
                    <i className="fas fa-times-circle"></i>
                  </button>

                  <div className="grid grid-cols-3 gap-3">
                    <div className="w-full items-center mb-2">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Min{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="range_from"
                        type="number"
                        min={0}
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow w-full"
                        placeholder="Quantity"
                        value={Discount.range_from}
                        onChange={(e) => onValuesChange(index, e)}
                      />
                    </div>

                    <div className="w-full items-center mb-2">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Max{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="range_to"
                        type="number"
                        min={0}
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow w-full"
                        placeholder="Quantity"
                        value={Discount.range_to}
                        onChange={(e) => onValuesChange(index, e)}
                      />
                    </div>

                    <div className="w-full items-center mb-2">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Discount{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="discount"
                        type="number"
                        min={0}
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow w-full"
                        placeholder="12345"
                        value={Discount.discount}
                        onChange={(e) => onValuesChange(index, e)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>

      <button
        className="w-full bg-primary text-white font-bold uppercase text-xs px-4 py-2 mb-5 rounded shadow"
        type="button"
        onClick={(e) => addDiscounts()}
      >
        Add Discount
      </button>
    </>
  );
}
