import Modal from "components/Modal/Modal";
import { useEffect, useState } from "react";
import compress from "lib/compress";
import api from "lib/api";
import { useHistory } from "react-router";
import { useError } from "contexts/error";
import Spinner from "components/Spinner";

export default function AddPackages({ onClose }) {
  const [values, setValues] = useState({ name: "", position: 1, tag_id: "", image: "", description: "" });
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const { handleError, handleSuccess } = useError();
  const history = useHistory();

  let handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  let onFileChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files[0] });
  };

  let onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const compressedImage = values.image ? await compress(values.image) : null;
    const { err, data } = await api.controllers.createPackage({
      name: values.name,
      tag_id: Number(values.tag_id),
      image: compressedImage,
      description: values.description,
      position: values.position,
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      handleSuccess("Package created");
      history.push(`/packages/${data.data.id}`);
    }
  };

  useEffect(() => {
    const get = async () => {
      const { data, err } = await api.controllers.getTags(1, -1);
      if (!err) {
        setTags(data.data.filter((tag) => tag.type === "package"));
      }
    };
    get();
  }, []);

  useEffect(() => {
    if (tags.length > 0) {
      setValues((values) => ({ ...values, tag_id: tags[0].id }));
    }
  }, [tags]);

  let body = (
    <form onSubmit={onSubmit} className="flex justify-center">
      <div className="w-full lg:w-6/12">
        <div className="relative w-full mb-3">
          <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
            Name{" "}
            <span className="text-red-500">
              <sup>*</sup>
            </span>
          </label>
          <input
            name="name"
            type="text"
            className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full placeholder:text-gray-300"
            placeholder="package-1"
            value={values.name}
            onChange={handleChange}
          />
        </div>

        <div className="flex gap-4">
          <div className="relative w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
              Category{" "}
              <span className="text-red-500">
                <sup>*</sup>
              </span>
            </label>
            <select
              className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              name="tag_id"
              onChange={handleChange}
              values={values.tag_id}
            >
              {tags.length > 0 &&
                tags
                  .filter((tag) => tag.type === "package")
                  .map((tag, index) => (
                    <option key={`tag - ${index}`} value={tag.id}>
                      {tag.name}
                    </option>
                  ))}
            </select>
          </div>

          <div className="relative w-32 mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
              Position{" "}
              <span className="text-red-500">
                <sup>*</sup>
              </span>
            </label>
            <input
              name="position"
              type="number"
              min={1}
              className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full placeholder:text-gray-300"
              placeholder="123"
              value={values.position}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="relative w-full mb-3">
          <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Description</label>
          <textarea
            name="description"
            className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="Deskripsi"
            value={values.description}
            onChange={handleChange}
            rows={8}
          ></textarea>
        </div>

        <div className="relative w-full mb-3">
          <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Image</label>
          <input
            name="image"
            type="file"
            className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
            onChange={onFileChange}
            accept="image/png, image/jpeg"
          />
          <p className="text-xs text-gray-400 mt-2">PNG, JPG up to 2MB</p>
        </div>

        <div className="relative w-full mb-3">
          <button
            className="w-full flex justify-center bg-sky-500 text-white font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md"
            type="submit"
          >
            {loading && <Spinner size={4} />} Save
          </button>
        </div>
      </div>
    </form>
  );

  return <Modal title={`Add Package`} onClose={() => onClose()} body={body} />;
}
