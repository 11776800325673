import Table from "components/Table/Table";
import api from "lib/api";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddPackages from "./AddPackages";

export default function PackagesList() {
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);
  const [filterValues, setFilterValues] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const getPackages = useCallback(async () => {
    const { data, err } = await api.controllers.getPackages(pagination.page, pagination.limit, filterValues);
    if (!err) {
      setData(data);
    }
  }, [filterValues, pagination]);

  useEffect(() => {
    pagination && getPackages();
  }, [pagination, getPackages]);

  useEffect(() => {
    filterValues && getPackages();
  }, [filterValues, getPackages]);

  let onFilter = async (_values) => {
    setFilterValues(_values);
  };

  let onClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Table
            settings={{
              title: "Packages",
              button: {
                label: "Add",
                onClick: () => {
                  setShowModal(true);
                },
              },
              headers: [
                {
                  label: "Name",
                  render: (element) => {
                    return (
                      <Link to={`/packages/${element.id}`} className={"underline flex items-center gap-3"}>
                        <img
                          src={element.image_url}
                          alt={element.name}
                          className="w-16 h-16 rounded-sm bg-white border border-gray-400 object-contain"
                        />
                        {element.name}
                      </Link>
                    );
                  },
                },
                {
                  label: "Product Count",
                  field: "product_count",
                },
                {
                  label: "Position",
                  field: "position",
                },
                {
                  label: "Price",
                  render: (element) => {
                    return new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(element.price);
                  },
                },
              ],
              filters: [
                {
                  label: "Name",
                  field: "name",
                },
              ],
            }}
            data={data}
            setPagination={setPagination}
            onFilter={onFilter}
          />
        </div>
      </div>

      {showModal && <AddPackages onClose={onClose} />}
    </>
  );
}
