import React, { useEffect, useState } from "react";

import Table from "components/Table/Table.js";
import api from "lib/api";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

export default function BrandsList() {
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);

  const history = useHistory();

  const get = async () => {
    const { data, err } = await api.controllers.getBrands({
      page: pagination.page,
      limit: pagination.limit,
    });
    if (!err) {
      setData(data);
    }
  };

  useEffect(() => {
    pagination && get();
  }, [pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Table
            settings={{
              title: "Brands",

              button: {
                label: "Add",
                onClick: () => {
                  history.push("/brands/new");
                },
              },
              headers: [
                {
                  label: "Name",
                  render: (element) => {
                    return (
                      <Link to={`/brands/${element.id}`} className={"underline flex items-center gap-3"}>
                        <img
                          src={element.logo_url}
                          alt={element.name}
                          className="w-16 h-16 rounded-sm bg-white border border-gray-400 object-contain"
                        />
                        {element.name}
                      </Link>
                    );
                  },
                },
                {
                  label: "Status",
                  render: (element) => {
                    return element.active ? "Active" : "Not Active";
                  },
                },
              ],
            }}
            data={data}
            setPagination={setPagination}
          />
        </div>
      </div>
    </>
  );
}
