import React, { useEffect, useState } from "react";

import api from "lib/api";
import { useHistory, useParams } from "react-router";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import compress from "lib/compress";

export default function EditClient() {
  const [client, setClient] = useState(null);
  const [values, setValues] = useState({
    name: "",
    logo: null,
    logo_url: "",
    address: "",
    payment_term: "forward_dating",
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const { handleError, handleSuccess } = useError();

  useEffect(() => {
    const get = async () => {
      const { data, err } = await api.controllers.getClient(id);
      if (!err) {
        const _client = data.data;
        setClient(_client);
        setValues({
          name: _client.name,
          logo_url: _client.logo_url,
          address: _client.address,
          payment_term: _client.payment_term,
        });
      }
    };
    get();
  }, [id]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const onFileChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files[0] });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const compressedImage = values.logo ? await compress(values.logo) : null;
    const { err } = await api.controllers.updateClient(id, {
      name: values.name,
      logo: compressedImage,
      address: values.address,
      payment_term: values.payment_term,
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      handleSuccess("Client updated");
      history.push("/clients");
    }
  };

  const deleteAddress = async (id) => {
    const { err } = await api.controllers.deleteClient(id);

    if (err) {
      handleError(err);
    } else {
      handleSuccess("Client deleted");
      history.push("/clients");
    }
  };

  function onDelete(id) {
    let text = `Are you sure, you want to delete ${client && client.name}?`;
    if (window.confirm(text) === true) {
      deleteAddress(id);
    }
  }

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-slate-700 text-xl font-bold">Edit {client && client.name}</h6>

            <div className="flex items-center gap-4">
              <div
                className="flex cursor-pointer bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                onClick={() => onDelete(client.id)}
              >
                {loading && <Spinner size={4} />} Delete
              </div>
              <button
                className="flex bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit"
                onClick={(e) => onSubmit(e)}
              >
                {loading && <Spinner size={4} />} Save
              </button>
            </div>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={onSubmit}>
            <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">Client Information</h6>
            <div className="flex flex-wrap w-full lg:w-6/12">
              <div className="w-full">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Name{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="name"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Yippy"
                    value={values.name ? values.name : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="w-full">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Logo</label>
                  <input
                    name="logo"
                    type="file"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow w-full"
                    onChange={onFileChange}
                    accept="image/png, image/jpeg"
                  />
                  <p className="text-xs text-gray-400 mt-2">PNG, JPG up to 2MB</p>

                  {values.logo_url && <img src={values.logo_url} alt="logo" className={"h-24 aspect-square"} />}
                </div>
              </div>

              <div className="w-full">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Address</label>
                  <input
                    name="address"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Jalan "
                    value={values.address ? values.address : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* <div className="w-full">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Payment Term</label>
                  <select
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="payment_term"
                    onChange={handleChange}
                    value={values.payment_term ? values.payment_term : ""}
                  >
                    <option value="advance">Advance</option>
                    <option value="forward_dating">Forward Dating</option>
                  </select>
                </div>
              </div> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
