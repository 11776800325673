import { useAuth } from "contexts/auth";
import { useState } from "react";
import { Link } from "react-router-dom";

const BurgerMenu = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const logoUrl = require("assets/img/logo-font.png").default;
  let pathName = window.location.pathname;
  const { logout } = useAuth();
  const { user } = useAuth();

  return (
    <div className="sm:hidden flex items-center justify-between">
      <nav>
        {/* icon  */}
        <section className="flex sm:hidden">
          {/* burger  */}
          <div
            className={`${isNavOpen ? "hidden" : ""} space-y-2 cursor-pointer text-right`}
            onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
          >
            <span className="block h-0.5 w-8 animate-pulse bg-black"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-black"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-black"></span>
          </div>

          {/* X  */}
          <div className={isNavOpen ? "showMenuNav shadow" : "hideMenuNav"}>
            <div className="px-5">
              <Link className="" to="/">
                <img alt="Yippy" className="h-10 mt-5" src={logoUrl} />
              </Link>

              <div className="absolute top-0 right-0 px-7 py-6 cursor-pointer" onClick={() => setIsNavOpen(false)}>
                <svg
                  className="h-8 w-8 text-gray-600"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </div>
            </div>

            {/* menu  */}
            <ul className=" bg-white">
              <li className="my-3 ">
                <div className="border-y border-tertiary_gray_one">
                  <div className={`relative flex justify-between items-center pl-5 px-4 py-2 font-bold`}>
                    <div className="">
                      <i className={`fas fa-user  text-sm mr-6 pl-4`}></i>
                      {user.name}
                    </div>
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        logout();
                      }}
                      className="px-3 py-1 hover:underline rounded-md bg-tertiary_gray_one font-medium cursor-pointer flex items-center gap-3"
                    >
                      Log Out<i className={`fas fa-sign-out-alt block text-sm text-red-500`}></i>
                    </p>
                  </div>
                </div>

                {/* Proposals  */}
                <div className="border-b border-tertiary_gray_one pl-5 ">
                  <Link to={"/"}>
                    <div
                      onClick={() => setIsNavOpen((prev) => !prev)}
                      className={`${
                        pathName === "/" ? "text-primary underline" : ""
                      } relative hover:underline w-fit flex justify-between items-center px-4 py-2 font-bold cursor-pointer`}
                    >
                      <i className={`fas fa-gift block text-sm mr-6`}></i>
                      Campaigns
                    </div>
                  </Link>
                </div>

                {/* Campaigns  */}
                <div className="border-b border-tertiary_gray_one pl-5 ">
                  <div className="relative select-none text-center w-fit flex justify-between items-center p-4 font-bold">
                    <i className={`fas fa-search block text-sm mr-6`}></i> Catalogue
                  </div>
                  <div className="overflow-hidden">
                    <ul>
                      <li className="flex justify-start items-center mb-2 hover:font-bold">
                        {/* pathName === menu.path */}
                        <Link to={"/products"}>
                          <div
                            onClick={() => setIsNavOpen((prev) => !prev)}
                            className={`${
                              pathName === "/products" ? "text-primary underline" : ""
                            } hover:underline w-36 flex items-center pl-14`}
                          >
                            Products
                          </div>
                        </Link>
                      </li>
                      <li className="flex justify-start items-center mb-2 hover:font-bold">
                        <Link to={"/tags"}>
                          <div
                            onClick={() => setIsNavOpen((prev) => !prev)}
                            className={`${
                              pathName === "/tags" ? "text-primary underline" : ""
                            } hover:underline w-36 flex items-center pl-14`}
                          >
                            Tags
                          </div>
                        </Link>
                      </li>
                      <li className="flex justify-start items-center mb-2 hover:font-bold">
                        <Link to={"/categories"}>
                          <div
                            onClick={() => setIsNavOpen((prev) => !prev)}
                            className={`${
                              pathName === "/categories" ? "text-primary underline" : ""
                            } hover:underline w-36 flex items-center pl-14`}
                          >
                            Categories
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Proposals  */}
                <div className="border-b border-tertiary_gray_one pl-5 ">
                  <Link to={"/proposals"}>
                    <div
                      onClick={() => setIsNavOpen((prev) => !prev)}
                      className={`${
                        pathName === "/proposals" ? "text-primary underline" : ""
                      } relative hover:underline w-fit flex justify-between items-center px-4 py-2 font-bold cursor-pointer`}
                    >
                      <i className={`fas fa-file-invoice block text-sm mr-7`}></i>
                      Proposals
                    </div>
                  </Link>
                </div>

                {/* Clients  */}
                <div className="border-b border-tertiary_gray_one pl-5 ">
                  <Link to={"/clients"}>
                    <div
                      onClick={() => setIsNavOpen((prev) => !prev)}
                      className={`${
                        pathName === "/clients" ? "text-primary underline" : ""
                      } relative hover:underline w-fit flex justify-between items-center px-4 py-2 font-bold cursor-pointer`}
                    >
                      <i className={`fas fa-users block text-sm mr-6`}></i>
                      Clients
                    </div>
                  </Link>
                </div>

                {/* Setting  */}
                <div className="pl-5 ">
                  <div className="relative select-none text-center w-fit flex justify-between items-center px-4 pb-4 pt-2 font-bold">
                    <i className={`fas fa-cogs block text-sm mr-6`}></i> Setting
                  </div>

                  <div className="overflow-hidden">
                    <ul>
                      <li className="flex justify-start items-center mb-2 hover:font-bold">
                        <Link to={"/vouchers"}>
                          <div
                            onClick={() => setIsNavOpen((prev) => !prev)}
                            className={`${
                              pathName === "/vouchers" ? "text-primary underline" : ""
                            } hover:underline w-36 flex items-center pl-14`}
                          >
                            Vouchers
                          </div>
                        </Link>
                      </li>
                      <li className="flex justify-start items-center mb-2 hover:font-bold">
                        <Link to={"/users"}>
                          <div
                            onClick={() => setIsNavOpen((prev) => !prev)}
                            className={`${
                              pathName === "/users" ? "text-primary underline" : ""
                            } hover:underline w-36 flex items-center pl-14`}
                          >
                            Users
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </nav>
    </div>
  );
};

export default BurgerMenu;
