import React from "react";

export default function Preview({ data }) {
  const img =
    data.image ||
    "https://yippy.s3.ap-southeast-1.amazonaws.com/static/email-gifts.jpeg";

  return (
    <div className="flex flex-col min-w-0 py-8 px-6 break-words bg-[#FBE6A380] rounded-lg border border-primary">
      <div className="flex flex-wrap">
        <div className="w-full">
          <h3 className="font-bold text-2xl text-center mb-4">{data.title}</h3>

          <img src={img} alt="Yippy" className="w-full block mb-4 rounded-md" />

          <p
            className="font-medium mb-4"
            dangerouslySetInnerHTML={{ __html: data.body }}
          ></p>

          <button
            className="bg-primary text-white font-bold py-3 rounded-lg shadow w-full"
            type="button"
          >
            {data.action}
          </button>
        </div>
      </div>
    </div>
  );
}
