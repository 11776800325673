import api from "lib/api";
import { useEffect, useState } from "react";
import { useError } from "contexts/error";

export default function OrderPickups({ order }) {
  const [pickups, setPickups] = useState([]);
  const { handleError } = useError();

  useEffect(() => {
    const getPickups = async () => {
      const { err, data } = await api.controllers.getPickups(order.id);

      if (err) {
        handleError(err);
      } else {
        setPickups(data.data);
      }
    };
    getPickups();
  }, [order.id, handleError]);

  let downloadMultiLabel = async (pickup) => {
    const { err, data } = await api.controllers.downloadShippingLabelMulti(order.id, pickup.id);

    if (!err) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `label-${pickup.number}.pdf`);
      document.body.appendChild(link);
      link.click();
    }
  };

  let downloadLabel = async (recipient) => {
    const { err, data } = await api.controllers.downloadShippingLabel(order.id, recipient.id);

    if (!err) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `label-${recipient.tracking_number}.pdf`);
      document.body.appendChild(link);
      link.click();
    }
  };

  const convert = (dateString) => new Date(dateString).toLocaleString("en-GB");

  return (
    <div>
      {pickups.map((pickup) => (
        <div key={pickup.id}>
          <h2 className="font-bold mb-4">
            {pickup.provider === "kiriminaja" ? pickup.schedule : convert(pickup.schedule)}
            {pickup.provider === "kiriminaja" && (
              <button className="underline ml-2" onClick={() => downloadMultiLabel(pickup)}>
                <i className="fas fa-file-pdf"></i>
              </button>
            )}
          </h2>
          <div className="flex gap-x-4 mb-4">
            <div className="px-3 py-2 bg-gray-300 font-medium rounded-lg">
              <p>
                {pickup.provider === "kiriminaja" ? "Number : " : " Order ID : "}
                <span className="uppercase">{pickup.number}</span>
              </p>
            </div>
            <div className="px-3 py-2 bg-gray-300 font-medium rounded-lg">
              <p>
                Courier: <span className="uppercase">{pickup.courier}</span>
              </p>
            </div>
            {pickup.provider === "kiriminaja" && (
              <>
                <div className="px-3 py-2 bg-gray-300 font-medium rounded-lg">
                  <p>
                    Courier Service: <span className="uppercase">{pickup.courier_service}</span>
                  </p>
                </div>
                <div className="px-3 py-2 bg-gray-300 font-medium rounded-lg">
                  <p>
                    Item Name: <span>{pickup.item_name}</span>
                  </p>
                </div>
                <div className="px-3 py-2 bg-gray-300 font-medium rounded-lg">
                  <p>
                    Insurance: <span>{pickup.insurance ? "Yes" : "No"}</span>
                  </p>
                </div>
              </>
            )}
          </div>

          <table className="items-center w-full bg-white border-collapse mb-4">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 font-semibold text-left bg-gray-200 text-slate-500 border-slate-100"
                  }
                >
                  Recipient
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 font-semibold text-left bg-gray-200 text-slate-500 border-slate-100"
                  }
                >
                  {pickup.provider === "kiriminaja" ? "AWB" : "Link"}
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 font-semibold text-left bg-gray-200 text-slate-500 border-slate-100"
                  }
                >
                  Cost
                </th>
                <th
                  className={
                    "w-4 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left bg-gray-200 text-slate-500 border-slate-100"
                  }
                >
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {pickup.recipients.map((recipient) => (
                <tr key={recipient.id}>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4">{recipient.name}</td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 break-words max-w-[300px]">
                    {pickups[0].provider === "kiriminaja" ? (
                      recipient.tracking_number
                    ) : (
                      <a href={recipient.tracking_number} target="_blank" rel="noreferrer" className="underline">
                        {recipient.tracking_number}
                      </a>
                    )}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4">
                    Delivery:{" "}
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      maximumFractionDigits: 0,
                    }).format(recipient.delivery_cost)}
                    <br />
                    {recipient.delivery_insurance_cost > 0 && (
                      <>
                        Insurance:{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          maximumFractionDigits: 0,
                        }).format(recipient.delivery_insurance_cost)}
                      </>
                    )}
                  </td>
                  {pickups[0].provider === "kiriminaja" && (
                    <td className="w-4 border-t-0 px-2 justify-center align-middle border-l-0 border-r-0 text-xs p-2">
                      {recipient.tracking_number && (
                        <>
                          <button className="underline ml-2" onClick={() => downloadLabel(recipient)}>
                            <i className="fas fa-file-pdf"></i>
                          </button>
                        </>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>

          <hr className="border-gray-200 my-10" />
        </div>
      ))}
    </div>
  );
}
