import React, { useEffect, useRef, useState } from "react";

import api from "lib/api";
import { useHistory, useParams } from "react-router";
import Spinner from "components/Spinner";
import compress from "lib/compress";
import { useError } from "contexts/error";
import ProductVariations from "./ProductVariations";
import ProductTags from "./ProductTags";
import ProductBulkPrice from "./ProductBulkPrice";

export default function EditProduct() {
  const [product, setProduct] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const [values, setValues] = useState({
    name: "",
    sku: "",
    brand_id: "",
    category_id: "",
    description: "",
    price: 0,
    weight: 0,
    active: true,
    custom_design: false,
    personal: false,
    merchandise: false,
    min_quantity: 1,
    external_url: "",
    quantity: -1,
    purchase_price: 0,
    margin: 0,
    additional_margin: 0,
    internal_notes: "",
    digital: false,
    provider: "iak",
    provider_product_code: "",
    video_url: "",
    production_time: "",
    discount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [imageLoading, setImageLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imageFeatureIndex, setImageFeatureIndex] = useState(null);
  const imageRef = useRef(null);
  const history = useHistory();

  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [variations, setVariations] = useState([]);
  const [defaultVariations, setDefaultVariations] = useState([]);
  const [tags, setTags] = useState([]);
  const [defaultTags, setDefaultTags] = useState([]);

  const { id } = useParams();
  const { handleError, handleSuccess } = useError();

  const getBrands = async () => {
    const { data, err } = await api.controllers.getBrands({
      page: 1,
      limit: -1,
    });
    if (!err) {
      setBrands(data.data);
    }
  };

  const getCategories = async () => {
    const { data, err } = await api.controllers.getCategories({
      page: 1,
      limit: -1,
      parent: 0,
    });
    if (!err) {
      setCategories(data.data);
    }
  };

  const onDiscountsChange = (discounts) => {
    setDiscounts(discounts);
  };

  const getVariations = async () => {
    const { data, err } = await api.controllers.getProductVariations(id, {
      page: 1,
      limit: -1,
    });
    if (!err) {
      const _variations = data.data;
      setVariations(_variations);
      setDefaultVariations(_variations);
    }
  };

  const get = async () => {
    const { data, err } = await api.controllers.getProduct(id);
    if (!err) {
      const _product = data.data;
      setValues({
        name: _product.name,
        sku: _product.sku,
        brand_id: _product.brand_id,
        category_id: _product.category_id,
        description: _product.description,
        active: _product.active,
        price: _product.price,
        discount: _product.discount,
        weight: _product.weight,
        min_quantity: _product.min_quantity,
        custom_design: _product.custom_design,
        personal: _product.personal,
        merchandise: _product.merchandise,
        external_url: _product.external_url,
        quantity: _product.quantity,
        purchase_price: _product.purchase_price,
        margin: _product.margin,
        additional_margin: _product.additional_margin,
        internal_notes: _product.internal_notes ? _product.internal_notes : "",
        video_url: _product.video_url ? _product.video_url : "",
        production_time: _product.production_time ? _product.production_time : "",
        digital: _product.digital,
        provider: _product.provider ? _product.provider : "",
        provider_product_code: _product.provider_product_code ? _product.provider_product_code : "",
      });
      setImages(_product.images);
      setImageFeatureIndex(_product.images.findIndex((obj) => obj.image_url === _product.image_url));
      setDefaultTags(_product.tags.map((tag) => tag.id));
      setProduct(_product);
    } else {
      setNotFound("Product not found");
    }
  };

  useEffect(() => {
    get();
    getCategories();
    getVariations();
    getBrands();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const onCheckboxClicked = (e) => {
    setValues({ ...values, [e.target.name]: !values[e.target.name] });
  };
  const handleNumberChange = (e) => {
    setValues({ ...values, [e.target.name]: parseInt(e.target.value) });
  };

  useEffect(() => {
    let number = (values.purchase_price + values.additional_margin) / (1 - values.margin / 100);

    let decimal = (number / 1000).toFixed(3);
    let round = parseInt(Math.ceil(decimal).toString() + "000");

    setValues((values) => ({
      ...values,
      price: round,
    }));
  }, [values.additional_margin, values.margin, values.purchase_price]);

  const loadImages = async () => {
    const { data, err } = await api.controllers.getImages(id);
    setImageLoading(false);
    if (!err) {
      setImages(data.data);
    }
  };

  const addImage = async (images) => {
    setImageLoading(true);

    for (var i = 0; i < images.length; i++) {
      var compressedImages = await compress(images[i]);
      const { err } = await api.controllers.addProductImage(id, {
        image: compressedImages,
      });
      setImageLoading(false);
      if (!err) {
        loadImages();
      } else {
        handleError(err);
      }
    }
  };

  const onImageAdd = (e) => {
    addImage(e.target.files);
  };

  const onImageClick = () => {
    imageRef && imageRef.current.click();
  };

  const onRemoveImageClick = async (imageId) => {
    const { err } = await api.controllers.removeProductImage(id, imageId);
    if (!err) {
      loadImages();
    }
  };

  const onSetImageFeature = async (imageId, index) => {
    const { err } = await api.controllers.setImageFeature(id, imageId);
    if (!err) {
      handleSuccess("Update successful");
      setImageFeatureIndex(index);
    }
  };

  const onVariationsChange = (_variations) => {
    setVariations(_variations);
  };
  const onTagsChange = (_tags) => {
    setTags(_tags);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { data, err } = await api.controllers.updateProduct(id, {
      name: values.name,
      sku: values.sku,
      description: values.description,
      ...(values.brand_id ? { brand_id: Number(values.brand_id) } : {}),
      category_id: values.category_id,
      price: values.price,
      discount: values.discount ? values.discount : 0,
      weight: values.weight,
      min_quantity: values.min_quantity,
      custom_design: values.custom_design,
      personal: values.personal,
      merchandise: values.merchandise,
      active: values.active,
      variations: variations,
      tags: tags,
      external_url: values.external_url,
      quantity: values.quantity,
      purchase_price: values.purchase_price,
      margin: values.margin,
      additional_margin: values.additional_margin,
      internal_notes: values.internal_notes,
      video_url: values.video_url,
      digital: values.digital,
      production_time: values.production_time,
      ...(values.digital ? { provider: values.provider, provider_product_code: values.provider_product_code } : {}),
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else if (data) {
      const { err } = await api.controllers.updateBulkPrice(product.id, { prices: discounts });

      if (err) {
        handleError(err);
      } else {
        handleSuccess("Update successful");
        window.location.reload();
      }
    }
  };

  const onDelete = async (e) => {
    e.preventDefault();

    if (window.confirm("Are you sure you want to delete this product?")) {
      setDeleteLoading(true);
      const { err } = await api.controllers.deleteProduct(id);
      setDeleteLoading(false);
      if (!err) {
        handleSuccess("Product successfully deleted");
        history.push("/products");
      }
    }
  };

  useEffect(() => {
    setValues((values) => ({ ...values, provider: values.digital ? "iak" : null }));
  }, [values.digital]);

  return (
    <>
      {!product && !notFound && (
        <div className="flex justify-center">
          <Spinner color={"black"} />
        </div>
      )}

      {notFound && (
        <div className="flex justify-center">
          <h2 className="w-fit text-2xl font-semibold">{notFound}</h2>
        </div>
      )}

      {product && (
        <div className="flex flex-wrap">
          <div className="relative flex flex-col min-w-0 break-words w-full md:w-3/5 mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-lg font-bold">Edit {product && product.name}</h6>
                <div className="flex gap-3">
                  <a
                    href={`https://yippy.id/id/katalog/produk/${product.slug}`}
                    target="_blank"
                    rel="noreferrer"
                    className="flex h-fit bg-orange-500 text-white active:bg-orange-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  >
                    Show
                  </a>
                  <button
                    className="flex h-fit bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="submit"
                    onClick={(e) => onSubmit(e)}
                  >
                    {loading && <Spinner size={4} />} Save
                  </button>
                  <button
                    className="flex h-fit bg-red-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="submit"
                    onClick={(e) => onDelete(e)}
                  >
                    {deleteLoading && <Spinner size={4} />} Delete
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={onSubmit}>
                <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">Gift Information</h6>
                <div className="flex flex-wrap w-full">
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Name{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="name"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Lanyard"
                        value={values.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="w-full md:w-1/3 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        SKU{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="sku"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="ABC123"
                        value={values.sku}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="w-full md:w-1/3 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Category{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <select
                        name="category_id"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChange}
                        value={values.category_id}
                      >
                        {categories.map((category) => (
                          <option key={`category-${category.id}`} value={category.id}>
                            {category.parent_name} &gt; {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="w-full md:w-1/3 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Brand</label>
                      <select
                        name="brand_id"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChange}
                        value={values.brand_id ? values.brand_id : ""}
                      >
                        <option value="">Select Brand</option>

                        {brands.map((brand) => (
                          <option key={`brands-${brand.id}`} value={brand.id}>
                            {brand.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="w-full grid grid-cols-3 gap-4 mt-3 mb-5">
                    <div className={`w-full px-4 flex items-center`}>
                      <div className="flex relative items-center mb-1">
                        <input
                          name="digital"
                          type="checkbox"
                          className="border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2"
                          checked={values.digital || values.digital === "true" ? true : false}
                          onChange={onCheckboxClicked}
                        />
                        <label className="block uppercase text-slate-600 text-xs font-bold">Digital?</label>
                      </div>
                    </div>

                    {(values.digital || values.digital === "true") && (
                      <div className="w-full px-4">
                        <label className="block uppercase text-xs font-bold mb-2">Provider</label>
                        <select
                          name="provider"
                          className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                          value={values.provider}
                          onChange={handleChange}
                        >
                          <option value="iak">IAK</option>
                        </select>
                      </div>
                    )}

                    {(values.digital || values.digital === "true") && (
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                            Product Code{" "}
                            <span className="text-red-500">
                              <sup>*</sup>
                            </span>
                          </label>
                          <input
                            name="provider_product_code"
                            type="text"
                            className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="xxx"
                            value={values.provider_product_code}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Description{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <textarea
                        name="description"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Deskripsi"
                        value={values.description}
                        onChange={handleChange}
                        rows={8}
                      ></textarea>
                    </div>
                  </div>

                  <div className="w-1/2 md:w-1/4 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Purchase (Rp){" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="purchase_price"
                        type="number"
                        min={0}
                        className={`border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white
                         rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        placeholder="10000"
                        value={values.purchase_price}
                        onChange={handleNumberChange}
                      />
                    </div>
                  </div>
                  <div className="w-1/2 md:w-1/4 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Additional (Rp){" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="additional_margin"
                        type="number"
                        min={0}
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="10000"
                        value={values.additional_margin}
                        onChange={handleNumberChange}
                      />
                    </div>
                  </div>
                  <div className="w-1/2 md:w-1/4 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Margin (%){" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="margin"
                        type="number"
                        min={0}
                        max={100}
                        maxLength={300}
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="%"
                        value={values.margin}
                        onChange={handleNumberChange}
                      />
                    </div>
                  </div>

                  <div className="w-1/2 md:w-1/4 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Price (total)</label>
                      <div className="border-0 select-none px-3 py-3 placeholder-slate-300 text-slate-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          maximumFractionDigits: 0,
                        }).format(values.price)}
                      </div>
                    </div>
                  </div>

                  <div className="w-1/2 md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Discount (RP)
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="discount"
                        type="number"
                        min={0}
                        className={`border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        placeholder="10000"
                        value={values.discount}
                        onChange={handleNumberChange}
                      />
                    </div>
                  </div>

                  <div className="w-1/2 md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Discounted Price</label>
                      <div className="border-0 select-none px-3 py-3 placeholder-slate-300 text-slate-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          maximumFractionDigits: 0,
                        }).format(values.discount > 0 ? values.price - values.discount : 0)}
                      </div>
                    </div>
                  </div>

                  <div className="w-1/3 md:w-4/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Weight (gram){" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="weight"
                        type="number"
                        min={0}
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="500"
                        value={values.weight}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="w-1/3 md:w-4/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        quantity{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="quantity"
                        type="number"
                        min={0}
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="500"
                        value={values.quantity}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="w-1/3 md:w-4/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Min order{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="min_quantity"
                        type="number"
                        min={0}
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="10000"
                        value={values.min_quantity}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Tags{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>

                      <ProductTags defaultTags={defaultTags} onChange={onTagsChange} />
                    </div>
                  </div>

                  <div className="w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Production Time</label>
                      <select
                        name="production_time"
                        className="border-0 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChange}
                        value={values.production_time ? values.production_time : ""}
                      >
                        <option value="">Not specified</option>
                        <option value="below_7">Below 7 days</option>
                      </select>
                    </div>
                  </div>

                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Images</label>
                    </div>

                    {images && images.length > 0 && (
                      <>
                        <div className={`grid grid-cols-5 gap-4 mb-4`}>
                          {images.map((image, index) => (
                            <div className={"relative mr-2 border"} key={`images-${index}`}>
                              <span
                                className="absolute -top-2 -right-2 cursor-pointer px-1"
                                onClick={() => onRemoveImageClick(image.id)}
                              >
                                <i className="text-red-500   fas fa-times-circle w-4 h-4"></i>
                              </span>

                              {index === imageFeatureIndex && (
                                <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer">
                                  <div className="bg-blue-500 px-2 py-1 rounded-full">
                                    <i className="text-white fas fa-check"></i>
                                  </div>
                                </span>
                              )}
                              <img
                                onClick={() => onSetImageFeature(image.id, index)}
                                src={image.image_url}
                                alt=""
                                className={"h-fit aspect-square cursor-pointer"}
                              />
                            </div>
                          ))}

                          {imageLoading ? (
                            <div className="flex justify-center items-center">
                              <Spinner size={4} color={`black`} />
                            </div>
                          ) : (
                            <div
                              className="flex items-center text-center bg-gray-200 h-24 w-24 py-4 p-10 cursor-pointer"
                              onClick={onImageClick}
                            >
                              <span className="text-xs">
                                <i className="fas fa-plus"></i>
                              </span>

                              <input
                                ref={imageRef}
                                type="file"
                                name="image"
                                multiple={true}
                                onChange={onImageAdd}
                                className={"invisible"}
                                accept="image/png, image/jpeg"
                                style={{ width: "1px" }}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        External URL{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="external_url"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="https://shopee.co.id/xxx"
                        value={values.external_url}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-3">
                    <div className="w-full px-4 mb-3">
                      <div className="flex relative items-center mb-1">
                        <input
                          name="custom_design"
                          type="checkbox"
                          className="border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2"
                          checked={values.custom_design || values.custom_design === "true" ? true : false}
                          onChange={onCheckboxClicked}
                        />
                        <label className="block uppercase text-slate-600 text-xs font-bold">Custom Design?</label>
                      </div>
                      <span className="text-xs text-gray-400">
                        Checking this option will allow client to upload custom design for branded campaign.
                      </span>
                    </div>

                    <div className="w-full px-4 mb-3">
                      <div className="flex relative items-center mb-1">
                        <input
                          name="merchandise"
                          type="checkbox"
                          className="border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2"
                          checked={values.merchandise || values.merchandise === "true" ? true : false}
                          onChange={onCheckboxClicked}
                        />
                        <label className="block uppercase text-slate-600 text-xs font-bold">Merchandise?</label>
                      </div>
                      <span className="text-xs text-gray-400">
                        Checking this option will allow this product to be chosen as Merchandide.
                      </span>
                    </div>

                    <div className="w-full px-4 mb-3">
                      <div className="flex relative items-center mb-1">
                        <input
                          name="personal"
                          type="checkbox"
                          className="border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2"
                          checked={values.personal || values.personal === "true" ? true : false}
                          onChange={onCheckboxClicked}
                        />
                        <label className="block uppercase text-slate-600 text-xs font-bold">Smart Gift?</label>
                      </div>
                      <span className="text-xs text-gray-400">
                        Checking this option will allow this product to be chosen as Smart gift.
                      </span>
                    </div>
                  </div>

                  <div className="w-full px-4">
                    <div className="flex relative mb-3 items-center">
                      <input
                        name="active"
                        type="checkbox"
                        className="border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2"
                        checked={values.active || values.active === "true" ? true : false}
                        onChange={onCheckboxClicked}
                      />
                      <label className="block uppercase text-slate-600 text-xs font-bold">Active</label>
                    </div>
                  </div>

                  <div className="w-full px-4 mt-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Video URL</label>
                      <input
                        name="video_url"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="https://www.youtube.com/embed/xxx"
                        value={values.video_url}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="w-full lg:w-12/12 px-4 mt-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Internal Notes</label>
                      <textarea
                        name="internal_notes"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Notes"
                        value={values.internal_notes}
                        onChange={handleChange}
                        rows={8}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="w-full md:w-2/5 px-4">
            <ProductVariations onVariationsChange={onVariationsChange} defaultVariations={defaultVariations} />
            <ProductBulkPrice onDiscountsChange={onDiscountsChange} defaultDiscounts={product.prices} />
          </div>
        </div>
      )}
    </>
  );
}
