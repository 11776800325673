import React, { useCallback, useEffect, useState } from "react";
import api from "lib/api";
// components
import { useError } from "contexts/error";
import Table from "components/Table/Table";
import { Link } from "react-router-dom";

export default function OrderListRecipients() {
  const statusMap = {
    waiting_payment: "Waiting Payment",
    address_requested: "Waiting Address",
    processing: "Processing",
    delivered: "Delivered",
    delivering: "Delivering",
  };
  const { handleError } = useError();
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);
  const [filterValues, setFilterValues] = useState(null);

  const fetchRecipients = useCallback(async () => {
    const { data, err } = await api.controllers.getRecipients(pagination.page, pagination.limit, filterValues);
    if (err) {
      handleError(err);
    } else {
      setData(data);
    }
  }, [filterValues, pagination, handleError]);

  let onFilter = async (_values) => {
    setFilterValues(_values);
  };

  useEffect(() => {
    pagination && fetchRecipients();
  }, [pagination, fetchRecipients]);

  return (
    <Table
      settings={{
        title: null,
        headers: [
          {
            label: "Client",
            render: (element) => {
              return element.client_name;
            },
          },
          {
            label: "Campaign",
            render: (element) => {
              if (element.order_name) {
                return (
                  <Link to={`/orders/${element.order_id}`} className={"underline"}>
                    {element.order_name}
                  </Link>
                );
              }
            },
          },
          {
            label: "Name",
            render: (element) => {
              if (element.recipient_name) {
                return (
                  <>
                    {element.recipient_name}
                    <br />
                    <span className="text-gray-400">{element.recipient_email}</span>
                    <br />
                    <span className="text-gray-400">{element.recipient_phone_number}</span>
                    <br />
                  </>
                );
              }
            },
          },
          {
            label: "Status",
            render: (element) => {
              if (element.status) {
                return (
                  <>
                    {statusMap[element.status]}
                    <br />
                    <span className="text-gray-400">{element.tracking_courier}</span>
                    <br />
                    <span className="text-gray-400">
                      {element.tracking_number?.length > 18 ? "" : element.tracking_number}
                    </span>
                    <br />
                  </>
                );
              }
            },
          },
          {
            label: "Address",
            render: (element) => {
              if (element.address) {
                return (
                  <>
                    {element.address}
                    {element.address && element.province && (
                      <>
                        <p>
                          {element.subdistrict}, {element.city}, {element.province}, {element.postal_code}
                        </p>
                      </>
                    )}
                  </>
                );
              }
            },
          },
        ],
        filters: [
          {
            label: "Client",
            field: "client_name",
          },
          {
            label: "Name",
            field: "recipient_name",
          },
          {
            label: "Email",
            inputType: "email",
            field: "recipient_email",
          },
          {
            label: "Status",
            field: "status",
            inputType: "select",
            options: [
              {
                label: "Waiting Address",
                value: "address_requested",
              },
              {
                label: "Waiting Payment",
                value: "waiting_payment",
              },
              {
                label: "Processing",
                value: "processing",
              },
              {
                label: "Delivering",
                value: "delivering",
              },
              {
                label: "Delivered",
                value: "delivered",
              },
            ],
          },
        ],
      }}
      data={data}
      setPagination={setPagination}
      onFilter={onFilter}
    />
  );
}
