import api from "lib/api";
import { useEffect, useState } from "react";
import Select from "react-select";

export default function ProductTags({ onChange, defaultTags }) {
  const [tags, setTags] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);

  useEffect(() => {
    const getTags = async () => {
      const { err, data } = await api.controllers.getTags(1, -1);
      if (!err) {
        let _tags = data.data;

        if (defaultTags) {
          var values = [];
          _tags.map(
            (tag) =>
              defaultTags.indexOf(tag.id) !== -1 &&
              values.push({
                value: tag.id,
                label: tag.name,
              })
          );

          setDefaultValues(values);
        }

        setTags(_tags);
      }
    };
    getTags();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onValuesChange = (values) => {
    onChange &&
      onChange(
        values.map((value) => {
          return {
            id: value.value,
          };
        })
      );
  };

  return (
    <>
      {tags.length > 0 && (
        <Select
          name="tags"
          defaultValue={defaultValues}
          isMulti
          inputClassName={"shadow-none"}
          options={tags.map((tag) => {
            return {
              value: tag.id,
              label: tag.name,
            };
          })}
          onChange={(values) => onValuesChange(values)}
        />
      )}
    </>
  );
}
