import React, { useCallback, useEffect, useState } from "react";

import api from "lib/api";
import { useParams } from "react-router";
import Spinner from "components/Spinner";
import OrderProducts from "./OrderProducts";
import OrderRecipients from "./OrderRecipients";
import { useError } from "contexts/error";
import OrderNotification from "./OrderNotification";
import OrderPickups from "./OrderPickups";
import OrderDetailAdd from "./OrderDetailAdd";
import Sample from "components/Sample/Sample";
// import OrderConditions from "./Detail/OrderConditions";
import OrderRecurringRecipients from "./Edit/OrderRecurringRecipients";
import { useLocation, useHistory } from "react-router";
import OrderSchedule from "./Detail/OrderSchedule";
import OrderPersonalTypeItems from "./Edit/OrderPersonalTypeItems";
import OrderPersonalTypeBudget from "./Edit/OrderPersonalTypeBudget";

export default function EditOrder() {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { handleError } = useError();
  const [step, setStep] = useState("recipients");
  const [addReciModal, setAddReciModal] = useState(false);
  let history = useHistory();
  let query = useLocation();
  const [showImport, setShowImport] = useState(false);
  const [showBatchUpdate, setShowBatchUpdate] = useState(false);
  const [showPickup, setShowPickup] = useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const modalToggle = () => {
    setAddReciModal((prev) => !prev);
  };

  const fetchOrder = useCallback(async () => {
    const { data, err } = await api.controllers.getOrder(id);
    if (!err) {
      const _order = data.data;
      setOrder(_order);
    }
  }, [id]);

  useEffect(() => {
    fetchOrder(true);
  }, [fetchOrder]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  const onPublish = async (e) => {
    e.preventDefault();
    if (!window.confirm("Are you sure you want to publish? It is no longer possible to edit a published order.")) {
      return;
    }
    setLoading(true);
    const { err } = await api.controllers.publishOrder(order?.id);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      window.location.reload();
    }
  };

  const statusMap = {
    draft: "Draft",
    waiting_payment: "Waiting Payment",
    processing: "Processing",
    completed: "Completed",
  };

  useEffect(() => {
    if (query.hash) {
      if (query.hash.startsWith("#recipients-")) {
        if (query.hash === "#recipients-") {
          history.push(`${window.location.pathname}`);
        } else {
          setStep("recipients");
        }
      } else {
        let str = query.hash.replace("#", "");
        setStep(str);
      }
    } else if (step !== "recipients") {
      history.push(`${window.location.pathname}#${step}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let onDownloadClicked = async () => {
    setDownloadLoading(true);
    const { err, data } = await api.controllers.downloadOrderRecipients(order?.id);
    setDownloadLoading(false);

    if (!err) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `recipients-${Math.floor(Math.random() * 100000)}.csv`);
      document.body.appendChild(link);
      link.click();
    }
  };

  const updateActiveStatus = async () => {
    const { data, err } = await api.controllers.patchOrder(order.id, {
      active: !order?.active,
    });

    if (!err) {
      const _order = data.data;
      setOrder(_order);
    } else if (err) {
      handleError(err);
    }
  };

  return (
    <div className="px-3 md:px-0">
      <div className="rounded-t bg-white mb-6 px-6 py-6">
        <div className="text-center flex justify-between items-center">
          {order && (
            <>
              <div>
                <p className="text-xl font-bold">
                  {order?.name}
                  {order?.sender_type === "customer" && " - " + order?.customer_phone_number}
                  {order.free && <span className="px-2 py-1 text-sm text-white bg-primary rounded-md ml-2">Free</span>}
                </p>
                <div className="">
                  <p className="text-left text-xs text-gray-400">{statusMap[order?.status]}</p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                {order?.status !== "draft" && order?.recurring && (
                  <>
                    <label htmlFor="toggle" className="flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        id="toggle"
                        className="sr-only peer"
                        checked={order.active ? true : false}
                        onChange={updateActiveStatus}
                      />
                      <div
                        className={`block relative  w-16 h-9 p-1 rounded-full before:absolute before:w-7 before:h-7 
                  before:p-1 before:rounded-full before:transition-all before:duration-500 before:left-1 ${
                    order.active ? "before:left-8 bg-orange-200 before:bg-orange-500" : "bg-gray-300 before:bg-white"
                  }`}
                      ></div>
                    </label>
                  </>
                )}

                {(order?.status === "draft" || order?.status === "waiting_payment") && (
                  <button
                    className="flex bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 
                    rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onPublish}
                  >
                    {loading && <Spinner size={4} />} Publish
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="relative flex flex-col min-w-0 break-words mb-6 shadow-lg rounded-lg bg-white border-0">
        <div className="w-full overflow-x-auto flex justify-between border-b border-gray-200 bg-white rounded-tl-lg rounded-tr-lg">
          <ul className="flex w-fit">
            <li className="mr-2">
              <div
                onClick={() => {
                  setStep("recipients");
                  history.push(`${window.location.pathname}`);
                }}
                className={`inline-block cursor-pointer ${
                  step === "recipients" ? "text-sky-500" : "text-gray-500 hover:text-gray-600 hover:bg-gray-50"
                } rounded-t-lg py-4 px-4 text-sm font-medium text-center`}
              >
                Recipients
              </div>
            </li>

            {order?.type === "personal" && (
              <li className="mr-2">
                <div
                  onClick={() => {
                    setStep("gift-options");
                    history.push(`${window.location.pathname}#gift-options`);
                  }}
                  className={`inline-block cursor-pointer ${
                    step === "gift-options" ? "text-sky-500" : "text-gray-500 hover:text-gray-600 hover:bg-gray-50"
                  } rounded-t-lg py-4 px-4 text-sm font-medium text-center`}
                >
                  Gift Options
                </div>
              </li>
            )}

            {order?.type !== "personal" && (
              <li className="mr-2">
                <div
                  onClick={() => {
                    setStep("products");
                    history.push(`${window.location.pathname}#products`);
                  }}
                  className={`inline-block cursor-pointer ${
                    step === "products" ? "text-sky-500" : "text-gray-500 hover:text-gray-600 hover:bg-gray-50"
                  } rounded-t-lg py-4 px-4 text-sm font-medium text-center`}
                >
                  Gifts {order && `(${order?.product_count})`}
                </div>
              </li>
            )}

            {order?.type === "branded" && (
              <li className="mr-2">
                <div
                  onClick={() => {
                    setStep("production");
                    history.push(`${window.location.pathname}#production`);
                  }}
                  className={`inline-block cursor-pointer ${
                    step === "production" ? "text-sky-500" : "text-gray-500 hover:text-gray-600 hover:bg-gray-50"
                  } rounded-t-lg py-4 px-4 text-sm font-medium text-center`}
                >
                  Production
                </div>
              </li>
            )}

            <li className="mr-2">
              <div
                onClick={() => {
                  setStep("notification");
                  history.push(`${window.location.pathname}#notification`);
                }}
                className={`inline-block cursor-pointer ${
                  step === "notification" ? "text-sky-500" : "text-gray-500 hover:text-gray-600 hover:bg-gray-50"
                } rounded-t-lg py-4 px-4 text-sm font-medium text-center`}
              >
                Notification
              </div>
            </li>

            <li className="mr-2">
              <div
                onClick={() => {
                  setStep("pickups");
                  history.push(`${window.location.pathname}#pickups`);
                }}
                className={`inline-block cursor-pointer ${
                  step === "pickups" ? "text-sky-500" : "text-gray-500 hover:text-gray-600 hover:bg-gray-50"
                } rounded-t-lg py-4 px-4 text-sm font-medium text-center`}
              >
                Pickups
              </div>
            </li>

            {order?.recurring && (
              <li className="mr-2">
                <div
                  onClick={() => {
                    setStep("condition");
                    history.push(`${window.location.pathname}#condition`);
                  }}
                  className={`inline-block cursor-pointer ${
                    step === "condition" ? "text-sky-500" : "text-gray-500 hover:text-gray-600 hover:bg-gray-50"
                  } rounded-t-lg py-4 px-4 text-sm font-medium text-center`}
                >
                  Condition
                </div>
              </li>
            )}

            {order?.recurring && (
              <li className="mr-2">
                <div
                  onClick={() => {
                    setStep("schedule");
                    history.push(`${window.location.pathname}#schedule`);
                  }}
                  className={`inline-block cursor-pointer ${
                    step === "schedule" ? "text-sky-500" : "text-gray-500 hover:text-gray-600 hover:bg-gray-50"
                  } rounded-t-lg py-4 px-4 text-sm font-medium text-center`}
                >
                  Schedule
                </div>
              </li>
            )}
          </ul>

          <div className="flex items-center justify-center pr-4">
            {step === "recipients" && (
              <div className="flex items-center justify-center gap-x-2 pr-2">
                {order?.status === "draft" && (
                  <button
                    className="flex bg-gray-500 active:bg-gray-600 text-white font-bold uppercase text-xs px-4 py-[10px] rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowImport(true)}
                  >
                    Add Recipients
                  </button>
                )}

                {order?.status === "processing" && (
                  <button
                    className="flex bg-gray-500 active:bg-gray-600 text-white font-bold uppercase text-xs px-4 py-[10px] rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowBatchUpdate(true)}
                  >
                    Update Status
                  </button>
                )}

                {order?.status !== "draft" && (
                  <button
                    className="flex bg-gray-500 active:bg-gray-600 text-white font-bold uppercase text-xs px-4 py-[10px] rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => onDownloadClicked()}
                  >
                    {downloadLoading && <Spinner />}
                    Download
                  </button>
                )}

                {selectedRecipients?.length > 0 && (
                  <button
                    className="flex bg-primary text-white font-bold uppercase text-xs px-4 py-[10px] rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowPickup(true)}
                  >
                    Pickup ({selectedRecipients?.length})
                  </button>
                )}
              </div>
            )}

            {!order?.recurring && (
              <button
                className="bg-gray-500 active:bg-gray-600 text-white font-bold uppercase px-3 py-1 
            rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 text-lg"
                type="button"
                onClick={() => modalToggle()}
              >
                +
              </button>
            )}
          </div>
        </div>

        <div className="p-4">
          {order && (
            <>
              {step === "recipients" && (
                <OrderRecipients
                  order={order}
                  onSuccessPickup={() => setStep("pickups")}
                  showPickup={showPickup}
                  setShowPickup={setShowPickup}
                  selectedRecipients={selectedRecipients}
                  setSelectedRecipients={setSelectedRecipients}
                  showBatchUpdate={showBatchUpdate}
                  setShowBatchUpdate={setShowBatchUpdate}
                  showImport={showImport}
                  setShowImport={setShowImport}
                />
              )}
              {step === "gift-options" && (
                <>
                  {order.personal_type === "items" && <OrderPersonalTypeItems order={order} loadOrder={fetchOrder} />}

                  {order.personal_type === "budget" && <OrderPersonalTypeBudget order={order} loadOrder={fetchOrder} />}
                </>
              )}
              {step === "products" && <OrderProducts order={order} />}
              {step === "production" && <Sample order={order} hideTitle={true} load={fetchOrder} type="production" />}

              {step === "notification" && <OrderNotification order={order} fetchOrder={fetchOrder} />}
              {step === "pickups" && <OrderPickups order={order} />}

              {step === "condition" && <OrderRecurringRecipients order={order} isCreate={false} />}

              {step === "schedule" && <OrderSchedule order={order} isCreate={false} />}
            </>
          )}
        </div>
      </div>

      {/* modal  */}
      {addReciModal && <OrderDetailAdd onClose={modalToggle} onSuccess={fetchOrder} id={id} />}
    </div>
  );
}
