import React, { useEffect, useRef, useState } from "react";

import api from "lib/api";
import { useParams } from "react-router";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import compress from "../../../lib/compress";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function EditBrands() {
  const [category, setCategory] = useState(null);
  const [values, setValues] = useState({ name: "", description: "", color: "", active: false, logo: null });
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { handleError, handleSuccess } = useError();
  const history = useHistory();
  const imageRef = useRef(null);

  useEffect(() => {
    const get = async () => {
      const { data, err } = await api.controllers.getBrand(id);
      if (!err) {
        const _category = data.data;

        setCategory(_category);
        setValues({
          name: _category.name,
          active: _category.active,
          logo: _category.logo_url,
          description: _category.description,
          color: _category.color,
        });
      }
    };
    get();
  }, [id]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const onCheckboxClicked = (e) => {
    setValues({ ...values, [e.target.name]: !values[e.target.name] });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let value = {
      name: values.name,
      active: values.active ? 1 : 0,
      logo: values.logo instanceof File ? await compress(values.logo) : values.logo,
      description: values.description,
      color: values.color,
    };

    const { err } = await api.controllers.updateBrand(id, value);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      handleSuccess("Update successful");
      window.location.reload();
    }
  };

  const onImageClick = () => {
    imageRef && imageRef.current.click();
  };

  const onFileChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files[0] });
  };

  const deleteBrand = async () => {
    if (window.confirm("Are you sure you want to delete this brand?")) {
      const { err } = await api.controllers.deleteBrand(id);
      if (!err) {
        handleSuccess("Brand successfully deleted");
        history.push("/brands");
      }
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-slate-700 text-xl font-bold">Edit {category && category.name}</h6>
            <div className="flex gap-2">
              <button
                className="flex bg-gray-500 text-white active:bg-gray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit"
                onClick={deleteBrand}
              >
                {loading && <Spinner size={4} />} Delete
              </button>
              <button
                className="flex bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit"
                onClick={(e) => onSubmit(e)}
              >
                {loading && <Spinner size={4} />} Save
              </button>
            </div>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={onSubmit}>
            <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">brand Information</h6>
            <div className="flex flex-wrap w-full lg:w-6/12">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Name{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="name"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Birthday"
                    value={values.name || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Description{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <textarea
                    name="description"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Deskripsi"
                    value={values.description || ""}
                    onChange={handleChange}
                    rows={8}
                  ></textarea>
                  {/* <input
                    name="description"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="abc"
                    value={values.description || ""}
                    onChange={handleChange}
                  /> */}
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Color{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input name="color" type="color" value={values.color || "#000000"} onChange={handleChange} />
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4  mb-3">
                <label className="flex uppercase text-slate-600 text-xs font-bold mb-2">
                  Logo{" "}
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <div
                  className={`text-center ${
                    values.logo ? "" : "bg-gray-200"
                  } w-full cursor-pointer rounded-lg flex items-center`}
                  onClick={onImageClick}
                >
                  {values.logo && (
                    <img
                      src={values.logo instanceof File ? URL.createObjectURL(values.logo) : values.logo}
                      alt="category-icon"
                      className="w-6/12 h-fit rounded-sm bg-white border border-gray-400"
                    />
                  )}
                  {!values.logo && (
                    <>
                      <span className="mx-auto text-gray-400 text-4xl px-4">+</span>
                    </>
                  )}

                  <input
                    ref={imageRef}
                    type="file"
                    name="logo"
                    onChange={onFileChange}
                    className={"invisible"}
                    accept="image/png, image/jpeg"
                    style={{ width: "1px" }}
                  />
                </div>
              </div>

              <div className="px-4">
                <div className="flex relative mb-3 items-center">
                  <input
                    name="active"
                    type="checkbox"
                    className="border-0 mr-3 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    checked={values.active || values.active === "true" ? true : false}
                    onChange={onCheckboxClicked}
                  />
                  <label className="block uppercase text-slate-600 text-xs font-bold">Active</label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
