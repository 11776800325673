import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "contexts/auth";

const UserDropdown = () => {
  const { logout } = useAuth();

  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const dropdownPopover = () => {
    setDropdownPopoverShow((prev) => !prev);
  };

  const submenusRef = useRef();

  useEffect(() => {
    const closeSubMenu = (e) => {
      if (!submenusRef?.current?.contains(e.target)) {
        setDropdownPopoverShow(false);
      }
    };

    document.body.addEventListener("click", closeSubMenu);

    return () => {
      document.body.removeEventListener("click", closeSubMenu);
    };
  }, [submenusRef]);

  return (
    <div className="relative">
      <div
        className="text-slate-500 block"
        ref={submenusRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-slate-200 inline-flex items-center justify-center rounded-full">
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={require("assets/img/profile.png").default}
            />
          </span>
        </div>
      </div>

      <div
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 absolute right-0"
        }
      >
        <button
          className={"text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"}
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}
        >
          Logout
        </button>
        {/*
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
          }
          onClick={(e) => e.preventDefault()}
        >
          Action
        </a>
        <div className="h-0 my-2 border border-solid border-slate-100" />
        */}
      </div>
    </div>
  );
};

export default UserDropdown;
