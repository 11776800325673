import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { useDebouncedEffect } from "lib/useDebouncedEffect";
import { useError } from "contexts/error";

const Map = ({ values, setValues, reverseGeocodeDone, setReverseGeocodeDone, addressForLongLat, readOnly }) => {
  const { handleError } = useError();
  const OnlyRead = readOnly ? readOnly : false;
  const [mapsLoad, setMapsLoad] = useState(false);
  const [geocodeDone, setGeocodeDone] = useState(false);
  const [hideRealMarker, setHideRealMarker] = useState(false);
  const [FakeMarkerLongLat, setFakeMarkerLongLat] = useState({
    lat: null,
    lng: null,
  });
  const [location, setLocation] = useState({
    lat: parseFloat(values.latitude),
    lng: parseFloat(values.longitude),
    geoAddress: null,
  });

  useEffect(() => {
    setLocation((location) => ({ ...location, lng: parseFloat(values.longitude), lat: parseFloat(values.latitude) }));
    setFakeMarkerLongLat((FakeMarkerLongLat) => ({
      ...FakeMarkerLongLat,
      lng: parseFloat(values.longitude),
      lat: parseFloat(values.latitude),
    }));
  }, [values]);

  useDebouncedEffect(
    () => {
      const geocode = async (address) => {
        try {
          const google = window.google;
          const geocoder = new google.maps.Geocoder();
          const { results } = await geocoder.geocode({ address: address });
          if (results && results[0]) {
            const lng = results[0].geometry.location.lng();
            const lat = results[0].geometry.location.lat();
            setValues((values) => ({ ...values, longitude: lng, latitude: lat }));
            setReverseGeocodeDone(true);
            setGeocodeDone(true);
          }
        } catch (error) {
          if (error.code === "ZERO_RESULTS") {
            handleError({ message: "Result not found, make sure to put correct address" });
          } else {
            throw new Error(error);
          }
        }
      };
      if (mapsLoad && !reverseGeocodeDone && !OnlyRead) {
        geocode(addressForLongLat);
      }
    },
    [mapsLoad, setValues, addressForLongLat],
    1500
  );

  useEffect(() => {
    if (OnlyRead) {
      setGeocodeDone(true);
    }
  }, [OnlyRead]);

  useEffect(() => {
    const reverseGeo = async () => {
      const google = window.google;
      const geocoder = new google.maps.Geocoder();

      const latlng = {
        lat: location.lat,
        lng: location.lng,
      };

      try {
        const { results } = await geocoder.geocode({ location: latlng });
        if (results) {
          setGeocodeDone(false);
          const addressResult = results[0].formatted_address;
          setLocation((location) => ({ ...location, geoAddress: addressResult }));
        }
      } catch (e) {
        // handle exception
      }
    };

    if (mapsLoad && geocodeDone) {
      if (reverseGeocodeDone) {
        reverseGeo();
      } else if (OnlyRead) {
        reverseGeo();
      }
    }
  }, [location, reverseGeocodeDone, geocodeDone, values.lat, values.lng, mapsLoad, OnlyRead]);

  const Marker = () => (
    <div>
      <img src={require("assets/img/marker.png").default} alt={"marker"} className="w-10 -ml-5 -mt-10" />
    </div>
  );
  const FakeMarker = () => (
    <div>
      <img src={require("assets/img/marker.png").default} alt={"marker"} className="w-10 -ml-5 -mt-12" />
      <div className="bg-gray-300 w-2 h-1 -ml-[4px] rounded-full mt-2"></div>
    </div>
  );

  return (
    <div className="mt-8">
      {location.lat && location.lng && (
        <div
          style={{ height: "60vh", width: "100%" }}
          className={`border border-gray-300 relative ${OnlyRead ? "rounded-md select-none" : ""}`}
        >
          <div
            className={`absolute px-4 py-2 bg-gray-100 border border-gray-300 rounded-lg top-3 left-3 right-3 z-10 select-none ${
              OnlyRead ? "text-sm" : ""
            }`}
          >
            {location.geoAddress}
          </div>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
            center={location}
            zoom={addressForLongLat || OnlyRead ? 16 : 5}
            onGoogleApiLoaded={({ map, maps }) => {
              map && setMapsLoad((prev) => !prev);
              map && setHideRealMarker(true);
              OnlyRead && map.setOptions({ draggable: false });
            }}
            onDrag={(map) => {
              setHideRealMarker(false);
              setFakeMarkerLongLat((FakeMarkerLongLat) => ({
                ...FakeMarkerLongLat,
                lng: map.center.lng(),
                lat: map.center.lat(),
              }));
            }}
            onDragEnd={(map) => {
              setValues((values) => ({ ...values, longitude: map.center.lng(), latitude: map.center.lat() }));
              setGeocodeDone(true);
              setHideRealMarker((prev) => !prev);
            }}
            yesIWantToUseGoogleMapApiInternals={true}
          >
            {hideRealMarker ? (
              <Marker lat={location.lat} lng={location.lng} text="My Marker" />
            ) : (
              <FakeMarker lat={FakeMarkerLongLat.lat} lng={FakeMarkerLongLat.lng} />
            )}
          </GoogleMapReact>
        </div>
      )}
    </div>
  );
};

export default Map;
