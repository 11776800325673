import api from "lib/api";
import { useError } from "contexts/error";
import { useState } from "react";
import Spinner from "components/Spinner";
import Select from "react-select";

export default function Packages({ proposal }) {
  const [groups, setGroups] = useState(
    proposal.package_groups.length > 0
      ? proposal.package_groups
      : [
          {
            name: "",
            position: 1,
            packages: [
              {
                position: 1,
                products: [],
              },
            ],
          },
        ]
  );
  const [loading, setLoading] = useState(false);

  const { handleError, handleSuccess } = useError();

  let products = [];
  proposal.product_groups.forEach((group) => {
    group.products.forEach((product) => {
      products.push({
        name: product.product.name,
        proposal_product_id: product.id,
      });
    });
  });

  let onValuesChange = (index, e) => {
    let newGroups = [...groups];
    newGroups[index] = {
      ...groups[index],
      [e.target.name]: e.target.value,
    };

    setGroups(newGroups);
  };

  let addGroup = () => {
    let len = groups.length;
    let newGroups = [...groups];
    newGroups.push({
      name: "",
      position: len + 1,
      packages: [
        {
          position: 1,
          products: [],
        },
      ],
    });
    setGroups(newGroups);
  };

  let removeGroup = (index) => {
    let newGroups = [];
    var position = 1;
    groups.forEach((group, idx) => {
      if (idx !== index) {
        newGroups.push({
          name: group.name,
          position: position++,
          packages: group.packages,
        });
      }
    });

    setGroups(newGroups);
  };

  let addPackage = (index) => {
    let newGroups = [...groups];

    let group = newGroups[index];
    let len = group.packages.length;
    group.packages.push({
      id: "",
      position: len + 1,
      products: [],
    });

    setGroups(newGroups);
  };

  let removePackage = (index, packageIndex) => {
    let newGroups = [...groups];

    let group = newGroups[index];
    let packages = group.packages;

    let newPackages = [];
    var position = 1;
    packages.forEach((_package, idx) => {
      if (idx !== packageIndex) {
        newPackages.push({
          id: _package.id,
          position: position++,
          products: _package.products,
        });
      }
    });
    group.packages = newPackages;

    setGroups(newGroups);
  };

  let onProductsChange = (index, packageIndex, options) => {
    let newGroups = [...groups];

    let group = newGroups[index];
    let _package = group.packages[packageIndex];
    _package.products = options.map((option, _index) => {
      return {
        proposal_product_id: option.value,
        position: _index + 1,
      };
    });

    setGroups(newGroups);
  };

  let onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const { err } = await api.controllers.updateProposalPackages(proposal.id, {
      groups: groups,
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      handleSuccess("Packages updated");
      window.location && window.location.reload();
    }
  };

  let productOptions = products.map((product) => {
    return {
      value: product.proposal_product_id,
      label: product.name,
    };
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        {groups &&
          groups.map((group, index) => (
            <div className="w-full mb-4" key={`group-${index}`}>
              <div className="bg-gray-100 p-2 flex justify-between">
                <div className="w-1/2 flex">
                  <div className="w-1/2 mr-4">
                    <input
                      name="name"
                      type="text"
                      className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                      placeholder="Package group"
                      value={group.name}
                      required
                      onChange={(e) => onValuesChange(index, e)}
                    />
                  </div>
                </div>
                <div className="w-1/5 text-right">
                  <button className="bg-gray-100 p-2 rounded-lg" type="button" onClick={() => removeGroup(index)}>
                    <i className="fas fa-trash" />
                  </button>
                </div>
              </div>
              <div className="bg-gray-50 p-2 mb-4">
                {group.packages &&
                  group.packages.map((_package, packageIndex) => (
                    <div
                      className="bg-white px-4 py-3 w-full flex mb-4 rounded-lg shadow-lg items-center relative"
                      key={`package-${packageIndex}`}
                    >
                      <h4 className="w-1/5">
                        {group.name} {_package.position}
                      </h4>
                      <div className="w-4/5">
                        <Select
                          defaultValue={_package.products.map((product) => {
                            return {
                              value: product.proposal_product_id,
                              label: product.name,
                            };
                          })}
                          isMulti
                          name="products"
                          options={productOptions}
                          onChange={(options) => onProductsChange(index, packageIndex, options)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                      <button
                        className="absolute"
                        type="button"
                        onClick={() => removePackage(index, packageIndex)}
                        style={{
                          top: "-8px",
                          right: "-8px",
                        }}
                      >
                        <i className="fas fa-times-circle" />
                      </button>
                    </div>
                  ))}
                <button className="bg-white p-2 rounded-lg mr-4" type="button" onClick={() => addPackage(index)}>
                  Add Package
                </button>
              </div>
            </div>
          ))}

        <div className="text-center flex justify-center">
          <button className="bg-gray-100 p-2 rounded-lg mr-4" type="button" onClick={addGroup}>
            Add Group
          </button>
          <button className="bg-primary p-2 rounded-lg text-white font-bold flex" type="submit">
            {loading && <Spinner />} Save
          </button>
        </div>
      </form>
    </>
  );
}
