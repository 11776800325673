import api from "lib/api";
import React, { useEffect, useRef, useState } from "react";
import useDidMountEffect from "../../lib/useDidMountEffect";
import { useError } from "../../contexts/error";
import compress from "../../lib/compress";
import Spinner from "components/Spinner";

export default function Sample({ order, hideTitle, type, load }) {
  let showTitle = hideTitle ? hideTitle : false;
  let production = type === "production" ? true : false;
  let samples = type === "sample" ? true : false;
  const [products, setProducts] = useState([]);
  const [patchIndexLoading, setPatchIndexLoading] = useState(null);
  const [mockUpProduct, setMockUpProduct] = useState([]);
  const [valuesSample, setValuesSample] = useState({
    sampling: order.sampling ? order.sampling : false,
    sample_estimated_date: order.sample_estimated_date,
  });
  const [valuesProduction, setValuesProduction] = useState({
    production_estimated_date: order.production_estimated_date,
  });
  const uploadFileRef = useRef([]);
  const { handleError } = useError();

  useEffect(() => {
    setMockUpProduct(
      products.map((data) => {
        return {
          sample_status: data.sample_status,
          sample_photo_url: data.sample_photo_url,
          sample_photo: null,
          production_status: data.production_status,
          production_photo_url: data.production_photo_url,
          production_photo: null,
          product_id: data.product_id,
          product_name: data.product_name,
          id: data.id,
        };
      })
    );
  }, [products]);

  const getOrderProducts = async (id) => {
    const { data, err } = await api.controllers.getOrderProducts(id);
    if (!err) {
      setProducts(data.data);
    }
  };

  useEffect(() => {
    order && getOrderProducts(order.id);
  }, [order]);

  let onSubmit = async (id, status, picture) => {
    const compressedImage = picture ? await compress(picture) : null;
    const productId = parseInt(id);

    if (samples && compressedImage !== null) {
      setPatchIndexLoading(productId);
      const { err } = await api.controllers.updateOrderProduct(order.id, productId, {
        sample_status: status,
        sample_photo: compressedImage,
        type: "sample",
      });
      setPatchIndexLoading(null);
      if (err) {
        handleError(err);
      } else {
        getOrderProducts(order.id);
      }
    }

    if (production && compressedImage !== null) {
      setPatchIndexLoading(productId);
      const { err } = await api.controllers.updateOrderProduct(order.id, productId, {
        production_status: status,
        production_photo: compressedImage,
        type: "production",
      });
      setPatchIndexLoading(null);
      if (err) {
        handleError(err);
      } else {
        getOrderProducts(order.id);
      }
    }
  };

  const onFileChange = (e, index) => {
    const { id, files } = e.target;
    const status = "completed";

    if (files.length) {
      onSubmit(id, status, files[0]);
    }
  };

  useDidMountEffect(async () => {
    let params = !valuesSample.sampling
      ? { sampling: true, sample_estimated_date: valuesSample.sample_estimated_date }
      : { sample_estimated_date: valuesSample.sample_estimated_date };
    if (valuesSample.sample_estimated_date) {
      const { err } = await api.controllers.patchOrder(order.id, params);
      if (err) {
        handleError(err);
      } else if (!err && !valuesSample.sampling) {
        load();
      }
    }
  }, [valuesSample.sample_estimated_date]);

  useDidMountEffect(async () => {
    if (valuesProduction.production_estimated_date) {
      const { err } = await api.controllers.patchOrder(order.id, valuesProduction);
      if (err) {
        handleError(err);
      } else {
        load();
      }
    }
  }, [valuesProduction.production_estimated_date]);

  const handleStatusChange = (e, index) => {
    uploadFileRef.current[index].click();
  };

  const handleSampleChange = (e) => {
    setValuesSample({ ...valuesSample, [e.target.name]: e.target.value });
  };

  const handleProductionChange = (e) => {
    setValuesProduction({ ...valuesProduction, [e.target.name]: e.target.value });
  };

  let onSampleStatusUpdate = async (id, checked) => {
    const productId = parseInt(id);
    let status = checked ? "processing" : null;
    if (samples) {
      setPatchIndexLoading(productId);
      const { err } = await api.controllers.updateOrderProduct(order.id, productId, {
        sample_status: status,
        sample: true,
        type: "sample",
      });
      setPatchIndexLoading(null);

      if (err) {
        handleError(err);
      } else {
        getOrderProducts(order.id);
      }
    }

    if (production) {
      setPatchIndexLoading(productId);
      const { err } = await api.controllers.updateOrderProduct(order.id, productId, {
        production_status: status,
        sample: false,
        type: "production",
      });
      setPatchIndexLoading(null);

      if (err) {
        handleError(err);
      } else {
        getOrderProducts(order.id);
      }
    }
  };

  const onGotSample = (e) => {
    const { checked, id } = e.target;
    if (checked) {
      onSampleStatusUpdate(id, true);
    } else {
      onSampleStatusUpdate(id, false);
    }
  };

  let renderProducts = (_products) => {
    return _products?.map((product, i) => {
      return (
        <div key={product.id} className={`mb-4 px-3 py-1 md:py-3 border mborder-gray-300 rounded-md `}>
          <div className="md:flex justify-between items-center">
            <div className={`w-full mb-1 py-3 mr-3 text-sm md:text-base md:px-3 flex items-center`}>
              <div className="md:w-4 md:mr-2">
                {production && product.production_status !== "completed" && (
                  <input
                    type="checkbox"
                    id={product.id}
                    checked={product.production_status === null ? false : true}
                    onChange={(e) => onGotSample(e, i)}
                    className="mr-2"
                  />
                )}
                {samples && product.sample_status !== "completed" && (
                  <input
                    type="checkbox"
                    id={product.id}
                    checked={product.sample_status === null ? false : true}
                    onChange={(e) => onGotSample(e, i)}
                    className="mr-2"
                  />
                )}
              </div>
              <h4 className=""> {product.product_name}</h4>
            </div>

            {patchIndexLoading !== product.id && (
              <div className="hidden md:block">
                {samples && product.sample_status === "completed" && product.sample_photo_url !== null && (
                  <div className="flex mr-16">
                    <button
                      onClick={() => uploadFileRef.current[i].click()}
                      className={`py-3 mr-3 px-3 flex items-center`}
                    >
                      <i className={`fas fa-edit block `}></i>
                    </button>
                    <img
                      src={product.sample_photo_url}
                      alt={product.product_name}
                      className="w-16 h-16 rounded-lg mr-5 object-contain"
                    />
                  </div>
                )}

                {production && product.production_status === "completed" && product.production_photo_url !== null && (
                  <div className="flex mr-16">
                    <button
                      onClick={() => uploadFileRef.current[i].click()}
                      className={`py-3 mr-3 px-3 flex items-center`}
                    >
                      <i className={`fas fa-edit block `}></i>
                    </button>
                    <img
                      src={product.production_photo_url}
                      alt={product.product_name}
                      className="w-16 h-16 rounded-lg mr-5 object-contain"
                    />
                  </div>
                )}
              </div>
            )}

            <input
              ref={(ref) => (uploadFileRef.current[i] = ref)}
              id={product.id}
              type="file"
              className="hidden"
              onChange={(e) => onFileChange(e, i)}
              accept="image/png, image/jpeg"
            />

            {patchIndexLoading === product.id && (
              <div className="w-fit mx-auto hidden md:block">
                <Spinner color="black" />
              </div>
            )}
            {production && product.production_status !== "completed" && patchIndexLoading === product.id && (
              <div className="w-fit mx-auto md:hidden">
                <Spinner color="black" />
              </div>
            )}
            {samples && product.sample_status !== "completed" && patchIndexLoading === product.id && (
              <div className="w-fit mx-auto md:hidden">
                <Spinner color="black" />
              </div>
            )}

            <div className={`flex items-center ${!product.sample_status || !product.production_status ? "" : "mb-2"}`}>
              {production ? (
                <>
                  {product.production_status !== null && (
                    <select
                      className="border-0 w-full md:w-fit bg-tertiary_gray_one placeholder-slate-300 focus:ring-0 rounded-md cursor-pointer ease-linear transition-all duration-150"
                      onChange={(e) => handleStatusChange(e, i)}
                      disabled={product.production_status === "completed"}
                      value={product.production_status === "completed" ? "completed" : "processing"}
                    >
                      {product.production_status === "completed" && (
                        <option value={products.production_status}>Completed</option>
                      )}
                      <option value="processing">Processing</option>
                      <option value="completed">Completed</option>
                    </select>
                  )}
                </>
              ) : (
                <>
                  {product.sample_status !== null && (
                    <select
                      className="border-0 w-full md:w-fit bg-tertiary_gray_one placeholder-slate-300 focus:ring-0 rounded-md cursor-pointer ease-linear transition-all duration-150"
                      onChange={(e) => handleStatusChange(e, i)}
                      disabled={product.sample_status === "completed"}
                      value={product.sample_status === "completed" ? "completed" : "processing"}
                    >
                      {product.sample_status === "completed" && (
                        <option value={products.sample_status}>Completed</option>
                      )}
                      <option value="processing">Processing</option>
                      <option value="completed">Completed</option>
                    </select>
                  )}
                </>
              )}
            </div>
          </div>

          {samples && product.sample_status === "completed" && product.sample_photo_url !== null && (
            <div className="bg-tertiary_gray_one p-2 rounded-md my-2 md:hidden">
              {patchIndexLoading === product.id ? (
                <div className="w-fit mx-auto md:hidden">
                  <Spinner color="black" />
                </div>
              ) : (
                <div onClick={() => uploadFileRef.current[i].click()} className="relative cursor-pointer">
                  <button className={`absolute left-0 top-0`}>
                    <i className={`fas fa-edit block `}></i>
                  </button>
                  <div className="flex justify-center">
                    <img
                      src={product.sample_photo_url}
                      alt={product.product_name}
                      className="w-20 h-20 rounded-lg mr-5 object-contain"
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {production && product.production_status === "completed" && product.production_photo_url !== null && (
            <div className="bg-tertiary_gray_one p-2 rounded-md my-2 md:hidden">
              {patchIndexLoading === product.id ? (
                <div className="w-fit mx-auto md:hidden">
                  <Spinner color="black" />
                </div>
              ) : (
                <div onClick={() => uploadFileRef.current[i].click()} className="relative cursor-pointer">
                  <button className={`absolute left-0 top-0`}>
                    <i className={`fas fa-edit block `}></i>
                  </button>
                  <div className="flex justify-center">
                    <img
                      src={product.production_photo_url}
                      alt={product.product_name}
                      className="w-20 h-20 rounded-lg mr-5 object-contain"
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 md:px-4">
          {!showTitle && (
            <div className="pb-10">
              {" "}
              <h3 className="text-2xl font-bold text-center">Add Sample</h3>
            </div>
          )}
          <div className="md:flex items-center gap-5 px-3 md:pr-0 md:pl-5 py-2 mb-3 bg-tertiary_gray_one rounded-sm">
            <p className="mb-2 md:mb-0">Set estimated {production ? "production" : "sample"} date :</p>
            <input
              name={production ? "production_estimated_date" : "sample_estimated_date"}
              type="date"
              className="border-0 w-full md:max-w-210-px bg-white  placeholder-slate-300 focus:ring-0 rounded-sm cursor-pointer"
              placeholder="Date"
              value={
                production ? valuesProduction.production_estimated_date || "" : valuesSample.sample_estimated_date || ""
              }
              onChange={production ? handleProductionChange : handleSampleChange}
            />
          </div>

          {samples && order.sampling && (
            <div className="bg-transparent border-collapse h-350-px overflow-auto">{renderProducts(mockUpProduct)}</div>
          )}
          {production && valuesProduction.production_estimated_date && (
            <div className="bg-transparent border-collapse">{renderProducts(mockUpProduct)}</div>
          )}
        </div>
      </div>
    </>
  );
}
