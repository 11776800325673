import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useError } from "contexts/error";
import api from "lib/api";
import FormDelivery from "./OrderNew/FormDelivery";
import FormType from "./OrderNew/FormType";
import FormName from "./OrderNew/FormName";
import Modal from "components/Modal/Modal";

export default function OrderNew({ onClose }) {
  const [values, setValues] = useState({
    client_id: "",
    client_user_id: null,
    name: "",
    type: "",
    recipient_count: 0,
    deliver_to: "",
    delivery_date: "",
    recurring_until: "",
    claimable_until: "",
    recurring: false,
    activate: false,
    can_expire: false,
    selected_countries: false,
    request_address: true,
    request_location: false,
    multiple_items: false,
    skip_intro: false,
    countries: [{ value: "indonesia", label: "Indonesia" }],
  });
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [formBody, setFormBody] = useState("name");
  let history = useHistory();
  const { handleError } = useError();

  let getClients = async () => {
    const { data, err } = await api.controllers.getClients(1, -1);
    if (!err) {
      setClients(data.data);
    }
  };
  let getUsers = async (id) => {
    const { data, err } = await api.controllers.getClientUsers(id);
    if (!err) {
      setUsers(data.data);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    if (values.client_id) {
      getUsers(values.client_id);
    }
  }, [values]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleClientOption = (value) => {
    setValues({ ...values, client_id: value.value });
  };
  const handleUsersOption = (value) => {
    setValues({ ...values, client_user_id: value.value });
  };
  const onCheckboxClicked = (e) => {
    setValues({ ...values, [e.target.name]: !values[e.target.name] });
  };

  useEffect(() => {
    if (values.activate) {
      setValues((values) => ({
        ...values,
        recurring: false,
        request_address: true,
        multiple_items: false,
        // can_expire: false,
      }));
    }
  }, [values.activate]);

  useEffect(() => {
    if (values.recurring) {
      setValues((values) => ({
        ...values,
        activate: false,
        recipient_count: 0,
        request_address: true,
        can_expire: false,
        multiple_items: false,
      }));
    }
  }, [values.recurring]);

  useEffect(() => {
    if (!values.recurring && !values.activate) {
      setValues((values) => ({
        ...values,
        request_address: true,
      }));
    }
  }, [values.recurring, values.activate]);

  useEffect(() => {
    if (values.deliver_to === "company") {
      setValues((prevState) => {
        return {
          ...prevState,
          recipient_count: 0,
          activate: false,
          request_address: false,
          recurring: false,
          skip_intro: false,
          selected_countries: false,

          multiple_items: false,
        };
      });
    }
    if (values.deliver_to === "recipients") {
      setValues((prevState) => {
        return {
          ...prevState,
          request_address: true,
          recipient_count: 0,
        };
      });
    }
  }, [values.deliver_to]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const value = {
      client_id: values.client_id,
      name: values.name,
      type: values.type,
      recipient_count: values.recipient_count,
      deliver_to: values.deliver_to,
      delivery_date: values.delivery_date,
      recurring: values.recurring,
      activate: values.activate,
      request_address: values.request_address,
      skip_intro: values.skip_intro,
      ...(values.selected_countries &&
        values.countries.length > 0 && { delivery_countries: values.countries.map((country) => country.value) }),
      ...(values.recurring_until.length > 0 && { recurring_until: values.recurring_until }),
      ...(values.can_expire && values.claimable_until.length > 0 && { claimable_until: values.claimable_until }),
      ...(values.client_user_id && { client_user_id: values.client_user_id }),
      ...(values.request_location && { request_location: values.request_location }),
      ...(values.multiple_items && { multiple_items: values.multiple_items }),
    };

    if (values.deliver_to === "") {
      e.preventDefault();
      handleError({ message: "delivery method can't be empty" });
    } else if (values.can_expire && values.claimable_until.length === 0) {
      e.preventDefault();

      handleError({ message: "The Expire Date is required if you set this campaign to can expire" });
    } else if (values.deliver_to !== "") {
      e.preventDefault();
      let DeliveryDate = new Date(values.delivery_date);
      let ClaimableDate = new Date(values.claimable_until);

      if (ClaimableDate < DeliveryDate) {
        handleError({ message: "The Expire Date must be at least after the Delivery Date" });
      } else {
        setLoading(true);
        const { data, err } = await api.controllers.createOrder(value);
        setLoading(false);

        if (err) {
          handleError(err);
        } else {
          history.push(`/orders/edit/${data.data.id}`);
        }
      }
    }
  };

  useEffect(() => {
    if (values.type === "personal") {
      setValues((prevState) => {
        return {
          ...prevState,
          deliver_to: "recipients",
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          deliver_to: "",
        };
      });
    }
  }, [values.type]);

  const body = (
    <form
      className={`bg-white p-5 flex flex-col ${formBody === "delivery" ? "max-h-[85vh] overflow-y-auto" : ""}`}
      onSubmit={onSubmit}
    >
      {formBody === "name" && (
        <FormName
          values={values}
          handleChange={handleChange}
          setFormBody={setFormBody}
          handleClientOption={handleClientOption}
          handleUsersOption={handleUsersOption}
          clients={clients}
          users={users}
        />
      )}

      {formBody === "type" && <FormType values={values} handleChange={handleChange} setFormBody={setFormBody} />}

      {formBody === "delivery" && (
        <FormDelivery
          values={values}
          setValues={setValues}
          onCheckboxClicked={onCheckboxClicked}
          handleChange={handleChange}
          setFormBody={setFormBody}
          loading={loading}
        />
      )}
    </form>
  );

  return <Modal hideHeader={true} title={``} widthClass="min-w-[70%]" onClose={onClose} body={body} />;
}
