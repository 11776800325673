import api from "lib/api";
import { useCallback, useEffect, useState } from "react";
import { useError } from "contexts/error";
import Catalogue from "components/Catalogue/Catalogue";
import CatalogueProductForm from "components/Catalogue/CatalogueProductForm";

export default function OrderProducts({ order }) {
  const [products, setProducts] = useState([]);

  const { handleError } = useError();

  const fetchOrderProducts = useCallback(async () => {
    const { data, err } = await api.controllers.getOrderProducts(order.id);
    if (!err) {
      setProducts(data.data);
    }
  }, [order.id]);

  // const getOrderProducts = async () => {
  //   const { data, err } = await api.controllers.getOrderProducts(order.id);
  //   if (!err) {
  //     setProducts(data.data);
  //   }
  // };

  const onProductAdd = async (product, values) => {
    const { err } = await api.controllers.addOrderProduct(order.id, {
      products: [
        {
          id: product.id,
          quantity: values.quantity,
          variations: values.variations,
          note: values.note,
        },
      ],
    });

    if (err) {
      handleError(err);
    } else {
      fetchOrderProducts();

      return true;
    }

    return false;
  };

  const onProductDelete = async (product) => {
    const { err } = await api.controllers.removeOrderProduct(order.id, product.id);

    if (err) {
      handleError(err);
    } else {
      fetchOrderProducts();
    }
  };

  useEffect(() => {
    fetchOrderProducts();
  }, [fetchOrderProducts]);

  const renderProduct = (product) => {
    return (
      <CatalogueProductForm
        key={`${product.id}`}
        product={product}
        onSubmit={onProductAdd}
        checkRecipientVariation={true}
      />
    );
  };

  const productsTable = (
    <div className="w-full overflow-x-auto">
      <table className="items-center w-fit md:w-full bg-transparent border-collapse mb-4">
        <thead>
          <tr>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"
              }
            >
              Name
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"
              }
            >
              Qty
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"
              }
            >
              Image
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"
              }
            >
              Variation
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"
              }
            >
              Note
            </th>
            {order && order.status === "draft" && (
              <>
                <th
                  className={
                    "w-4 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"
                  }
                >
                  &nbsp;
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {order &&
            products.map((product) => (
              <tr key={`field-${product.id}`}>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4">
                  {product.product_name}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4">{product.quantity}</td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4">
                  <img src={product.product_image_url} alt={product.product_name} className="aspect-square w-12" />
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4">
                  {product.variations &&
                    product.variations.map((variation) => (
                      <div key={`${variation.name}-${variation.option}`}>
                        <span>
                          {variation.name}: {variation.option}
                        </span>
                      </div>
                    ))}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4">{product.note}</td>
                {order.status === "draft" && (
                  <>
                    <td className="w-4 border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2">
                      <button type="button" onClick={() => onProductDelete(product)}>
                        <i className="fas fa-trash"></i>
                      </button>
                    </td>
                  </>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <>
      <div className="w-full">
        {order && (
          <>
            {productsTable}

            {order.status === "draft" && (
              <>
                <hr className="mb-4" />
                <div className="bg-slate-100 p-4">
                  <h4 className="text-lg font-bold text-gray-400 mb-4">Choose Gifts</h4>
                  <Catalogue renderProduct={renderProduct} />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
