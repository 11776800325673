import { useAuth } from "contexts/auth";
import api from "lib/api";
import React, { useState } from "react";

export default function Login() {
  const { login } = useAuth();
  const [values, setValues] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { data, err } = await api.controllers.createToken(values.email, values.password);
    setLoading(false);
    if (!err) {
      const { err: meErr } = await login(data.data.token);
      if (!meErr) {
        window.location = "/";
      } else {
        setError(meErr.message);
      }
    } else {
      setError(err.message);
    }
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-10">
                <div className="text-slate-400 text-center mb-3 font-bold">
                  <small>Sign In</small>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Email</label>
                    <input
                      name="email"
                      type="email"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Password</label>
                    <input
                      name="password"
                      type="password"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                    />
                  </div>

                  {error && <p className="text-center text-red-700 text-sm">{error}</p>}

                  <div className="text-center mt-6">
                    <button
                      className="flex justify-center bg-slate-800 text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      {loading && (
                        <svg
                          className="animate-spin ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/*
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-slate-200">
                  <small>Create new account</small>
                </Link>
              </div>
            </div>
            */}
          </div>
        </div>
      </div>
    </>
  );
}
