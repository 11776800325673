import Modal from "components/Modal/Modal";
import { useState } from "react";
import compress from "lib/compress";
import api from "lib/api";
import { useHistory } from "react-router";
import { useError } from "contexts/error";
import Spinner from "components/Spinner";

export default function AddProposal({ onClose }) {
  const [values, setValues] = useState({ name: "", client_name: "", logo: "" });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { handleError, handleSuccess } = useError();

  let handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  let onFileChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files[0] });
  };

  let onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const compressedImage = values.logo ? await compress(values.logo) : null;
    const { err, data } = await api.controllers.createProposal({
      name: values.name,
      client_name: values.client_name,
      logo: compressedImage,
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      handleSuccess("Proposal created");
      history.push(`/proposals/${data.data.id}`);
    }
  };

  let body = (
    <form onSubmit={onSubmit} className="flex justify-center">
      <div className="w-full lg:w-6/12">
        <div className="relative w-full mb-3">
          <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
            Name{" "}
            <span className="text-red-500">
              <sup>*</sup>
            </span>
          </label>
          <input
            name="name"
            type="text"
            className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
            placeholder="Onboarding Kit Yippy"
            value={values.name}
            onChange={handleChange}
          />
        </div>

        <div className="relative w-full mb-3">
          <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
            Client Name{" "}
            <span className="text-red-500">
              <sup>*</sup>
            </span>
          </label>
          <input
            name="client_name"
            type="text"
            className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
            placeholder="Yippy"
            value={values.client_name}
            onChange={handleChange}
          />
        </div>

        <div className="relative w-full mb-3">
          <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Logo</label>
          <input
            name="logo"
            type="file"
            className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
            onChange={onFileChange}
            accept="image/png, image/jpeg"
          />
          <p className="text-xs text-gray-400 mt-2">PNG, JPG up to 2MB</p>
        </div>

        <div className="relative w-full mb-3">
          <button
            className="w-full flex justify-center bg-sky-500 text-white font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md"
            type="submit"
          >
            {loading && <Spinner size={4} />} Save
          </button>
        </div>
      </div>
    </form>
  );

  return (
    <>
      <Modal title={`Add Proposal`} onClose={() => onClose()} body={body} />
    </>
  );
}
