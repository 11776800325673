import React, { useEffect, useState } from "react";

import Table from "components/Table/Table.js";
import api from "lib/api";
import Spinner from "components/Spinner";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

export default function CategoryList() {
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);

  const [values, setValues] = useState({ file: null });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const get = async () => {
    const { data, err } = await api.controllers.getCategories({
      page: pagination.page,
      limit: pagination.limit,
    });
    if (!err) {
      setData(data);
    }
  };

  useEffect(() => {
    pagination && get();
  }, [pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFileChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { err } = await api.controllers.importCategories({
      file: values.file[0],
    });
    setLoading(false);
    if (!err) {
      setShowModal(false);
      get();
    }
  };

  const form = (
    <>
      <form>
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="grid-password">
              File
            </label>

            <input
              name="file"
              type="file"
              className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              onChange={onFileChange}
              accept=".xls, .xlsx"
            />
          </div>

          <button
            className="flex bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="submit"
            onClick={(e) => onSubmit(e)}
          >
            {loading && <Spinner size={4} />} Import
          </button>
        </div>
      </form>

      <div className="w-full lg:w-12/12 px-4">
        <div className="relative w-full mt-5">
          <ul className="text-gray-500 text-sm list-disc pl-4">
            <li>
              Please use the following template:
              <a
                className="underline ml-2 font-bold"
                href={"/assets/category_variation_template.xlsx"}
                target={"_blank"}
                rel="noreferrer"
              >
                download
              </a>
            </li>
            <li>All fields are required</li>
          </ul>
        </div>
      </div>
    </>
  );

  const ImportModal = (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-2/3 my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Import Categories</h3>
              <button
                className="p-1 ml-auto border-0 text-black float-right text-3xl leading-none font-semibold"
                onClick={() => setShowModal(false)}
              >
                <span className="text-black h-6 w-6 text-2xl block">×</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6">{form}</div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Table
            settings={{
              title: "Categories",
              secondaryButton: {
                label: "Import",
                onClick: () => {
                  setShowModal(true);
                },
              },
              button: {
                label: "Add",
                onClick: () => {
                  history.push("/categories/new");
                },
              },
              headers: [
                {
                  label: "Name",
                  render: (element) => {
                    return (
                      <Link to={`/categories/${element.id}`} className={"underline"}>
                        {element.name}
                      </Link>
                    );
                  },
                },
                {
                  label: "Parent Category",
                  field: "parent_name",
                },
                {
                  label: "Status",
                  render: (element) => {
                    return element.active ? "Active" : "Not Active";
                  },
                },
              ],
            }}
            data={data}
            setPagination={setPagination}
          />
        </div>
      </div>

      {showModal ? ImportModal : null}
    </>
  );
}
