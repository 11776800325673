import { useError } from "contexts/error";
import React, { useState, useEffect } from "react";
import Select from "react-select";

function FormName({ values, handleChange, setFormBody, clients, handleClientOption, handleUsersOption, users }) {
  const [clientId, setClientId] = useState(null);
  const [userId, setUserId] = useState(null);
  const { handleError } = useError();

  useEffect(() => {
    if (values.client_id !== "") {
      setClientId(
        clients.findIndex((data) => {
          return data.id === values.client_id;
        })
      );
    }
    if (values.client_user_id !== "") {
      setUserId(
        users.findIndex((data) => {
          return data.id === values.client_user_id;
        })
      );
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-10/12 mx-auto mb-3 relative flex flex-col items-center ">
      <img
        src={require("assets/img/new-order/new-order-name-icon.png").default}
        alt={"new-order-name-icon"}
        className="w-36 mx-auto mt-3 mb-5"
      />
      <label className="block font-bold text-center text-3xl">How would you like to name this campaign?</label>
      <input
        name="name"
        type="text"
        className="text-center px-3 py-3 my-8 placeholder-slate-300 rounded bg-white border-b-2 border-0 border-tertiary_light_blue
		 focus:ring-0 w-10/12 ease-linear transition-all duration-150"
        placeholder="Input Campaign Name"
        value={values.name}
        onChange={handleChange}
      />

      {/* client  */}
      <Select
        placeholder={values.client_id === "" ? "Select existing clients" : clients[clientId]?.name}
        options={clients.map((client) => {
          return {
            value: client.id,
            label: client.name,
          };
        })}
        name="client_id"
        onChange={(value) => handleClientOption(value)}
        className="mb-8 rounded bg-white border border-tertiary_light_blue w-10/12"
        classNamePrefix="select"
      />

      {users.length > 0 && (
        <>
          {/* users  */}
          <Select
            placeholder={values.client_user_id === "" ? "Select existing user" : clients[userId]?.name}
            options={users.map((user) => {
              return {
                value: user.id,
                label: user.name,
              };
            })}
            name="client_user_id"
            onChange={(value) => handleUsersOption(value)}
            className="mb-8 rounded bg-white border border-tertiary_light_blue w-10/12"
            classNamePrefix="select"
          />
        </>
      )}

      <div
        className="py-3 px-16 w-fit bg-primary text-white font-bold cursor-pointer mx-auto flex items-center rounded-lg"
        onClick={() => {
          values.name.length === 0
            ? handleError({ message: "name field can't be empty" })
            : values.client_id === ""
            ? handleError({ message: "select client" })
            : setFormBody("type");
        }}
      >
        <p>Next</p>
        <img src={require("assets/img/arrow-right.svg").default} alt={"arrow-right"} className="w-4 h-4 ml-2 " />
      </div>
    </div>
  );
}

export default FormName;
