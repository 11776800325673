import { useState } from "react";
import compress from "lib/compress";
import api from "lib/api";
import { useParams } from "react-router";
import { useError } from "contexts/error";
import Spinner from "components/Spinner";
import Select from "react-select";
import MDEditor from "@uiw/react-md-editor";

export default function Information({ proposal }) {
  const [values, setValues] = useState({
    name: proposal.name,
    client_name: proposal.client_name,
    logo: "",
    logo_url: proposal.logo_url,
    //     how_to:
    //       proposal.how_to ||
    //       `1. Ping us via WhatsApp or email with your choice of selections. If you can't find your desired items or still have anything to ask, please notify us!
    // 2. After the price quotation is approved, a down payment of 50% is required to process your order
    // 3. Final designs (if any) with correct sizing is provided by you
    // 4. Production takes 20 (twenty) working days, depends on quantity and order queue
    // 5. The settlement must be done 3 days before shipment`,
    //     tnc:
    //       proposal.tnc ||
    //       `1. Minimum order 20 pcs
    // 2. Shipping cost is excluded
    // 3. Please send us your final design (no editing)`,
    sections: ["products", "features"],
    decorations_included: proposal.decorations_included,
  });
  const [loading, setLoading] = useState(false);
  const { handleError, handleSuccess } = useError();
  const { id } = useParams();

  let handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  let onFileChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files[0] });
  };
  let onCheckboxClicked = (e) => {
    setValues({ ...values, [e.target.name]: !values[e.target.name] });
  };
  let onSelectChange = (name, options) => {
    let _values = options.map((option) => option.value);
    setValues({ ...values, [name]: _values });
  };
  let onSubmit = async (e) => {
    e.preventDefault();

    const compressedImage = values.logo ? await compress(values.logo) : null;
    setLoading(true);
    const { err } = await api.controllers.updateProposal(id, {
      name: values.name,
      client_name: values.client_name,
      logo: compressedImage,
      // how_to: values.how_to,
      // tnc: values.tnc,
      sections: values.sections,
      decorations_included: values.decorations_included,
    });
    setLoading(false);
    if (err) {
      handleError(err);
    } else {
      handleSuccess("Information updated");
      window.location && window.location.reload();
    }
  };

  let onMarkdownChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  let sectionOptions = [
    { value: "products", label: "Products" },
    { value: "features", label: "Features" },
  ];

  return (
    <>
      <form onSubmit={onSubmit} className="flex">
        <div className="w-full lg:w-1/2 bg-gray-100 rounded-lg p-4">
          <div className="w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
              Name{" "}
              <span className="text-red-500">
                <sup>*</sup>
              </span>
            </label>
            <input
              name="name"
              type="text"
              className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
              placeholder="Onboarding Kit Yippy"
              value={values.name}
              onChange={handleChange}
            />
          </div>

          <div className="w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
              Client Name{" "}
              <span className="text-red-500">
                <sup>*</sup>
              </span>
            </label>
            <input
              name="client_name"
              type="text"
              className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
              placeholder="Yippy"
              value={values.client_name}
              onChange={handleChange}
            />
          </div>

          <div className="w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Logo</label>
            <input
              name="logo"
              type="file"
              className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
              onChange={onFileChange}
              accept="image/png, image/jpeg"
            />
            <p className="text-xs text-gray-400 mt-2">PNG, JPG up to 2MB</p>

            {values.logo_url && <img src={values.logo_url} alt="logo" className={"h-24 aspect-square"} />}
          </div>
          {/* <div className="w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
              How To{" "}
              <span className="text-red-500">
                <sup>*</sup>
              </span>
            </label>
            <div>
              <MDEditor value={values.how_to} onChange={(value) => onMarkdownChange("how_to", value)} />
            </div>
          </div>
          <div className="w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
              Terms &amp; Conditions{" "}
              <span className="text-red-500">
                <sup>*</sup>
              </span>
            </label>
            <div>
              <MDEditor value={values.tnc} onChange={(value) => onMarkdownChange("tnc", value)} />
            </div>
          </div> */}

          <div className="w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
              Sections{" "}
              <span className="text-red-500">
                <sup>*</sup>
              </span>
            </label>
            <Select
              defaultValue={[sectionOptions[0], sectionOptions[1]]}
              isMulti
              name="sections"
              options={sectionOptions}
              onChange={(option) => onSelectChange("sections", option)}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div className="flex w-full mb-3">
            <input
              name="decorations_included"
              type="checkbox"
              className="border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2"
              checked={values.decorations_included || values.decorations_included === "true" ? true : false}
              onChange={onCheckboxClicked}
            />
            <label className="block uppercase text-slate-600 text-xs font-bold">Decorations included?</label>
          </div>

          <div className="w-full mb-3">
            <button
              className="flex justify-center bg-sky-500 text-white font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md"
              type="submit"
            >
              {loading && <Spinner size={4} />} Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
