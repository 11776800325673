import api from "lib/api";
import { useEffect, useState } from "react";
import Pagination from "components/Pagination";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";

export default function Catalogue({
  renderProduct,
  options,
  onPriceOptionUpdate,
  addedItem,
  onToggleModal,
  budget,
  setDownlaodQueriesValue,
}) {
  let history = useHistory();
  let query = useLocation();
  const queryObject = queryString.parse(query.search);

  options = options || {};
  let defaultOptions = {
    ...{
      categoryType: "gift",
      cols: 5,
      showAllCategories: false,
      showCategories: false,
      showFilters: false,
      contentBorder: false,
      showPrice: false,
      showPriceType: "input",
      price: "",
      priceOptions: [],
      personal: null,
      sort: "created_at,desc", //for order type branded
      quantity: null,
      showTitle: false,
      showKeyword: false,
      showSearch: false,
      showCart: false, //for order type branded
      showSort: false, //for order type branded
      showStatus: false,
      clientId: null,
      active: "all",
    },
    ...options,
  };

  const [search, setSearch] = useState({
    name: queryObject.item ? queryObject.item : "",
    category_id: queryObject.category ? queryObject.category : "",
    subcategory_id: queryObject.subcategory ? queryObject.subcategory : "",
    quantity: defaultOptions.quantity,
    personal: defaultOptions.personal,
    active: queryObject.active ? queryObject.active : defaultOptions.active,
    price: queryObject.price ? queryObject.price : defaultOptions.price,
    sort: queryObject.sort ? queryObject.sort : defaultOptions.sort,
    gift_type: queryObject.gift_type ? queryObject.gift_type : "all",
  });

  const [pagination, setPagination] = useState(null);
  const [data, setData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [showCollapse, setShowCollapse] = useState(false);
  const [showPriceOptionInput, setShowPriceOptionInput] = useState(false);
  const [showSubCategory, setShowSubCategory] = useState(false);
  const [inputName, setInputName] = useState(queryObject.item ? queryObject.item : "");
  const [inputPrice, setInputPrice] = useState("");
  const [inputMinPrice, setInputMinPrice] = useState(queryObject.price ? queryObject.price.split(",")[0].trim() : "");
  const [inputMaxPrice, setInputMaxPrice] = useState(queryObject.price ? queryObject.price.split(",")[1].trim() : "");
  const [addedItemId, setAddedItemId] = useState([]); // for order edit
  const [checkBudget, setCheckBudget] = useState(true);
  const [initialRenderDone, setInitialRenderDone] = useState(false);

  useEffect(() => {
    if (pagination) {
      let obj = {
        ...queryObject,
        ...(search.category_id !== "" && { category: search.category_id }),
        ...(search.subcategory_id.length > 0 && { subcategory: search.subcategory_id }),
        ...(search.name.length > 0 && { item: search.name }),
        ...(search.sort !== "created_at,desc" && { sort: search.sort }),
        ...(search.active !== "all" && { active: search.active }),
        ...(search.gift_type !== "all" && { gift_type: search.gift_type }),
        ...(search.price.length > 5 && { price: search.price }),
        ...(pagination && { page: pagination.page }),
      };

      pagination.page === 1 && delete obj.page;
      search.category_id === "" && delete obj.category;
      search.subcategory_id.length === 0 && delete obj.subcategory;
      search.name.length === 0 && delete obj.item;
      search.sort === "created_at,desc" && delete obj.sort;
      search.active === "all" && delete obj.active;
      search.gift_type === "all" && delete obj.gift_type;
      search.price.length < 5 && delete obj.price;

      if (obj) {
        const queryParams = new URLSearchParams(obj).toString();
        history.push(`${window.location.pathname}?${queryParams}`);
      } else {
        history.push(`${window.location.pathname}`);
      }
    }
  }, [search, pagination, history]); // eslint-disable-line react-hooks/exhaustive-deps

  let filter = async () => {
    if (loadingData) return;
    setLoadingData(true);
    var categoryId = search.subcategory_id;

    var tagId = null;
    if (!categoryId && search.category_id) {
      let category = categories.find((category) => category.id === search.category_id);
      if (category?.type === "category") {
        categoryId = category.id;
      } else {
        tagId = category?.id;
      }
    }

    let payload = {
      client_id: defaultOptions.clientId,
      page: pagination ? pagination.page : queryObject.page ? queryObject.page : 1,
      limit: pagination ? pagination.limit : 20,
      name: search.name,
      category_id: categoryId || queryObject.category,
      category_type: defaultOptions.categoryType,
      tag_id: tagId,
      price: search.price,
      sort: search.sort,
      quantity: search.quantity,
      active: search.active === "all" ? null : search.active === true || search.active === "true" ? 1 : false,
      exclude_ids: addedItemId, // for remove catalogue that already be added as suggestion
      ...(search.gift_type === "merchandise" ? { merchandise: 1 } : {}),
      personal:
        defaultOptions.categoryType === "all"
          ? search.gift_type === "personal"
            ? 1
            : null
          : search.personal === true
          ? 1
          : null,
    };

    const { data, err } = await api.controllers.getProducts(payload);

    setDownlaodQueriesValue && setDownlaodQueriesValue(payload);
    setLoadingData(false);

    if (!err) {
      setData(data);
    }
  };

  // for remove catalogue that already be added as suggestion
  useEffect(() => {
    addedItem && setAddedItemId(addedItem.map((item) => item.product_id));
  }, [addedItem]);

  useEffect(() => {
    const getCategories = async () => {
      const { data, err } = await api.controllers.getProductCategories({
        type: defaultOptions.categoryType,
        personal: search.personal === true ? 1 : null,
      });
      if (!err) {
        setCategories(data.data);
      }
    };

    getCategories();
  }, [defaultOptions.categoryType, search.personal]);

  useEffect(() => {
    if (pagination || addedItemId) {
      filter();
    }
  }, [addedItemId, pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (categories.length > 0 && search.category_id.length > 0) {
      const getSubCategories = async () => {
        const { err, data } = await api.controllers.getProductSubcategories(search.category_id, {
          personal: search.personal === true ? 1 : null,
        });
        if (!err) {
          setSubcategories(data.data);
          setShowSubCategory(true);
        }

        if (initialRenderDone && search.subcategory_id) {
          setSearch({ ...search, subcategory_id: "" });
        }

        if (initialRenderDone) {
          resetPagination();
        } else {
          filter();
        }
      };
      getSubCategories();
    } else {
      if (initialRenderDone && search.subcategory_id) {
        setSearch({ ...search, subcategory_id: "" });
      }

      if (initialRenderDone) {
        resetPagination();
      } else {
        filter();
      }
    }
  }, [categories, search.category_id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (defaultOptions.categoryType === "packaging" || defaultOptions.categoryType === "decoration") {
      setSearch({ ...search, name: "", subcategory_id: "", category_id: "", price: "", sort: "created_at,desc" });
      setInputName("");
    }
  }, [defaultOptions.categoryType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    resetPagination();
  }, [search.subcategory_id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (options.price) {
      setSearch((search) => ({ ...search, price: options.price }));
    }
  }, [options.price]);

  useEffect(() => {
    resetPagination();
  }, [search.price, search.active]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filter();
  }, [search.sort]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    resetPagination();
  }, [search.name, search.gift_type]); // eslint-disable-line react-hooks/exhaustive-deps

  let resetPagination = () => {
    setPagination({ page: 1, limit: 20 });
  };

  const onPageChange = (page, limitPerPage) => {
    setPagination({ page: page, limit: limitPerPage ? limitPerPage : 20 });
  };

  let onQueryChange = (e) => {
    setInputName(e.target.value);
  };
  let onQueryEnter = (e) => {
    if (e.key === "Enter") {
      setSearch({ ...search, name: inputName });
    }
  };
  let onUpdateName = () => {
    setSearch({ ...search, name: inputName });
  };

  let onCategoryChange = (e, id) => {
    e.preventDefault();
    setInitialRenderDone(true);
    setSearch({ ...search, category_id: id });
  };
  let onSubCategoryChange = (e, id) => {
    e.preventDefault();
    setSearch({ ...search, subcategory_id: id });
  };
  let handleSortChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  let onEnter = (e) => {
    if (e.key === "Enter") {
      setSearch({ ...search, price: `${inputMinPrice},${inputMaxPrice}` });
    }
  };

  useEffect(() => {
    defaultOptions.priceOptions.indexOf(budget) === -1 ? setCheckBudget(false) : setCheckBudget(true);
  }, [budget, defaultOptions.priceOptions]);

  return (
    <>
      <div className="relative w-full mb-4 flex">
        <div className={`${defaultOptions.showAllCategories ? "pr-5 w-3/12" : "absolute w-2/12"}`}>
          {/* search  */}
          {defaultOptions.showSearch && defaultOptions.categoryType === "gift" && (
            <div>
              <div className="relative flex justify-center items-center w-full mb-3">
                <input
                  name="name"
                  type="search"
                  className="border-0 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded-l text-sm shadow w-full"
                  placeholder="Search"
                  value={inputName}
                  onChange={onQueryChange}
                  onKeyUp={onQueryEnter}
                />
                <div
                  className="bg-primary text-white font-bold uppercase text px-3 py-2 rounded-r shadow cursor-pointer"
                  onClick={onUpdateName}
                >
                  <i className="fas fa-search" />
                </div>
              </div>
            </div>
          )}

          {defaultOptions.showAllCategories && (
            <>
              {/*  category  */}
              <div className="bg-white rounded-lg overflow-hidden mb-4">
                <div className="px-5 py-4 bg-primaryTwo flex justify-between items-center">
                  <div className="flex">
                    <img
                      src={require("assets/img/order-edit/category-icon.svg").default}
                      alt={"category-icon"}
                      className="w-5 mr-2 svg_white"
                    />
                    <h4 className="font-bold text-white">Categories</h4>
                  </div>
                </div>

                <div className="px-6 py-2">
                  <div className="text-md text-gray-600 hover:text-black font-medium mb-2">
                    <p
                      className={`cursor-pointer flex ${search.category_id === "" ? "text-primary" : ""}`}
                      onClick={(e) => onCategoryChange(e, "")}
                    >
                      <img
                        src={require("assets/img/catalogue/all.png").default}
                        alt={"all"}
                        // ${search.category_id === "" ? "svg_orange" : ""}
                        className={`w-6 mr-4 `}
                      />
                      All
                    </p>
                  </div>

                  {categories
                    .filter((category) => {
                      return category.type !== "occasion";
                    })
                    .map((category) => (
                      <div className="" key={`category-${category.id}`}>
                        <div className="text-md text-gray-600 hover:text-black font-medium mb-2 flex justify-between">
                          <p
                            className={`cursor-pointer flex ${
                              category.id === search.category_id ? "text-primary" : ""
                            }`}
                            onClick={(e) => {
                              onCategoryChange(e, category.id);
                              category.id === search.category_id
                                ? setShowSubCategory((prev) => !prev)
                                : setShowSubCategory(true);
                            }}
                          >
                            <img
                              src={category.icon_url}
                              alt={`category-icon-${category.id}`}
                              //  ${category.id === search.category_id ? "svg_orange" : ""}
                              className={`w-6 mr-4 h-fit`}
                            />
                            {category.name}
                          </p>
                          <img
                            src={require("assets/img/order-edit/accordion-arrow.svg").default}
                            alt={"accordion-arrow"}
                            className={`w-2  ${category.id === search.category_id ? "" : "rotate-180"}`}
                          />
                        </div>

                        <div className="pl-10 overflow-hidden">
                          <ul>
                            {search.category_id === category.id &&
                              showSubCategory &&
                              subcategories.map((subcategory, i) => (
                                <li
                                  key={`subcategory - ${i}`}
                                  className="mb-2 hover:font-bold"
                                  onClick={(e) => onSubCategoryChange(e, subcategory.id)}
                                >
                                  <p
                                    className={`hover:underline cursor-pointer ${
                                      subcategory.id === search.subcategory_id ? "text-primary" : ""
                                    } `}
                                  >
                                    {subcategory.name}
                                  </p>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              {/*  ocassion  */}
              <div className="bg-white rounded-lg overflow-hidden mb-4 ">
                <div
                  onClick={() => setShowCollapse((prev) => !prev)}
                  className="px-5 py-4 bg-primaryTwo flex justify-between items-center cursor-pointer"
                >
                  <div className="flex">
                    <img
                      src={require("assets/img/ocassion-icon.svg").default}
                      alt={"ocassion-icon"}
                      className="w-5 mr-2 svg_white"
                    />
                    <h4 className="font-bold text-white">Ocassions</h4>
                  </div>

                  <div className="">
                    <img
                      src={require("assets/img/order-edit/accordion-arrow.svg").default}
                      alt={"accordion-arrow"}
                      className={`w-3 svg_white  ${showCollapse ? "rotate-180" : ""}`}
                    />
                  </div>
                </div>

                {showCollapse && (
                  <div className="px-6 py-2">
                    <div className="text-md text-gray-600 hover:text-black font-medium mb-2">
                      <p
                        className={`cursor-pointer flex ${search.category_id === "" ? "text-primary" : ""}`}
                        onClick={(e) => onCategoryChange(e, "")}
                      >
                        <img
                          src={require("assets/img/catalogue/all.png").default}
                          alt={"all"}
                          className={`w-6 mr-4 ${search.category_id === "" ? "svg_orange" : ""}`}
                        />
                        All
                      </p>
                    </div>

                    {categories
                      .filter((category) => {
                        return category.type === "occasion";
                      })
                      .map((category) => (
                        <div className="" key={`category-${category.id}`}>
                          <div className="text-md text-gray-600 hover:text-black font-medium mb-2 flex justify-between">
                            <p
                              className={`cursor-pointer flex ${
                                category.id === search.category_id ? "text-primary" : ""
                              }`}
                              onClick={(e) => onCategoryChange(e, category.id)}
                            >
                              <img
                                src={category.icon_url}
                                alt={`category-icon-${category.id}`}
                                className={`w-6 mr-4 h-fit ${category.id === search.category_id ? "svg_orange" : ""}`}
                              />
                              {category.name}
                            </p>
                          </div>

                          <div className="pl-4 overflow-hidden">
                            <ul>
                              {search.category_id === category.id &&
                                subcategories.map((subcategory) => (
                                  <li
                                    className="mb-2 hover:font-bold"
                                    onClick={(e) => onSubCategoryChange(e, subcategory.id)}
                                  >
                                    <p className="hover:underline cursor-pointer text-primary">{subcategory.name}</p>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>

              {/*  price  */}
              <div className="bg-white rounded-lg overflow-hidden">
                <div className="px-5 py-4 bg-primaryTwo flex justify-between items-center">
                  <div className="flex">
                    <img
                      src={require("assets/img/order-edit/price-icon.svg").default}
                      alt={"price-icon"}
                      className="w-5 mr-2 svg_white"
                    />
                    <h4 className="font-bold text-white">Price</h4>
                  </div>
                </div>

                <div className="px-6 py-4">
                  <div className="border rounded-md border-primaryTwo flex items-center pl-3 mb-2">
                    <p className="">Rp</p>
                    <input
                      type="number"
                      className="border-0 px-3 py-2 placeholder-slate-400 text-slate-600 focus:ring-0 bg-white rounded-md  text-sm  w-full"
                      placeholder="Minimum price"
                      min={0}
                      value={inputMinPrice}
                      onChange={(e) => setInputMinPrice(e.target.value)}
                      onKeyUp={onEnter}
                    />
                  </div>
                  <div className="border rounded-md border-primaryTwo flex items-center pl-3">
                    <p className="">Rp</p>
                    <input
                      type="number"
                      className="border-0 px-3 py-2 placeholder-slate-400 text-slate-600 focus:ring-0 bg-white rounded-md  text-sm  w-full"
                      placeholder="Maximum price"
                      min={inputMinPrice}
                      value={inputMaxPrice}
                      onChange={(e) => setInputMaxPrice(e.target.value)}
                      onKeyUp={onEnter}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div
          className={` 
           ${defaultOptions.showAllCategories ? "relative w-10/12" : "relative w-full"}
         `}
        >
          <div
            className={`${
              defaultOptions.showSearch && defaultOptions.categoryType !== "gift" ? "flex justify-between" : ""
            }`}
          >
            {defaultOptions.showSearch && defaultOptions.categoryType !== "gift" && (
              <div className="lg:flex items-center gap-3 w-fit">
                <div className="relative flex justify-center items-center w-full mb-3 max-w-[18rem]">
                  <input
                    name="name"
                    type="search"
                    className="border-0 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded-l text-sm shadow w-full"
                    placeholder="Search"
                    value={inputName}
                    onChange={onQueryChange}
                    onKeyUp={onQueryEnter}
                  />
                  <div
                    className="bg-primary text-white font-bold uppercase text px-3 py-2 rounded-r shadow cursor-pointer"
                    onClick={onUpdateName}
                  >
                    <i className="fas fa-search" />
                  </div>
                </div>

                <div className="w-fit">
                  <div className="relative flex justify-center items-center w-full mb-3">
                    {/* <p className="text-slate-600 font-bold mr-2 min-w-max flex">Products Type</p> */}

                    <div className="flex items-center rounded-md overflow-hidden border border-gray-200">
                      <div
                        onClick={() => setSearch({ ...search, gift_type: "all" })}
                        className={`${
                          search.gift_type === "all" ? "bg-gray-200" : "bg-white"
                        } cursor-pointer text-center border border-gray-200 px-4 py-2 rounded-l-md`}
                      >
                        All
                      </div>
                      <div
                        onClick={() => setSearch({ ...search, gift_type: "merchandise" })}
                        className={`${
                          search.gift_type === "merchandise" ? "bg-gray-200" : "bg-white"
                        } cursor-pointer text-center border border-gray-200 px-4 py-2`}
                      >
                        Merchandise
                      </div>
                      <div
                        onClick={() => setSearch({ ...search, gift_type: "personal" })}
                        className={`${
                          search.gift_type === "personal" ? "bg-gray-200" : "bg-white"
                        } cursor-pointer text-center border border-gray-200 px-4 py-2 rounded-r-md whitespace-nowrap`}
                      >
                        Smart Gift
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {defaultOptions.showFilters && (
              <div
                className={`flex flex-wrap gap-x-4  ${!defaultOptions.showPrice ? "justify-end" : "justify-between"}`}
              >
                {/* // for order personal budget based */}
                {defaultOptions.showPrice && defaultOptions.showPriceType === "options" && (
                  <div className="lg:w-fit pr-4 text-sm">
                    <div className="relative flex justify-center items-center w-full mb-3">
                      <label className="block text-slate-600 font-bold mr-2 min-w-max">Budget :</label>

                      {!showPriceOptionInput && (
                        <div className="flex gap-x-2 items-center">
                          {defaultOptions.priceOptions.map((price, index) => {
                            return (
                              <div
                                key={`price-${index}`}
                                onClick={() => onPriceOptionUpdate(price)}
                                className={`${
                                  search.price === `${price * 0.5},${price}` || search.price === `0,${price}`
                                    ? "bg-primary text-white hover:text-white"
                                    : "bg-white hover:text-primary"
                                } min-w-max border rounded-md cursor-pointer hover:border hover:border-primary py-2 px-3`}
                              >
                                {new Intl.NumberFormat("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                  maximumFractionDigits: 0,
                                }).format(price)}
                              </div>
                            );
                          })}

                          <div
                            onClick={() => setShowPriceOptionInput((prev) => !prev)}
                            className={`${
                              budget > 50000 && !checkBudget
                                ? "bg-primary text-white hover:text-white"
                                : "bg-white hover:text-primary"
                            } min-w-max border rounded-md cursor-pointer hover:border hover:border-primary py-2 px-3`}
                          >
                            {/* Custom */}
                            {budget > 50000 && !checkBudget ? (
                              <div className="flex items-center gap-2">
                                {new Intl.NumberFormat("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                  maximumFractionDigits: 0,
                                }).format(budget)}
                                <i className="fas fa-edit text-xs font-normal"></i>
                              </div>
                            ) : (
                              "Custom"
                            )}
                          </div>
                        </div>
                      )}

                      {/* showCustomBudge, personal_type budget only */}
                      {showPriceOptionInput && (
                        <>
                          <input
                            name="price"
                            type="number"
                            className="border-0 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded-l text-sm shadow w-48"
                            placeholder="Input Budget"
                            min={50000}
                            value={inputPrice}
                            onChange={(e) => {
                              setInputPrice(e.target.value);
                            }}
                          />
                          <div
                            className={`bg-primary text-white font-bold px-3 py-2 ml-4 rounded shadow cursor-pointer`}
                            onClick={() => {
                              onPriceOptionUpdate(parseInt(inputPrice));
                              setShowPriceOptionInput((prev) => !prev);
                            }}
                          >
                            Update
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {defaultOptions.showStatus && (
                  <>
                    <div className="w-fit">
                      <div className="relative flex justify-center items-center w-full mb-3">
                        <label className="text-slate-600 font-bold mr-2 min-w-max lg:flex ">Status</label>
                        <select
                          name="active"
                          className="border w-fit border-gray-300 pl-2 pr-8 py-2 bg-white rounded text-sm focus:outline-none focus:ring-1 ease-linear transition-all duration-150"
                          onChange={(e) => {
                            setSearch({ ...search, active: e.target.value });
                          }}
                          value={search.active}
                        >
                          <option value={"all"}>All</option>
                          <option value={true}>Active</option>
                          <option value={false}>Inactive</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}

                {defaultOptions.showSort && (
                  <>
                    <div className="w-fit">
                      <div className="relative flex justify-center items-center w-full mb-3">
                        <label className="text-slate-600 font-bold mr-2 min-w-max lg:flex ">Sort by</label>
                        <select
                          name="sort"
                          className="border w-fit border-gray-300 pl-2 pr-8 py-2 bg-white rounded text-sm focus:outline-none focus:ring-1 ease-linear transition-all duration-150"
                          onChange={handleSortChange}
                          value={search.sort}
                        >
                          <option value="created_at,desc">Newest</option>
                          <option value="price,asc">Price Lowest</option>
                          <option value="price,desc">Price Highest</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}

                {defaultOptions.showCart && (
                  <div className="w-fit mb-3 cursor-pointer" onClick={onToggleModal}>
                    {addedItem.length <= 0 && (
                      <img
                        src={require("assets/img/order-edit/cart-empty.svg").default}
                        alt={"cart-empty"}
                        className="w-10"
                      />
                    )}
                    {addedItem.length > 0 && (
                      <div className="relative">
                        <div className="absolute top-0 right-0 px-1 text-xs font-semibold text-white bg-green-500 rounded-full border border-white shadow">
                          {addedItem.length}
                        </div>
                        <img
                          src={require("assets/img/order-edit/cart-filled.svg").default}
                          alt={"cart-filled"}
                          className="w-10"
                        />
                      </div>
                    )}
                  </div>
                )}

                {defaultOptions.showKeyword && (
                  <div className="w-2/12">
                    <div className="relative  flex justify-center items-center w-full mb-3">
                      <input
                        name="name"
                        type="search"
                        className="border-0 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded-l text-sm shadow w-full"
                        placeholder="Search"
                        value={inputName}
                        onChange={onQueryChange}
                        onKeyUp={onQueryEnter}
                      />
                      <div
                        className="bg-primary text-white font-bold px-3 py-2 rounded-r shadow cursor-pointer"
                        onClick={onUpdateName}
                      >
                        <i className="fas fa-search" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className={`w-full`}>
            {data && data.data && (
              <>
                {loadingData && <p className="text-sm text-center text-gray-400 mb-4">Loading...</p>}

                {/* filter */}
                {!loadingData && (
                  <>
                    {data.data.length > 0 && (
                      <div className={`grid grid-cols-${defaultOptions.cols} gap-x-3 gap-y-4 mb-4`}>
                        {defaultOptions.personal &&
                          renderProduct &&
                          addedItem.map((product) =>
                            renderProduct(product, {
                              reload: filter,
                              suggestionItem: true,
                            })
                          )}
                        {renderProduct &&
                          data.data.map((product) =>
                            renderProduct(product, {
                              reload: filter,
                              suggestionItem: false,
                            })
                          )}
                      </div>
                    )}

                    {data.data.length <= 0 && <p className={` text-center mb-4 p-8`}>No items found</p>}
                  </>
                )}
              </>
            )}

            <Pagination
              pageCount={data.total_page}
              itemCount={data.total_items}
              onPageChange={onPageChange}
              currentPage={queryObject.page}
              showAll={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}
