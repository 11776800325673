import React, { useEffect, useState } from "react";
import Spinner from "components/Spinner";
import api from "lib/api";
import { useError } from "contexts/error";
// import { saveAs } from "file-saver";
import useDidMountEffect from "lib/useDidMountEffect";
import Modal from "components/Modal/Modal";
import Sample from "components/Sample/Sample";

const OrderQuotation = ({ order, loadOrder, step, setStep }) => {
  const [values, setValues] = useState({
    recipient_count: order.recipient_count,
    request_address: order.request_address,
  });
  const [voucher, setVoucher] = useState({
    voucher_code: "",
  });

  // const [recipientLoading, setRecipientLoading] = useState(false);
  // const [paymentLoading, setPaymentLoading] = useState(false);
  // const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [availableProductsLoading, setAvailableProductsLoading] = useState(false);
  const [acceptProposal, setAcceptProposal] = useState(false);
  const [products, setProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [showInputVoucher, setShowInputVoucher] = useState(false);
  const [openSample, setOpenSample] = useState(false);

  const { handleError } = useError();
  const getProducts = async () => {
    const { data, err } = await api.controllers.getOrderProducts(order.id);
    if (!err) {
      setProducts(data.data);
    }
  };

  const getAvailableProducts = async () => {
    setAvailableProductsLoading(true);
    const { data, err } = await api.controllers.getProducts({
      page: 1,
      limit: -1,
      price: `${order.budget * 0.5},${order.budget}`,
    });
    setAvailableProductsLoading(false);
    if (!err) {
      setAvailableProducts(data.data);
    }
  };

  useEffect(() => {
    loadOrder();
    getProducts();
    if (order.type === "personal" && order.personal_type === "budget") {
      getAvailableProducts();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onDelete = async (id) => {
    const { err } = await api.controllers.deleteOrderProduct(order.id, id);
    if (!err) {
      getProducts();
    }
  };

  const giftProducts = products.filter((product) => product.product_category_type === "gift");
  const packagingProducts = products.filter((product) => product.product_category_type === "packaging");
  const decorationProducts = products.filter((product) => product.product_category_type === "decoration");

  // let handleChange = (e) => {
  //   setValues({ ...values, [e.target.name]: e.target.value });
  // };

  useDidMountEffect(() => {
    let onRecipientUpdate = async () => {
      // setRecipientLoading(true);
      const { err } = await api.controllers.patchOrder(order.id, values);
      // setRecipientLoading(false);

      if (err) {
        handleError(err);
      } else {
        loadOrder();
        getProducts();
      }
    };

    onRecipientUpdate();
  }, [values.recipient_count]);

  // let onPayment = async () => {
  //   setPaymentLoading(true);
  //   const { data, err } = await api.controllers.paymentOrder(order.id);
  //   setPaymentLoading(false);

  //   handleError(err);
  //   if (!err) {
  //     window.location.href = `${data.data.payment_link}`;
  //   }
  // };

  // let onInvoice = async () => {
  //   setInvoiceLoading(true);
  //   const { data, err } = await api.controllers.downloadInvoice(order.id);
  //   setInvoiceLoading(false);

  //   handleError(err);
  //   if (!err) {
  //     let blob = new Blob([data], { type: "application/pdf" });
  //     saveAs(blob, `invoice-${Math.floor(Math.random() * 100000)}.pdf`, {
  //       autoBom: true,
  //     });
  //   }
  // };

  let onSubmitVoucher = async () => {
    // setVoucherLoading(true);
    const { err } = await api.controllers.updateOrderVoucher(order.id, voucher);
    // setVoucherLoading(false);

    if (err) {
      handleError(err);
    } else {
      loadOrder();
    }
  };

  let onDeleteVoucher = async () => {
    // setVoucherLoading(true);
    const { err } = await api.controllers.deleteOrderVoucher(order.id);
    // setVoucherLoading(false);

    if (err) {
      handleError(err);
    } else {
      loadOrder();
    }
  };

  let handleVoucher = (e) => {
    setVoucher({ ...voucher, [e.target.name]: e.target.value });
  };

  let onNext = async (e) => {
    setAcceptProposal(true);
    const { err } = await api.controllers.patchOrder(order.id, {
      step: "payment",
    });
    setAcceptProposal(false);
    if (!err) {
      order.activate ? setStep("message") : order.deliver_to === "company" ? setStep("delivery") : setStep("recipient");
    }
  };

  let renderProducts = (_products) => {
    return _products.map((product) => {
      let imgUrl =
        process.env.REACT_APP_ENVIRONMENT === "production" ? product.product_image_url : "https://picsum.photos/200";

      return (
        <div key={product.id} className="px-3 flex items-center justify-between">
          <div className="py-3 min-w-max">
            <img src={imgUrl} alt={product.product_name} className="w-16 h-16 rounded-lg mr-3" />
          </div>

          <div
            className={`py-3 min-w-[250px] sm:w-7/12 mr-3 px-3 ${
              product.variations.length > 0 ? "" : "flex items-center"
            }`}
          >
            <h4 className="text-sm mb-1"> {product.product_name}</h4>
            {order.type === "branded" && (
              <div className="">
                {product.variations &&
                  product.variations.map((variation, index) => (
                    <span
                      key={`variation-${index}-${variation.name}`}
                      className="text-sm bg-secondaryTwo px-2 py-1 mr-2 mb-2 rounded-lg"
                    >
                      {variation.option}
                    </span>
                  ))}
              </div>
            )}
          </div>

          <p className="text-sm py-3 min-w-[100px] sm:w-3/12 mr-3 px-3">
            {new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
              maximumFractionDigits: 0,
            }).format(product.price)}
          </p>

          <div className="cursor-pointer sm:pr-8 py-3 font-bold min-w-fit" onClick={() => onDelete(product.id)}>
            <img src={require("assets/img/order-edit/cart-delete.svg").default} alt={"cart-delete"} className="w-6" />
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {openSample && (
        <Modal
          hideHeader={true}
          title={``}
          widthClass="min-w-0 w-11/12 md:min-w-[90%]"
          onClose={() => setOpenSample((prev) => !prev)}
          body={<Sample type="sample" order={order} products={giftProducts} load={loadOrder} />}
        />
      )}
      <div>
        {/* Quotation */}
        <div className="w-full py-5">
          <div className="px-6 pt-5 pb-11 rounded-lg flex flex-col bg-white border border-gray-300">
            {order.type === "branded" && (
              <div className="md:flex justify-end">
                <button
                  onClick={() => setOpenSample((prev) => !prev)}
                  className="text-white w-full md:w-fit bg-primary px-4 py-3 md:py-2 rounded-md mb-5"
                >
                  Add Samples <i className={` fas fa-plus-circle ml-2`} />
                </button>
              </div>
            )}

            <div className="w-full md:flex">
              {/* left part  */}
              <div className="overflow-x-auto w-full">
                <div className="w-fit md:w-full md:pr-5 mb-2">
                  <div className="items-center w-full bg-transparent border-collapse overflow-hidden">
                    <div className="bg-gray-100 px-3 flex justify-between">
                      <p className="text-left py-3 font-bold w-16">Item</p>
                      <p className={`text-left py-3 font-bold min-w-[250px] md:min-w-0 md:w-7/12 mr-16 md:mr-52`}></p>
                      <p className="text-left py-3 font-bold mr-10 w-3/12">Price</p>

                      <p className={`text-left py-3 pr-2 font-bold w-2/12 ${order.type !== "branded" ? "-mr-8" : ""}`}>
                        &nbsp;
                      </p>
                    </div>

                    {products.length > 0 && giftProducts.length > 0 && (
                      <div>
                        <>
                          {order.type === "branded" && <div className="pl-3 font-bold pt-4">Product</div>}
                          {order.type !== "branded" && <div className="pl-3 font-bold pt-4">Recommendation</div>}
                          {renderProducts(giftProducts)}
                        </>
                        {packagingProducts.length > 0 && (
                          <>
                            {order.type === "branded" && <div className="pl-3 font-bold pt-4">Packaging</div>}
                            {renderProducts(packagingProducts)}
                          </>
                        )}
                        {decorationProducts.length > 0 && (
                          <>
                            {order.type === "branded" && <div className="pl-3 font-bold pt-4">Decoration</div>}
                            {renderProducts(decorationProducts)}
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  {products.length <= 0 && (
                    <div className="w-full flex flex-col items-center mt-16 gap-2">
                      <img
                        src={require("assets/img/order-edit/proposal-no-item.png").default}
                        alt={"proposal-no-item"}
                        className="w-60 mx-auto"
                      />
                      <p className="text-2xl font-bold">
                        {order.type === "personal" && order.personal_type === "budget" ? (
                          availableProductsLoading ? (
                            <Spinner color={"black"} />
                          ) : (
                            `${availableProducts.length} gifts available`
                          )
                        ) : (
                          "Gift is empty"
                        )}
                      </p>
                      <p className="">The gift item will be selected by recipients</p>
                    </div>
                  )}
                </div>
              </div>

              {/* right part  */}
              <div className="pt-5 border-t md:pt-0 md:border-t-0"></div>
              <div className="md:w-6/12 h-fit px-5 py-3 bg-gray-100 rounded-lg ">
                {/* recipient / quantity  */}
                {!order.recurring && (
                  <div className="flex justify-between mb-2">
                    <p className="font-bold">
                      {order.deliver_to === "company" && "Quantity"}
                      {order.deliver_to !== "company" && "Recipients"}
                    </p>

                    <div className="flex items-center gap-x-5">
                      <button
                        disabled={values.recipient_count === 1}
                        onClick={() =>
                          setValues((values) => ({
                            ...values,
                            recipient_count: values.recipient_count - 1,
                          }))
                        }
                        className="w-fit cursor-pointer"
                      >
                        <img src={require("assets/img/order-edit/min.svg").default} alt={"min"} className="w-4" />
                      </button>
                      <div className="font-medium">{values.recipient_count}</div>
                      <button
                        onClick={() =>
                          setValues((values) => ({
                            ...values,
                            recipient_count: values.recipient_count + 1,
                          }))
                        }
                        className="w-fit cursor-pointer"
                      >
                        <img src={require("assets/img/order-edit/plus.svg").default} alt={"plus"} className="w-4" />
                      </button>
                    </div>
                  </div>
                )}

                <div className="flex justify-between mb-2">
                  <p className="font-bold">Campaign Type</p>
                  <p className="font-medium">{order.type === "branded" ? "Branded Gift" : "Personal Gift"}</p>
                </div>

                {order.recurring && (
                  <div className="flex justify-between mb-2">
                    <p className="font-bold">Recurring</p>
                    <p className="font-medium">Yes</p>
                  </div>
                )}

                {order.activate && (
                  <div className="flex justify-between mb-2">
                    <p className="font-bold">Activate</p>
                    <p className="font-medium">Yes</p>
                  </div>
                )}

                {/* budget  */}
                {order.type === "personal" && order.personal_type === "budget" && (
                  <div className="flex justify-between mb-2">
                    <p className="font-bold">Budget :</p>
                    <p className="text-left font-medium">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      }).format(order.budget)}
                    </p>
                  </div>
                )}

                {/* subtotal  */}
                {!order.recurring && order.type === "branded" && (
                  <div className="flex justify-between mb-2">
                    <p className="font-bold">Price / package</p>
                    <p className="text-left font-medium">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      }).format(order.products_price)}
                    </p>
                  </div>
                )}
                {!order.recurring && (
                  <div className="flex justify-between mb-2">
                    <p className="font-bold">Subtotal {order.type === "personal" && " (Estimated)"} :</p>
                    <p className="text-left font-medium">
                      {order.type === "branded" && (
                        <>
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            maximumFractionDigits: 0,
                          }).format(order.products_price * order.recipient_count)}
                        </>
                      )}
                      {order.type === "personal" && order.personal_type === "budget" && (
                        <>
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            maximumFractionDigits: 0,
                          }).format(order.budget * order.recipient_count)}
                        </>
                      )}
                      {order.type === "personal" && order.personal_type === "items" && (
                        <>
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            maximumFractionDigits: 0,
                          }).format(order.products_price * order.recipient_count)}
                        </>
                      )}
                    </p>
                  </div>
                )}
                {!order.recurring && order.type === "personal" && (
                  <div className="relative text-sm flex text-blue-500 bg-blue-100 rounded-md border border-blue-500 p-3 my-2">
                    <img src={require("assets/img/order-edit/info.svg").default} alt={"info"} className="w-6 mr-2" />{" "}
                    Final subtotal depends on the gifts that recipient choose.
                  </div>
                )}

                {/* voucher & discount  */}
                {order.voucher_code !== null && (
                  <>
                    <div className="flex justify-between mb-2">
                      <p className="font-bold py-2">Voucher :</p>
                      <div className="flex gap-2 font-medium bg-secondaryTwo py-2 px-2">
                        <p className="pl-2 border-l border-dashed border-primary text-primary font-bold">
                          {order.voucher_code}
                        </p>
                        <div className="cursor-pointer " onClick={onDeleteVoucher}>
                          <img
                            src={require("assets/img/order-edit/voucher-delete-orange.svg").default}
                            alt={"voucher-delete-orange"}
                            className="w-6"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between mb-2 font-medium">
                      <p className="font-bold">Discount :</p>
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      }).format(order.discount_amount)}
                    </div>
                  </>
                )}

                {/* {order.recurring && (
                  <div className="flex justify-between pt-4 border-t border-dashed border-primaryTwo mt-5"></div>
                )} */}

                {!order.recurring && (
                  <>
                    {/* total amount  */}
                    <div className="flex justify-between mb-3 pt-4 border-t border-dashed border-primaryTwo mt-5">
                      <p className="font-bold">Total Amount (IDR) :</p>
                      <p className="text-left font-medium">
                        {order.type === "personal" && (
                          <>
                            {new Intl.NumberFormat("id-ID", {
                              style: "currency",
                              currency: "IDR",
                              maximumFractionDigits: 0,
                            }).format(order.total_price)}
                          </>
                        )}
                        {order.type === "branded" && (
                          <>
                            {new Intl.NumberFormat("id-ID", {
                              style: "currency",
                              currency: "IDR",
                              maximumFractionDigits: 0,
                            }).format(order.total_price)}
                          </>
                        )}
                      </p>
                    </div>
                  </>
                )}

                {/* CTA  */}
                <>
                  {/* <button
                  disabled={products.length === 0 && giftProducts.length === 0}
                  className="flex  w-full justify-center mb-3 py-4 px-8 font-bold text-white bg-tertiary_light_blue rounded-md"
                >
                  <img
                    src={require("assets/img/order-edit/download.svg").default}
                    alt={"download"}
                    className="w-6 mr-2"
                  />
                  <p className="ml-2">Proposal</p>
  
  </button> */}
                  {!order.recurring && order.voucher_code === null && (
                    <>
                      {!showInputVoucher && (
                        <div
                          onClick={() => setShowInputVoucher((prev) => !prev)}
                          className="flex justify-center py-4 px-8 text-blue-500 cursor-pointer"
                        >
                          <p className="underline hover:font-medium">Have a promo code?</p>
                        </div>
                      )}

                      {showInputVoucher && (
                        <div className="relative flex justify-center items-center">
                          <img
                            src={require("assets/img/order-edit/voucher.svg").default}
                            alt={"voucher"}
                            className="w-6 mr-2 absolute left-2"
                          />

                          <input
                            name="voucher_code"
                            value={voucher.voucher_code}
                            onChange={handleVoucher}
                            type="search"
                            className="border-0 py-3 pl-12 placeholder-slate-300 text-slate-600 bg-white rounded-l text-sm shadow w-full"
                            placeholder="Voucher Code"
                          />
                          <div
                            onClick={onSubmitVoucher}
                            className="bg-tertiary_light_blue text-white font-bold uppercase text p-3   rounded-r shadow cursor-pointer"
                          >
                            Apply
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>

        {/* progress button  */}
        <div className="md:flex justify-between">
          <div
            className="flex justify-center mb-2 py-4 px-8 font-bold text-primary border md:w-fit bg-white border-primary rounded-md cursor-pointer"
            onClick={() => setStep("item")}
          >
            <img
              src={require("assets/img/arrow-right.svg").default}
              alt={"arrow-left"}
              className="w-6 mr-2 rotate-180 svg_orange"
            />
            <p className="mr-2">Change Item</p>
          </div>

          <div
            className="flex justify-center mb-2 py-4 px-8 font-bold text-white border md:w-fit bg-primary border-primary rounded-md cursor-pointer"
            onClick={onNext}
          >
            <p className="mr-2 flex">{acceptProposal && <Spinner />}Accept Proposal</p>
            <img src={require("assets/img/arrow-right.svg").default} alt={"arrow-left"} className="w-6 ml-2" />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderQuotation;
