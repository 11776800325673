import React, { useState } from "react";

// components
// import TableDropdown from "components/Dropdowns/TableDropdown.js";
import Pagination from "components/Pagination";

export default function Table({ settings, data, setPagination, onFilter }) {
  const headers = settings.headers;
  const filters = settings.filters || [];
  const elements = data?.data;
  const [showFilter, setShowFilter] = useState(false);

  const [filterValues, setFilterValues] = useState(
    Object.fromEntries(filters.map((filter) => [filter.field, filter.default || ""]))
  );
  const handleFilterChange = (e) => {
    setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
  };
  const onFilterSubmit = (e) => {
    e.preventDefault();

    onFilter && onFilter(filterValues);
  };

  const onPageChange = (page, limitPerPage) => {
    setPagination({ page: page, limit: limitPerPage ? limitPerPage : 20 });
  };

  return (
    <>
      <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"}>
        <div className="rounded-t mb-0 px-3 py-3 border-0">
          <div className="">
            <div className="relative w-full  max-w-full flex-grow flex-1 mb-2">
              {settings.title && <h3 className={"font-semibold text-lg text-blueGray-700"}>{settings.title}</h3>}
            </div>

            <div className="flex items-center md:justify-end gap-4">
              {filters.length > 0 && (
                <button
                  onClick={() => setShowFilter((prev) => !prev)}
                  className="w-fit bg-tertiary_gray_one p-3 flex justify-between items-center rounded-md md:hidden"
                >
                  <i className="fas fa-filter " />
                </button>
              )}
              {settings.secondaryButton && (
                <>
                  <button
                    className="bg-gray-500 text-white font-bold text-sm p-2 md:px-4 w-full md:w-fit rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={settings.secondaryButton.onClick}
                  >
                    {settings.secondaryButton.label}
                  </button>
                </>
              )}
              {settings.button && (
                <>
                  <button
                    className="bg-sky-500 text-white active:bg-sky-600 font-bold text-sm p-2 md:px-4 w-full md:w-fit rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={settings.button.onClick}
                  >
                    {settings.button.label}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        {filters.length > 0 && (
          <>
            <form
              className="block w-full overflow-x-auto py-4 border-t border-gray-100 pl-2 pr-4"
              onSubmit={onFilterSubmit}
            >
              <div className="">
                <div className={`md:flex items-end ${showFilter ? "block" : "hidden"}`}>
                  <div className={`grid md:flex grid-cols-2 mt-4 md:mt-0`}>
                    {filters
                      .filter((filter) => filter.inputType !== "select" && filter.inputType !== "email")
                      .map((filter) => {
                        return (
                          <div className="lg:min-w-[300px] w-full px-2 mb-3" key={`filter-${filter.field}`}>
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                              {filter.label}
                            </label>

                            <>
                              <input
                                name={filter.field}
                                type={filter.inputType || "text"}
                                className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                                value={filterValues[filter.field]}
                                onChange={handleFilterChange}
                              />
                            </>
                          </div>
                        );
                      })}
                  </div>
                  {filters
                    .filter((filter) => filter.inputType !== "select" && filter.inputType === "email")
                    .map((filter) => {
                      return (
                        <div className="w-full px-2 mb-3" key={`filter-${filter.field}`}>
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            {filter.label}
                          </label>

                          <>
                            <input
                              name={filter.field}
                              type={filter.inputType || "text"}
                              placeholder="xxx@gmail.com"
                              className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full placeholder:text-gray-300"
                              value={filterValues[filter.field]}
                              onChange={handleFilterChange}
                            />
                          </>
                        </div>
                      );
                    })}

                  {filters
                    .filter((filter) => filter.inputType === "select")
                    .map((filter) => {
                      return (
                        <div
                          className={`px-2 mb-3 ${settings.title === "Tags" ? "w-fit" : "w-full"}`}
                          key={`filter-${filter.field}`}
                        >
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            {filter.label}
                          </label>
                          <>
                            <select
                              className={`${
                                settings.title === "Tags" ? "pl-3 pr-10" : "px-3"
                              } border-0 py-3 bg-white rounded text-sm shadow w-full`}
                              name={filter.field}
                              onChange={handleFilterChange}
                            >
                              <option value="">Choose {filter.label}</option>
                              {filter.options.map((option, index) => (
                                <option value={option.value} key={index}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </>
                        </div>
                      );
                    })}

                  <div className="px-2 mb-3">
                    <button
                      className="bg-gray-400 w-full md:w-fit text-white font-bold px-3 py-2.5 mt-2 rounded shadow"
                      type="submit"
                    >
                      <i className="fas fa-search" /> <span className="font-medium md:hidden ml-2">Search</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}

        <div className="block w-full overflow-x-auto px-4 md:px-0">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th
                    key={`header-${index}`}
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    }
                  >
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {elements &&
                elements.map((element) => (
                  <tr key={`field-${element.id}`}>
                    {headers.map((header, index) => (
                      <td
                        key={`${element.id}-header-${index}`}
                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 relative"
                      >
                        {header.render && header.render(element)}
                        {header.field && element[header.field]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>

          <Pagination
            pageCount={data.total_page}
            itemCount={data.total_items}
            onPageChange={onPageChange}
            showAll={true}
          />
        </div>
      </div>
    </>
  );
}
