import api from "lib/api";
import { useEffect, useState } from "react";
import { useError } from "contexts/error";
import OrderRecipientImport from "./OrderRecipientImport";
import OrderRecipientStatus from "./OrderRecipientStatus";
import OrderRecipientBatchUpdate from "./OrderRecipientBatchUpdate";
import Spinner from "components/Spinner";
import OrderPickup from "./OrderPickup";
import Pagination from "components/Pagination";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function OrderRecipients({
  order,
  onSuccessPickup,
  showPickup,
  setShowPickup,
  selectedRecipients,
  setSelectedRecipients,
  showBatchUpdate,
  setShowBatchUpdate,
  showImport,
  setShowImport,
}) {
  const [recipients, setRecipients] = useState([]);
  const [data, setData] = useState([]);
  const { handleError, handleSuccess } = useError();
  // const [showImport, setShowImport] = useState(false);
  // const [showBatchUpdate, setShowBatchUpdate] = useState(false);
  // const [showPickup, setShowPickup] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  // const [downloadLoading, setDownloadLoading] = useState(false);
  const [procureLoading, setProcureLoading] = useState({ recipientId: 0, load: false });
  const [checkAll, setCheckAll] = useState(false);
  const [showCheckAll, setShowCheckAll] = useState(false);
  const [afterPageChange, setAfterPageChange] = useState(false);
  // const [selectedRecipients, setSelectedRecipients] = useState([]);
  let query = useLocation();
  let history = useHistory();

  // const isSenderClient = order && order.sender_type === "client";
  const isSenderCustomer = order && order.sender_type === "customer";
  const isDraft = order && order.status === "draft";
  const isWaitingPayment = order && order.status === "waiting_payment";
  // const isProcessing = order && order.status === "processing";
  // const isCompleted = order && order.status === "completed";

  const [pagination, setPagination] = useState(null);
  const [temporaryfilterValues, setTemporaryFilterValues] = useState({
    recipient_email: "",
    recipient_name: "",
    recipient_phone_number: "",
    status: "processing",
  });
  const [filterValues, setFilterValues] = useState({
    recipient_email: "",
    recipient_name: "",
    recipient_phone_number: "",
    status: "processing",
  });

  let statusMap = {
    address_requested: "Waiting Address",
    waiting_payment: "Waiting Payment",
    processing: "Processing",
    delivering: "Delivering",
    delivered: "Delivered",
  };

  let getOrderRecipients = async (id) => {
    const { data, err } = await api.controllers.getOrderRecipients(id, {
      limit: pagination ? pagination.limit : 20,
      page: pagination ? pagination.page : 1,
      ...(filterValues.status.length > 0 && { status: filterValues.status }),
      ...(filterValues.recipient_phone_number.length > 0 && {
        recipient_phone_number: filterValues.recipient_phone_number,
      }),
      ...(filterValues.recipient_name.length > 0 && { recipient_name: filterValues.recipient_name }),
      ...(filterValues.recipient_email.length > 0 && { recipient_email: filterValues.recipient_email }),
    });
    if (!err) {
      setRecipients(data.data);
      setData(data);
    }
  };

  useEffect(() => {
    pagination && order && getOrderRecipients(order.id);
  }, [order, pagination, filterValues]); // eslint-disable-line react-hooks/exhaustive-deps

  let onRecipientDelete = async (recipient) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${
          recipient.recipient_email
            ? recipient.recipient_email
            : recipient.recipient_name
            ? recipient.recipient_name
            : recipient.phone_number
            ? recipient.phone_number
            : ""
        }?`
      )
    ) {
      return;
    }
    const { err } = await api.controllers.removeOrderRecipient(order.id, recipient.id);

    if (err) {
      handleError(err);
    } else {
      getOrderRecipients(order.id);
    }
  };

  let onRecipientEdit = async (recipient) => {
    setSelectedRecipient(recipient);
  };

  let onRecipientNotif = async (recipient) => {
    const { err } = await api.controllers.notifOrderRecipient(order.id, recipient.id);

    if (err) {
      handleError(err);
    } else {
      handleSuccess("notification successfully sent");
    }
  };

  let onSelected = async (recipient) => {
    let newRecipients = [...selectedRecipients];
    let recipientIndex = newRecipients.indexOf(recipient.id);
    if (recipientIndex === -1) {
      newRecipients.push(recipient.id);
    } else {
      newRecipients.splice(recipientIndex, 1);
    }
    setSelectedRecipients(newRecipients);
  };

  useEffect(() => {
    let ids = recipients
      .filter((recipient) => recipient.status === "processing" && !recipient.has_requested_pickup && recipient.province)
      .map((recipient) => recipient.id);

    if (!afterPageChange) {
      if (checkAll) {
        setSelectedRecipients([...new Set([...selectedRecipients, ...ids])]);
      } else {
        setSelectedRecipients(selectedRecipients?.filter((id) => !ids.includes(id)));
      }
    }
  }, [checkAll, recipients]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let ids = recipients
      .filter((recipient) => recipient.status === "processing" && !recipient.has_requested_pickup && recipient.province)
      .map((recipient) => recipient.id);

    const result = ids.every((x) => selectedRecipients?.includes(x));
    if (result) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [selectedRecipients, recipients]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterChange = (e) => {
    setTemporaryFilterValues({ ...temporaryfilterValues, [e.target.name]: e.target.value });
  };

  const onPageChange = (page, limitPerPage) => {
    setPagination({ page: page, limit: limitPerPage ? limitPerPage : 20 });
  };

  useEffect(() => {
    setTemporaryFilterValues(filterValues);
  }, [filterValues]);

  const onFilterSubmit = (e) => {
    e.preventDefault();

    setFilterValues(temporaryfilterValues);
  };

  useEffect(() => {
    setCheckAll(false);
    setAfterPageChange(true);
  }, [pagination]);

  useEffect(() => {
    recipients.some(
      (recipient) => recipient.status === "processing" && !recipient.has_requested_pickup && recipient.province
    )
      ? setShowCheckAll(true)
      : setShowCheckAll(false);
  }, [recipients]); // eslint-disable-line react-hooks/exhaustive-deps

  function convertDate(string) {
    // Converting the date string to a Date object
    let dateObj = new Date(string);

    // Formatting the Date object into the desired format
    let options = { day: "numeric", month: "short", year: "numeric" };
    return dateObj.toLocaleDateString("en-GB", options);
  }

  const handleFilterRecipient = (id) => {
    // Filter out the recipients with the same ID as the button id
    const filteredRecipients = recipients.filter((recipient) => recipient.id !== id);

    // Update the state with the filtered array
    setRecipients(filteredRecipients);
  };

  let onRecipientProcurring = async (recipient) => {
    setProcureLoading({ ...procureLoading, load: true, recipientId: recipient.id });
    const { err } = await api.controllers.updateOrderRecipientProcurring(order.id, recipient.id);
    setProcureLoading({ ...procureLoading, load: false, recipientId: recipient.id });

    if (err) {
      handleError(err);
    } else {
      if (filterValues.status === "processing") {
        setTimeout(() => {
          handleFilterRecipient(recipient.id);
        }, 1000);
      } else {
        setProcureLoading({ ...procureLoading, recipientId: 0 });
        getOrderRecipients(order.id);
      }
    }
  };

  useEffect(() => {
    if (query.hash) {
      let str = query.hash.replace("#recipients-", "");
      setFilterValues({ ...filterValues, status: str });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const statusTab = [
    { value: "", Title: "All" },
    { value: "address_requested", Title: "Waiting Address" },
    { value: "processing", Title: "Processing" },
    ...(order.type === "personal" ? [{ value: "procuring", Title: "Procuring" }] : []),
    { value: "delivering", Title: "Delivering" },
    { value: "delivered", Title: "Delivered" },
  ];

  const table = (
    <div className="overflow-x-auto w-full">
      <form className="block w-full overflow-x-auto py-4 border-t border-gray-100 pl-2 pr-4" onSubmit={onFilterSubmit}>
        <div className="">
          <div className={`md:flex items-endblock`}>
            <div className="w-full px-2 mb-3">
              <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Name</label>
              <input
                name="recipient_name"
                type="text"
                className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                value={temporaryfilterValues.recipient_name}
                onChange={handleFilterChange}
              />
            </div>

            <div className="w-full px-2 mb-3">
              <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Email</label>
              <input
                name="recipient_email"
                type="text"
                placeholder="xxx@gmail.com"
                className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full placeholder:text-gray-300"
                value={temporaryfilterValues.recipient_email}
                onChange={handleFilterChange}
              />
            </div>

            <div className="w-full px-2 mb-3">
              <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Phone Number</label>
              <input
                name="recipient_phone_number"
                type="text"
                className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                value={temporaryfilterValues.recipient_phone_number}
                onChange={handleFilterChange}
              />
            </div>

            <div className="px-2 mt-4">
              <button
                className="bg-gray-400 w-full md:w-fit text-white font-bold px-3 py-2.5 mt-2 rounded shadow"
                type="submit"
              >
                <i className="fas fa-search" /> <span className="font-medium md:hidden ml-2">Search</span>
              </button>
            </div>
          </div>
        </div>
      </form>

      <div className="flex flex-wrap gap-y-2 gap-x-2 mb-5">
        {statusTab.map((category) => (
          <div
            onClick={() => {
              setFilterValues({ ...filterValues, status: category.value });
              history.push(
                `${window.location.pathname}${category.value !== "" ? `#recipients-${category.value}` : ""}`
              );
            }}
            className={`border px-5 py-2 rounded-full bg-white mb-2 lg:mb-0 cursor-pointer ${
              filterValues.status === category.value ? " border-primary" : ""
            }`}
            key={`category-${category.value}`}
          >
            <div
              className={`${
                filterValues.status === category.value ? "text-primary" : "text-gray-600 hover:text-black"
              } text-sm gap-3 font-medium flex lg:justify-between items-center`}
            >
              <p>{category.Title}</p>
            </div>
          </div>
        ))}
      </div>

      {recipients.length > 0 ? (
        <table className="items-center w-fit md:w-full bg-white border-collapse mb-4 ">
          <thead>
            <tr>
              <th
                className={`${
                  showCheckAll ? "px-2" : "px-6"
                } align-middle border border-solid py-3 text-xs border-l-0 border-r-0 font-semibold text-left 
              bg-slate-50 text-slate-500 border-slate-100`}
              >
                {showCheckAll ? (
                  <>
                    <input
                      name={`recipient_all`}
                      type="checkbox"
                      className="border placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 "
                      checked={checkAll ? true : false}
                      onChange={() => {
                        setCheckAll((prev) => !prev);
                        setAfterPageChange(false);
                      }}
                    />
                  </>
                ) : (
                  ` `
                )}
              </th>
              <th
                className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold
             text-left bg-slate-50 text-slate-500 border-slate-100"
              >
                Name
              </th>
              {!isDraft && (
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibol
                d text-left bg-slate-50 text-slate-500 border-slate-100"
                >
                  Status
                </th>
              )}
              {(order.has_recipient_variations || order.type === "personal") && (
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold
                 text-left bg-slate-50 text-slate-500 border-slate-100"
                >
                  Variation
                </th>
              )}
              <th
                className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold
             text-left bg-slate-50 text-slate-500 border-slate-100"
              >
                Address
              </th>
              {isSenderCustomer && isWaitingPayment && (
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold
                 text-left bg-slate-50 text-slate-500 border-slate-100"
                >
                  Courier
                </th>
              )}

              <th
                className="w-4 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold
             text-left bg-slate-50 text-slate-500 border-slate-100"
              >
                &nbsp;
              </th>

              {!isDraft && (
                <th
                  className="w-4 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold
               text-left bg-slate-50 text-slate-500 border-slate-100"
                >
                  &nbsp;
                </th>
              )}

              {order.type === "personal" && (filterValues.status === "processing" || filterValues.status === "") && (
                <th
                  className="w-4 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold
                      text-left bg-slate-50 text-slate-500 border-slate-100"
                >
                  &nbsp;
                </th>
              )}

              {(filterValues.status === "address_requested" || filterValues.status === "delivering") && (
                <th
                  className="w-4 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold
                      text-left bg-slate-50 text-slate-500 border-slate-100"
                >
                  &nbsp;
                </th>
              )}
            </tr>
          </thead>

          <tbody>
            {order &&
              recipients.map((recipient) => (
                <tr
                  key={`field-${recipient.id}`}
                  className={`${
                    !procureLoading.load &&
                    filterValues.status === "processing" &&
                    procureLoading.recipientId === recipient.id
                      ? "opacity-0 duration-1000 transition-all ease-out"
                      : ""
                  }`}
                >
                  <td className="px-2 align-middle text-xs p-2 w-4">
                    {recipient.status === "processing" && !recipient.has_requested_pickup && recipient.province && (
                      <input
                        name={`recipient_${recipient.id}`}
                        type="checkbox"
                        className="border placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2"
                        checked={selectedRecipients?.indexOf(recipient.id) !== -1 ? true : false}
                        onChange={() => {
                          onSelected(recipient);
                          setAfterPageChange(true);
                        }}
                      />
                    )}
                  </td>
                  <td
                    className={`border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 ${
                      recipient.recipient_name ? "max-w-[140px]" : ""
                    }`}
                  >
                    {recipient.recipient_name && (
                      <>
                        <p className="flex gap-3 items-center">
                          {recipient.recipient_name}{" "}
                          <a
                            href={`https://gifts.yippy.id/${recipient.token}`}
                            className="text-gray-400"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            <i className="text-base fas fa-eye"></i>
                          </a>
                        </p>
                        {recipient?.recipient_email && (
                          <>
                            <span className="text-gray-400">{recipient?.recipient_email}</span>
                            <br />
                          </>
                        )}
                        {recipient?.recipient_phone_number && (
                          <>
                            <span className="text-gray-400">{recipient?.recipient_phone_number}</span>
                            <br />
                          </>
                        )}
                        {recipient?.additional_data && recipient?.additional_data["company"] && (
                          <>
                            <span className="text-gray-400">
                              Company: <span className="capitalize">{recipient?.additional_data["company"]}</span>
                            </span>
                            <br />
                          </>
                        )}
                      </>
                    )}

                    {!recipient.recipient_name && recipient.status !== "draft" && recipient.status !== "delivered" && (
                      <a
                        href={`https://gifts.yippy.id/${recipient.token}`}
                        className="text-gray-400 inline-block mt-2 underline"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {`https://gifts.yippy.id/${recipient.token}`}
                      </a>
                    )}
                  </td>

                  {!isDraft && (
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 max-w-[220px]">
                      {recipient.status === "processing" && recipient.procured_at
                        ? "Procuring"
                        : statusMap[recipient.status]}
                      <br />

                      {/* {recipient.status === "processing" && recipient.procured_at && (
                      <span className="text-gray-400">
                        <br />
                        {convertDate(recipient.procured_at)}
                      </span>
                    )} */}

                      {recipient.status === "address_requested" && (
                        <span className="text-gray-400">
                          <br />
                          {convertDate(recipient.created_at)}
                        </span>
                      )}

                      {(recipient.status === "delivering" || recipient.status === "delivered") && (
                        <span className="text-gray-400">
                          <br />
                          {recipient.tracking_courier && recipient.tracking_courier.toUpperCase()}{" "}
                          {recipient.tracking_number}
                        </span>
                      )}
                    </td>
                  )}

                  {(order.has_recipient_variations || order.type === "personal") && (
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 max-w-[300px]">
                      {recipient.products.map((product) => (
                        <div key={`recipient-${recipient.id}-product-${product.id}`}>
                          <span className="">{product.product_name}</span>
                          {product.variations.map((variation) => (
                            <div key={`recipient-${recipient.id}-product-${product.id}-variation-${variation.name}`}>
                              <span className="text-xs text-gray-400">
                                {variation.name}: {variation.option}
                              </span>
                            </div>
                          ))}
                        </div>
                      ))}
                    </td>
                  )}

                  <td className="border-t-0 px-6 border-l-0 border-r-0 text-xs p-4 max-w-[250px]">
                    {recipient.address && (
                      <>
                        <p>
                          {recipient.latitude && recipient.longitude && (
                            <i className="fas fa-map-marker-alt text-primary mr-2" />
                          )}
                          {recipient.address}
                        </p>
                        <p>{recipient.phone_number || recipient.recipient_phone_number}</p>
                        {recipient.address && recipient.province && (
                          <p>
                            {recipient.subdistrict}, {recipient.city}, {recipient.province}, {recipient.postal_code}
                          </p>
                        )}
                        {recipient.country && recipient.country !== "indonesia" && (
                          <p className="inline-block capitalize py-1 px-2 bg-primary text-white text-xs font-bold rounded">
                            {recipient.country}
                          </p>
                        )}
                      </>
                    )}
                  </td>

                  {/* note  */}
                  {isSenderCustomer && isWaitingPayment && (
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4">
                      <p>
                        {recipient.tracking_courier && recipient.tracking_courier.toUpperCase()}{" "}
                        {recipient.courier_service}
                      </p>
                      <p className="text-xs text-gray-400">
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          maximumFractionDigits: 0,
                        }).format(recipient.delivery_cost)}
                      </p>
                    </td>
                  )}

                  {isDraft && (
                    <td className="w-4 border-t-0 px-2 justify-center align-middle border-l-0 border-r-0 text-xs p-2">
                      <button type="button" onClick={() => onRecipientDelete(recipient)}>
                        <i className="fas fa-trash"></i>
                      </button>
                    </td>
                  )}

                  {!isDraft && (
                    <>
                      {(recipient.status === "address_requested" || recipient.status === "processing") && (
                        <>
                          <td className="w-4 border-t-0 px-2 justify-center align-middle border-l-0 border-r-0 text-xs p-2">
                            <button type="button" onClick={() => onRecipientEdit(recipient)}>
                              <i className="fas fa-pen"></i>
                            </button>
                          </td>

                          <td className="w-4 border-t-0 px-2 justify-center align-middle border-l-0 border-r-0 text-xs p-2">
                            <button type="button" onClick={() => onRecipientDelete(recipient)}>
                              <i className="fas fa-trash"></i>
                            </button>
                          </td>
                        </>
                      )}

                      {(recipient.status === "address_requested" || recipient.status === "delivering") && (
                        <>
                          <td className="w-4 border-t-0 px-2 justify-center align-middle border-l-0 border-r-0 text-xs p-2">
                            <button type="button" onClick={() => onRecipientNotif(recipient)}>
                              <i className="fas fa-bell"></i>
                            </button>
                          </td>
                        </>
                      )}

                      {order.type === "personal" && recipient.status === "processing" && !recipient.procured_at && (
                        <td className="w-4 border-t-0 px-2 justify-center align-middle border-l-0 border-r-0 text-xs p-2">
                          {procureLoading.load && procureLoading.recipientId === recipient.id ? (
                            <Spinner color="black" />
                          ) : (
                            <button type="button" onClick={() => onRecipientProcurring(recipient)}>
                              <i className="fas fa-shopping-bag"></i>
                            </button>
                          )}
                        </td>
                      )}
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <>
          <img
            src={require("assets/img/recipients-empty.png").default}
            alt="..."
            className="w-11/12 md:w-96 aspect-square object-contain mx-auto"
          ></img>
          <p className="text-center mb-10 text-lg text-gray-500 font-medium">No recipients found</p>
        </>
      )}

      <Pagination pageCount={data.total_page} itemCount={data.total_items} onPageChange={onPageChange} showAll={true} />
    </div>
  );

  return (
    <>
      <div>
        {order && (
          <>
            {/* <div className="flex gap-x-4">
              {order.status === "draft" && (
                <button
                  className="mb-4 flex bg-gray-500 active:bg-gray-600 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowImport(true)}
                >
                  Add Recipients
                </button>
              )}

              {order.status === "processing" && (
                <button
                  className="mb-4 flex bg-gray-500 active:bg-gray-600 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowBatchUpdate(true)}
                >
                  Update Status
                </button>
              )}

              {order.status !== "draft" && (
                <button
                  className="mb-4 flex bg-gray-500 active:bg-gray-600 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => onDownloadClicked()}
                >
                  {downloadLoading && <Spinner />} Download
                </button>
              )}

              {selectedRecipients?.length > 0 && (
                <button
                  className="mb-4 flex bg-primary text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowPickup(true)}
                >
                  Pickup ({selectedRecipients?.length})
                </button>
              )}
            </div> */}

            {table}
          </>
        )}
      </div>

      {selectedRecipient ? (
        <OrderRecipientStatus
          order={order}
          recipient={selectedRecipient}
          onSuccess={() => getOrderRecipients(order.id)}
          onClose={() => setSelectedRecipient(null)}
        />
      ) : null}

      {showImport ? (
        <OrderRecipientImport
          order={order}
          onSuccess={() => getOrderRecipients(order.id)}
          onClose={() => setShowImport(false)}
        />
      ) : null}

      {showBatchUpdate ? (
        <OrderRecipientBatchUpdate
          order={order}
          onSuccess={() => getOrderRecipients(order.id)}
          onClose={() => setShowBatchUpdate(false)}
        />
      ) : null}

      {showPickup ? (
        <OrderPickup
          order={order}
          recipients={selectedRecipients}
          onSuccess={() => {
            getOrderRecipients(order.id);
            setSelectedRecipients([]);
            onSuccessPickup();
          }}
          onClose={() => setShowPickup(false)}
        />
      ) : null}
    </>
  );
}
