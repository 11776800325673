import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Table from "components/Table/Table.js";
import api from "lib/api";

export default function UserList() {
  const [data, setData] = useState({});
  const history = useHistory();
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    const get = async () => {
      const { data, err } = await api.controllers.getStaffs(pagination.page, pagination.limit);
      if (!err) {
        setData(data);
      }
    };
    pagination && get();
  }, [pagination]);

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Table
            settings={{
              title: "Users",
              button: {
                label: "Add",
                onClick: () => {
                  history.push("/users/new");
                },
              },
              headers: [
                {
                  label: "Name",
                  render: (element) => {
                    return (
                      <Link to={`/users/${element.id}`} className={"underline"}>
                        {element.name}
                      </Link>
                    );
                  },
                },
                {
                  label: "Email",
                  field: "email",
                },
                {
                  label: "Role",
                  field: "role",
                },
                {
                  label: "Status",
                  render: (element) => {
                    return element.active ? "Active" : "Not Active";
                  },
                },
              ],
            }}
            data={data}
            setPagination={setPagination}
          />
        </div>
      </div>
    </>
  );
}
