import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const TableDropdown = ({ items }) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const dropdownPopover = () => {
    setDropdownPopoverShow((prev) => !prev);
  };

  const submenusRef = useRef();

  useEffect(() => {
    const closeSubMenu = (e) => {
      if (!submenusRef?.current?.contains(e.target)) {
        setDropdownPopoverShow(false);
      }
    };

    document.body.addEventListener("click", closeSubMenu);

    return () => {
      document.body.removeEventListener("click", closeSubMenu);
    };
  }, [submenusRef]);

  return (
    <>
      <Link
        className="text-slate-500 py-1 px-3"
        to={"#"}
        ref={submenusRef}
        onClick={() => {
          dropdownPopover();
        }}
      >
        <i className="fas fa-ellipsis-v"></i>
      </Link>

      <div
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left list-none text-left rounded border border-gray-50 min-w-48 absolute right-0 bottom-2 mr-10"
        }
      >
        {items.map((item, index) => (
          <div key={`dropdown-${index}`}>
            <Link
              to={"#"}
              className={
                "text-sm py-3 px-4 font-normal block w-full whitespace-nowrap bg-transparent hover:text-primary border-b"
              }
              onClick={item.onClick}
            >
              {item.label}
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default TableDropdown;
