import api from "lib/api";
import Catalogue from "components/Catalogue/Catalogue";
import CatalogueProductForm from "components/Catalogue/CatalogueProductForm";
import { useError } from "contexts/error";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";

export default function Products({ packages, id, reFetch }) {
  let history = useHistory();
  const [groups, setGroups] = useState([
    {
      id: 1,
      name: "Products",
      categoryType: "gift",
      position: 1,
      show_catalogue: false,
      showAllCategories: true,
      editableQuantity: true,
      products: [],
    },
    {
      id: 2,
      name: "Packaging",
      categoryType: "packaging",
      position: 2,
      show_catalogue: false,
      showAllCategories: false,
      editableQuantity: false,
      products: [],
    },
    {
      id: 3,
      categoryType: "decoration",
      name: "Decoration",
      position: 3,
      show_catalogue: false,
      showAllCategories: false,
      editableQuantity: false,
      products: [],
    },
  ]);

  useEffect(() => {
    if (packages.products.length > 0) {
      let gift = packages.products.filter((product) => product.category_type === "gift");
      let packaging = packages.products.filter((product) => product.category_type === "packaging");
      let decoration = packages.products.filter((product) => product.category_type === "decoration");

      let updatedGroups = groups.map((group) => {
        if (group.id === 1) {
          return {
            ...group,
            products: gift,
          };
        }
        if (group.id === 2) {
          return {
            ...group,
            products: packaging,
          };
        }
        if (group.id === 3) {
          return {
            ...group,
            products: decoration,
          };
        }
        return group;
      });

      setGroups(updatedGroups);
    }
  }, [packages]); // eslint-disable-line react-hooks/exhaustive-deps

  const { handleError, handleSuccess } = useError();

  let onShowCatalogueChange = (index, value) => {
    let newGroups = [...groups];

    newGroups = newGroups.map((group, i) => ({
      ...group,
      show_catalogue: i === index ? value : !value,
    }));

    setGroups(newGroups);
  };

  let addProduct = async (product, values) => {
    const { err } = await api.controllers.addPackageProduct(id, product.id, {
      variations: values.variations,
      quantity: values.quantity,
    });

    if (err) {
      handleError(err);
    } else {
      handleSuccess("Products added");
      reFetch();
    }
  };

  let removeProduct = async (product) => {
    const { err } = await api.controllers.deletePackageProduct(id, product.id);

    if (err) {
      handleError(err);
    } else {
      handleSuccess("Products remove");
      reFetch();
    }
  };

  let renderProduct = (index, product, addedProduct, editableQty) => {
    return (
      <CatalogueProductForm
        addedItem={addedProduct}
        key={product.id}
        product={product}
        onSubmit={(product, values) => addProduct(product, values)}
        hideNote={true}
        hideVariations={true}
        hideHover={true}
        packages={true}
        editableQty={editableQty}
      />
    );
  };

  return (
    <div>
      {groups &&
        groups.map((group, index) => (
          <div className="w-full mb-4" key={`group-${index}`}>
            <div className="bg-gray-100 p-2 flex justify-between">
              <div className="w-1/2 flex">
                <p className="w-fit mr-4 border-0 px-3 py-3 font-bold rounded text-sm">{group.name}</p>
              </div>
            </div>

            <div className="bg-gray-50 grid grid-cols-5 gap-4 p-2">
              {group.products &&
                group.products.map((product, productIndex) => {
                  let imageUrl =
                    process.env.REACT_APP_ENVIRONMENT === "production"
                      ? product.image_url
                      : "https://picsum.photos/200";

                  return (
                    <div className="bg-white shadow-lg relative" key={`product-${productIndex}`}>
                      <div>
                        <img src={imageUrl} alt={product.name} className="w-full aspect-square rounded-t" />
                        <div className="p-4">
                          <h4 className="mb-2 text-sm">{product.name}</h4>
                          <div className="flex flex-wrap gap-1 my-1">
                            {product.variations && product.variations.length > 0 && (
                              <>
                                {product.variations.map((variation) => (
                                  <p key={variation.id} className="text-xs bg-[#c2dced] rounded-full px-2 py-1 w-fit">
                                    {variation.option}
                                  </p>
                                ))}
                              </>
                            )}
                            <p className="text-xs bg-[#c2dced] rounded-full px-2 py-1 w-fit">Qty: {product.quantity}</p>
                          </div>
                          <div className="flex items-center text-primary font-bold">
                            {new Intl.NumberFormat("id-ID", {
                              style: "currency",
                              currency: "IDR",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(product.price)}
                          </div>
                        </div>
                      </div>

                      <button
                        className="absolute"
                        type="button"
                        onClick={() => removeProduct(product)}
                        style={{
                          top: "-8px",
                          right: "-8px",
                        }}
                      >
                        <i className="fas fa-times-circle" />
                      </button>
                    </div>
                  );
                })}

              {!group.show_catalogue && (
                <button
                  className="bg-white p-2 h-24 border"
                  type="button"
                  onClick={() => {
                    onShowCatalogueChange(index, true);
                    history.push(`${window.location.pathname}`);
                  }}
                >
                  Add <i className="fas fa-plus" />
                </button>
              )}
            </div>

            <div>
              {group.show_catalogue && (
                <div className="pl-4 pr-8 my-8">
                  <h3 className="text-center font-bold text-2xl mb-4">Add a product</h3>
                  <Catalogue
                    addedItem={packages.products.map((item) => ({ product_id: item.id }))}
                    renderProduct={(product) => renderProduct(index, product, group.products, group.editableQuantity)}
                    options={{
                      categoryType: group.categoryType,
                      contentBorder: true,
                      cols: 5,
                      showAllCategories: group.showAllCategories,
                      showKeyword: false,
                      showSort: true,
                      showSearch: true,
                      showFilters: true,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}
