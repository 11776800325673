import api from "lib/api";
import Modal from "components/Modal/Modal";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import { useState } from "react";

export default function OrderDuplicate({ order, onSuccess, onClose }) {
  const [values, setValues] = useState({
    name: "",
    recipient_count: 1,
    delivery_date: "",
  });
  const [loading, setLoading] = useState(false);
  const { handleError } = useError();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { err } = await api.controllers.duplicateOrder(order.id, values);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      onClose();
      onSuccess();
    }
  };

  const form = (
    <form className="flex justify-center" onSubmit={onSubmit}>
      <div className="w-full lg:w-1/2">
        <div className="w-full mb-3">
          <label className="block uppercase text-xs font-bold">
            Name{" "}
            <span className="text-red-500">
              <sup>*</sup>
            </span>
          </label>
          <input
            name="name"
            type="text"
            className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="Campaign"
            value={values.name}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-3">
          <label className="block uppercase text-xs font-bold">
            Recipient Count{" "}
            <span className="text-red-500">
              <sup>*</sup>
            </span>
          </label>
          <input
            name="recipient_count"
            type="text"
            className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            value={values.recipient_count}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-3">
          <label className="block uppercase text-xs font-bold">
            Delivery Date{" "}
            <span className="text-red-500">
              <sup>*</sup>
            </span>
          </label>
          <input
            name="delivery_date"
            type="date"
            className="border-0 px-3 py-3 my-4 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow w-full"
            placeholder="Date"
            value={values.delivery_date}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-2">
          <button
            className="w-full flex justify-center bg-primary text-white font-bold uppercase text-xs px-4 py-4 rounded mr-1"
            type="submit"
          >
            {loading && <Spinner size={4} />} Save
          </button>
        </div>
      </div>
    </form>
  );

  return <Modal title={`Duplicate ${order.name}`} onClose={() => onClose()} body={form} />;
}
