import React, { useCallback, useEffect, useState } from "react";
import api from "lib/api";
import CatalogueProductForm from "components/Catalogue/CatalogueProductForm";
import { useError } from "contexts/error";
import Catalogue from "components/Catalogue/Catalogue";
import useDidMountEffect from "../../../../lib/useDidMountEffect";

const OrderPersonalTypeItems = ({ order, setStep, loadOrder }) => {
  const [products, setProducts] = useState([]);
  const { handleError } = useError();

  const fetchOrderProducts = useCallback(async () => {
    const { data, err } = await api.controllers.getOrderProducts(order.id);
    if (!err) {
      setProducts(data.data);
    }
  }, [order.id]);
  const [budget, setBudget] = useState(order.budget);

  useDidMountEffect(async () => {
    const { err } = await api.controllers.patchOrder(order.id, {
      budget: budget,
    });

    if (err) {
      handleError(err);
    } else {
      loadOrder();
    }
  }, [budget]);

  const onBudgetUpdate = (budget) => {
    setBudget(budget);
  };

  useEffect(() => {
    fetchOrderProducts();
  }, [fetchOrderProducts]);

  let onSubmit = async (product, values) => {
    const { err } = await api.controllers.addOrderProduct(order.id, {
      products: [
        {
          id: product.id,
          quantity: values.quantity,
          note: values.note,
          variations: values.variations,
        },
      ],
    });

    if (err) {
      handleError(err);
    } else {
      fetchOrderProducts();

      return true;
    }

    return false;
  };

  const onDelete = async (id) => {
    const { err } = await api.controllers.deleteOrderProduct(order.id, id);
    if (!err) {
      fetchOrderProducts();
    }
  };

  let renderProduct = (product, options) => {
    return (
      <CatalogueProductForm
        onDelete={onDelete}
        addedItem={products}
        key={product.id}
        product={product}
        onSubmit={onSubmit}
        checkRecipientVariation={true}
        suggestionItem={options && options.suggestionItem}
        orderType="personal"
      />
    );
  };

  const giftProducts = products.filter((item) => item.product_category_type === "gift");

  return (
    <>
      <div className="relative">
        <>
          <div className="mt-10 mb-12 px-6 w-7/12 mx-auto text-center">
            <h3 className="text-3xl font-bold">Pick at least 2 items for your recipients to choose</h3>
          </div>

          <div className="w-full flex justify-center">
            <Catalogue
              addedItem={products}
              onPriceOptionUpdate={onBudgetUpdate}
              renderProduct={renderProduct}
              options={{
                categoryType: "gift",
                personal: true,
                contentBorder: true,
                cols: 5,
                showAllCategories: true,
                showFilters: true,
                showKeyword: false,
                showSort: true,
                showSearch: true,
                ...(order.multiple_items && {
                  showPrice: true,
                  priceOptions: [100000, 200000, 300000, 500000],
                  showPriceType: "options",
                  price: `0,${budget}`,
                }),
              }}
            />
          </div>
        </>

        {setStep && (
          <div className="flex justify-center bg-white fixed bottom-0 right-0 left-0 py-4 border-t border-slate-200">
            <div className="w-fit mx-auto flex gap-x-5">
              <button
                className="bg-white text-primary font-bold px-10 py-2 rounded-md border border-primary cursor-pointer select-none w-fit flex"
                onClick={() => setStep("personal_type")}
              >
                <img
                  src={require("assets/img/arrow-right.svg").default}
                  alt={"arrow-left"}
                  className="w-6 mr-2 mx-auto rotate-180 svg_orange"
                />
                <span>back</span>
              </button>

              <button
                className={`${
                  giftProducts.length < 2 ? "bg-tertiary_gray_two " : "bg-primary "
                } text-white font-bold px-10 py-2 rounded-md cursor-pointer select-none w-fit flex`}
                onClick={() => setStep("quotation")}
                disabled={giftProducts.length < 2}
              >
                <span>Proposal</span>
                <img
                  src={require("assets/img/arrow-right.svg").default}
                  alt={"arrow-right"}
                  className="w-6 ml-2 mx-auto"
                />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderPersonalTypeItems;
