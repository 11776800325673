import { useError } from "contexts/error";
import React from "react";

function FormType({ values, handleChange, setFormBody }) {
  const { handleError } = useError();

  return (
    <div className="w-full mb-4">
      <img
        src={require("assets/img/new-order/new-order-type-icon.png").default}
        alt={"new-order-type-icon"}
        className="w-44 mx-auto mt-10 mb-5"
      />

      <label className="block font-bold text-center text-3xl">
        Who will choose the gifts?
      </label>

      <div className="px-4 my-5 grid grid-cols-2 w-10/12 mx-auto gap-x-5">
        <div
          className={`mb-2 mt-4 rounded-lg bg-neutral-100 
 ${values.type === "branded" ? "border border-indigo-300" : ""}`}
        >
          <div className="flex items-center">
            <input
              id="company"
              type="radio"
              name="type"
              onChange={handleChange}
              value="branded"
              checked={values.type === "branded"}
              className="h-4 w-4 mr-2 border-gray-300 focus:ring-2 focus:ring-blue-300 opacity-0"
            />
            <label
              htmlFor="company"
              className="block text-center -ml-5 cursor-pointer pb-3 px-3"
            >
              <img
                src={
                  require("assets/img/new-order/new-order-company.png").default
                }
                alt={"new-order-company"}
                className="w-12 mx-auto py-4"
              />
              <h3 className="font-bold mb-1">Company</h3>
              <p className="text-sm">
                You will choose the gifts yourself. Items chosen can be customized with company logo.
              </p>
            </label>
          </div>
        </div>

        <div
          className={`mb-2 mt-4 rounded-lg bg-neutral-100 
 ${values.type === "personal" ? "border border-indigo-300" : ""}`}
        >
          <div className="flex items-center">
            <input
              id="recipients"
              type="radio"
              name="type"
              onChange={handleChange}
              value="personal"
              checked={values.type === "personal"}
              className="h-4 w-4 mr-2 border-gray-300 focus:ring-2 focus:ring-blue-300 opacity-0"
            />
            <label
              htmlFor="recipients"
              className="block text-center -ml-5 cursor-pointer pb-3 px-3"
            >
              <img
                src={
                  require("assets/img/new-order/new-order-recipients.png")
                    .default
                }
                alt={"new-order-recipients"}
                className="w-12 mx-auto py-4"
              />
              <h3 className="font-bold mb-1">Recipients</h3>
              <p className="text-sm">
                Recipients will get the freedom of choice based on your budget or recommended items.
              </p>
            </label>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center gap-x-5 w-fit mx-auto mb-2 mt-8">
        <div
          className="py-3 px-10 w-fit bg-white text-primary font-bold cursor-pointer mx-auto flex items-center rounded-lg border border-primary"
          onClick={() => setFormBody("name")}
        >
          <img
            src={require("assets/img/arrow-right.svg").default}
            alt={"home-no-campaign-icon"}
            className="w-4 h-4 mr-2 rotate-180 svg_orange"
          />
          <p>back</p>
        </div>

        <div
          className="py-3 px-10 w-fit bg-primary text-white font-bold cursor-pointer mx-auto flex items-center rounded-lg"
          onClick={() => {
            values.type.length > 0
              ? setFormBody("delivery")
              : handleError({ message: "choose one of campaign type option" });
          }}
        >
          <p>Next</p>
          <img
            src={require("assets/img/arrow-right.svg").default}
            alt={"home-no-campaign-icon"}
            className="w-4 h-4 ml-2 "
          />
        </div>
      </div>
    </div>
  );
}

export default FormType;
