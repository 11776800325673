import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import api from "lib/api";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Information from "./Edit/Information";
import Packages from "./Edit/Packages";
import Products from "./Edit/Products";

export default function EditProposal() {
  const [proposal, setProposal] = useState(null);
  const { id } = useParams();
  const { handleError, handleSuccess } = useError();
  const [loading, setLoading] = useState(false);
  const [loadingCatalogue, setLoadingCatalogue] = useState(false);
  const [step, setStep] = useState("information");

  useEffect(() => {
    let fetchData = async (e) => {
      const { err, data } = await api.controllers.getProposal(id);

      if (!err) {
        setProposal(data.data);
      }
    };
    fetchData();
  }, [id]);

  let download = async (catalogue = false) => {
    !catalogue && setLoading(true);
    catalogue && setLoadingCatalogue(true);

    const {
      err,
      // data
    } = await api.controllers.downloadProposal(id, {
      ...(catalogue ? { view: "catalogue" } : {}),
    });

    !catalogue && setLoading(false);
    catalogue && setLoadingCatalogue(false);

    if (err) {
      handleError(err);
    } else {
      handleSuccess("Proposal will be sent to your email shortly");

      // const url = window.URL.createObjectURL(new Blob([data]));
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", `${proposal.name}.pdf`);
      // document.body.appendChild(link);
      // link.click();
    }
  };

  return (
    <>
      {!proposal && (
        <div className="flex justify-center">
          <Spinner color={"black"} />
        </div>
      )}

      {proposal && (
        <div className="flex flex-wrap">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-lg bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-xl font-bold">Edit {proposal.name}</h6>
                {proposal.product_groups.length > 0 && (
                  <div className="flex gap-3">
                    <button
                      type="button"
                      className="p-2 border-2 border-primaryTwo rounded-lg text-primaryTwo font-bold flex"
                      onClick={() => !loadingCatalogue && download(true)}
                    >
                      {loadingCatalogue && <Spinner color="black" />} Catalogue
                    </button>
                    <button
                      type="button"
                      className="p-2 bg-primaryTwo rounded-lg text-white font-bold flex"
                      onClick={() => !loading && download()}
                    >
                      {loading && <Spinner />} Download
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="relative flex flex-col min-w-0 break-words mb-6 shadow-lg rounded-lg bg-white border-0 w-full">
            <div className="w-full flex justify-between border-b border-gray-200 bg-white rounded-tl-lg rounded-tr-lg">
              <ul className="flex flex-wrap">
                <li className="mr-2">
                  {step === "information" && (
                    <>
                      <div
                        onClick={() => setStep("information")}
                        className="inline-block cursor-pointer text-sky-500 rounded-t-lg py-4 px-4 text-sm font-medium text-center active"
                      >
                        Information
                      </div>
                    </>
                  )}
                  {step !== "information" && (
                    <>
                      <div
                        onClick={() => setStep("information")}
                        className="inline-block cursor-pointer text-gray-500 hover:text-gray-600 hover:bg-gray-50 rounded-t-lg py-4 px-4 text-sm font-medium text-center"
                      >
                        Information
                      </div>
                    </>
                  )}
                </li>
                <li className="mr-2">
                  {step === "products" && (
                    <>
                      <div
                        onClick={() => setStep("products")}
                        className="inline-block cursor-pointer text-sky-500 rounded-t-lg py-4 px-4 text-sm font-medium text-center active"
                      >
                        Products
                      </div>
                    </>
                  )}
                  {step !== "products" && (
                    <>
                      <div
                        onClick={() => setStep("products")}
                        className="inline-block cursor-pointer text-gray-500 hover:text-gray-600 hover:bg-gray-50 rounded-t-lg py-4 px-4 text-sm font-medium text-center"
                      >
                        Products
                      </div>
                    </>
                  )}
                </li>
                <li className="mr-2">
                  {step === "packages" && (
                    <>
                      <div
                        onClick={() => setStep("packages")}
                        className="inline-block cursor-pointer text-sky-500 rounded-t-lg py-4 px-4 text-sm font-medium text-center active"
                      >
                        Packages
                      </div>
                    </>
                  )}
                  {step !== "packages" && (
                    <>
                      <div
                        onClick={() => setStep("packages")}
                        className="inline-block cursor-pointer text-gray-500 hover:text-gray-600 hover:bg-gray-50 rounded-t-lg py-4 px-4 text-sm font-medium text-center"
                      >
                        Packages
                      </div>
                    </>
                  )}
                </li>
              </ul>
            </div>

            <div className="p-4">
              {proposal && (
                <>
                  {step === "information" && (
                    <>
                      <Information proposal={proposal} />
                    </>
                  )}
                  {step === "products" && (
                    <>
                      <Products proposal={proposal} />
                    </>
                  )}
                  {step === "packages" && (
                    <>
                      <Packages proposal={proposal} />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
