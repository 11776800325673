import api from "lib/api";
import Modal from "components/Modal/Modal";
import { useState } from "react";
import { useError } from "contexts/error";
import Spinner from "components/Spinner";

export default function OrderDetailAdd({ onClose, onSuccess, id }) {
  const { handleError } = useError();
  const [loading, setLoading] = useState(false);

  const [formValue, setformValue] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.id]: event.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { err } = await api.controllers.addRecipientOrder(id, {
      name: formValue.name,
      ...(formValue.email.length > 0 && {
        email: formValue.email,
      }),
      ...(formValue.phone.length > 0 && {
        phone_number: formValue.phone,
      }),
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      onClose();
      onSuccess();
    }
  };

  const form = (
    <form onSubmit={onSubmit} autoComplete="on">
      <div className="w-full lg:w-6/12 mx-auto">
        {/* name  */}
        <div className="flex flex-col mb-3">
          <label htmlFor="name" className="flex items-center mb-2">
            <p className="md:text-2xl font-medium">
              Name{" "}
              <span className="text-red-500">
                <sup>*</sup>
              </span>
            </p>
          </label>
          <input
            type="text"
            id="name"
            placeholder="Name"
            value={formValue.name}
            onChange={handleChange}
            required
            className="shadow-md h-14 md:h-[4rem] rounded-md px-5"
          />
        </div>

        {/* email  */}
        <div className="flex flex-col mb-3">
          <label htmlFor="email" className="flex items-center mb-2">
            <p className="md:text-2xl font-medium">Email </p>
          </label>
          <input
            type="email"
            id="email"
            placeholder="email@example.com"
            value={formValue.email}
            onChange={handleChange}
            className="shadow-md h-14 md:h-[4rem] rounded-md px-5"
          />
        </div>

        {/* phone  */}
        <div className="flex flex-col mb-11">
          <label htmlFor="phone" className="flex items-center mb-2">
            <p className="md:text-2xl font-medium">Phone Number </p>
          </label>
          <input
            id="phone"
            type="tel"
            placeholder="08xxxxxxxxxx"
            value={formValue.phone}
            autoComplete="off"
            onChange={handleChange}
            minLength={8}
            maxLength={13}
            className="shadow-md h-14 md:h-[4rem] rounded-md px-5"
          ></input>
        </div>

        <div className="w-full lg:w-12/12 mt-3">
          <div className="relative w-full mb-3">
            <button
              type="submit"
              value="Submit"
              className="w-full flex justify-center bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-4 
          rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              {loading && <Spinner size={4} />} Kirim
            </button>
          </div>
        </div>
      </div>
    </form>
  );

  return <Modal title={`Add Recipients`} onClose={onClose} body={form} />;
}
