import api from "lib/api";
import Modal from "components/Modal/Modal";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import { useState } from "react";

export default function OrderRecipientImport({ order, onSuccess, onClose }) {
  const [loading, setLoading] = useState(false);
  const { handleError } = useError();
  const [values, setValues] = useState({ file: null });

  const onFileChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { err } = await api.controllers.importOrderRecipient(order.id, {
      file: values.file[0],
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      onClose();
      onSuccess();
    }
  };

  const form = (
    <>
      <form>
        <div className="w-2/5 px-4">
          <div className="relative w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
              File{" "}
              <span className="text-red-500">
                <sup>*</sup>
              </span>
            </label>

            <input
              name="file"
              type="file"
              className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              onChange={onFileChange}
              accept=".xls, .xlsx"
            />
          </div>

          <button
            className="flex justify-center bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="submit"
            onClick={(e) => onSubmit(e)}
          >
            {loading && <Spinner size={4} />} Import
          </button>
        </div>
      </form>

      <div className="w-full lg:w-12/12 px-4">
        <div className="relative w-full mt-5">
          <ul className="text-gray-500 text-sm list-disc pl-4">
            <li>
              Please use the following template:
              <a
                className="underline ml-2 font-bold"
                href={"/assets/order_recipient_template.xlsx"}
                target={"_blank"}
                rel="noreferrer"
              >
                download
              </a>
            </li>
            <li>Email is required</li>
          </ul>
        </div>
      </div>
    </>
  );

  return <Modal title={"Import Recipients"} onClose={() => onClose()} body={form} />;
}
