import React, { useEffect, useState } from "react";

import api from "lib/api";
import { useHistory } from "react-router";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import { useParams } from "react-router-dom";

export default function EditVoucher() {
  const [voucher, setVoucher] = useState(null);
  const [values, setValues] = useState({
    code: "",
    client_id: "",
    type: "amount",
    amount: 0,
    minimum_amount: 0,
    maximum_amount: 0,
    active: true,
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const { handleError } = useError();

  useEffect(() => {
    const get = async () => {
      const { data, err } = await api.controllers.getVoucher(id);
      if (!err) {
        const _voucher = data.data;
        setVoucher(_voucher);
        setValues({
          code: _voucher.code.toUpperCase(),
          type: _voucher.type,
          amount: _voucher.amount,
          minimum_amount: _voucher.minimum_amount,
          maximum_amount: _voucher.maximum_amount,
          active: _voucher.active,
        });
      }
    };
    get();
  }, [id]);

  let handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  let onCheckboxClicked = (e) => {
    setValues({ ...values, [e.target.name]: !values[e.target.name] });
  };

  let onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { err } = await api.controllers.updateVoucher(id, values);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      history.push("/vouchers");
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-slate-700 text-xl font-bold">{voucher && `Edit ${voucher.code}`}</h6>
            <button
              className="flex bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              onClick={(e) => onSubmit(e)}
            >
              {loading && <Spinner size={4} />} Save
            </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={onSubmit}>
            <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">Voucher Information</h6>
            <div className="flex flex-wrap w-full lg:w-6/12">
              <div className="w-full mb-3">
                <label className="block uppercase text-xs font-bold mb-2">
                  Code{" "}
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <input
                  name="code"
                  type="text"
                  className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                  placeholder="VOUCHERCODE"
                  value={values.code}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full mb-3">
                <label className="block uppercase text-xs font-bold mb-2">
                  Type{" "}
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <select
                  className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                  name="type"
                  onChange={handleChange}
                >
                  <option value="amount">Amount</option>
                  <option value="percentage">Percentage</option>
                </select>
              </div>

              <div className="w-full mb-3">
                <label className="block uppercase text-xs font-bold mb-2">
                  Amount{" "}
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <input
                  name="amount"
                  type="number"
                  className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                  placeholder="0"
                  value={values.amount}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full mb-3">
                <label className="block uppercase text-xs font-bold mb-2">
                  Minimum Amount{" "}
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <input
                  name="minimum_amount"
                  type="number"
                  className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                  placeholder="0"
                  value={values.minimum_amount}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full mb-3">
                <label className="block uppercase text-xs font-bold mb-2">
                  Maximum Amount{" "}
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <input
                  name="maximum_amount"
                  type="number"
                  className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                  placeholder="0"
                  value={values.maximum_amount}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full mb-3 flex">
                <input
                  name="active"
                  type="checkbox"
                  className="border-0 rounded text-sm shadow mr-2"
                  checked={values.active || values.active === "true" ? true : false}
                  onChange={onCheckboxClicked}
                />
                <label className="block uppercase text-slate-600 text-xs font-bold">Active</label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
