import React, { useEffect, useState } from "react";

import api from "lib/api";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import Preview from "components/Preview/Preview";
import compress from "lib/compress";

export default function OrderNotification({ order, fetchOrder }) {
  const [values, setValues] = useState({
    notification_lang: order.notification_lang,
    notification_image: null,
    notification_title: order.notification_title,
    notification_body: order.notification_body,
    notification_action: order.notification_action,
  });
  const [showLanguageOption, setShowLanguageOption] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const { handleError, handleSuccess } = useError();
  let defaultTitle = {
    id: `{{ name }}, Kamu Mendapatkan Gift dari ${order.client_name}`,
    en: `{{ name }}, You Got a Gift from ${order.client_name}`,
  };
  let defaultBody = {
    id: `Hi {{ name }},\n${order.client_name} mengirimkan kamu gift. Silahkan konfirmasi alamat untuk menerima.\nP.S: Untuk memudahkan proses pengiriman gift, mohon konfirmasi maksimal 2 hari sejak pesan ini diterima.\nTerima Kasih.`,
    en: `Hi {{ name }},\n${order.client_name} is sending you a gift. Please confirm your address to claim the gift.\nP.S: To smoothen the process of sending you the gifts, please confirm to us max. 2 days after you received this message.\nThank You.`,
  };
  let defaultAction = {
    id: "Klaim",
    en: "Claim",
  };

  const [preview, setPreview] = useState({});

  const nameRegex = /{{\s?name\s?}}/g;
  const companyRegex = /{{\s?company\s?}}/g;

  const compile = (text) => {
    return text.replaceAll(nameRegex, "John").replaceAll(companyRegex, "Yippy").replaceAll("\n", "<br />");
  };

  useEffect(() => {
    if (values.notification_lang) {
      setPreview({
        image: order.notification_image_url,
        // values.notification_title ||
        title: compile(defaultTitle[values.notification_lang]),
        // values.notification_body ||
        body: compile(defaultBody[values.notification_lang]),
        // values.notification_action ||
        action: defaultAction[values.notification_lang],
      });
    }
  }, [values.notification_lang]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.notification_image) {
      setPreview((preview) => ({ ...preview, image: URL.createObjectURL(values.notification_image) }));
    }
  }, [values.notification_image]);

  useEffect(() => {
    if (values.notification_title) {
      setPreview((preview) => ({ ...preview, title: compile(values.notification_title) }));
    }
  }, [values.notification_title]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.notification_body) {
      setPreview((preview) => ({ ...preview, body: compile(values.notification_body) }));
    }
  }, [values.notification_body]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.notification_action) {
      setPreview((preview) => ({ ...preview, action: values.notification_action }));
    }
  }, [values.notification_action]);

  useEffect(() => {
    setPreview({
      image: order.notification_image_url,
      title: compile(values.notification_title || defaultTitle[values.notification_lang]),
      body: compile(values.notification_body || defaultBody[values.notification_lang]),
      action: values.notification_action || defaultAction[values.notification_lang],
    });
  }, [order]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    setValues((values) => ({ ...values, [e.target.name]: e.target.value }));
  };
  const onFileChange = (e) => {
    setValues((values) => ({ ...values, [e.target.name]: e.target.files[0] }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const compressedImage = values.notification_image ? await compress(values.notification_image) : null;
    setLoading(true);
    const { err } = await api.controllers.updateOrderNotification(order.id, {
      notification_lang: values.notification_lang,
      notification_image: compressedImage,
      notification_title: values.notification_title,
      notification_body: values.notification_body,
      notification_action: values.notification_action,
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      fetchOrder();
      setAllowEdit(false);
      handleSuccess("Order updated");
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="relative flex flex-col min-w-0 break-words w-full md:w-3/5 mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
        <div className="flex-auto px-4 py-10">
          <form onSubmit={onSubmit} autoComplete="false">
            {allowEdit && (
              <>
                {/* language button  */}
                <div className="relative cursor-pointer">
                  <div
                    onClick={() => setShowLanguageOption((prev) => !prev)}
                    className={`text-primaryTwo font-medium flex justify-between shadow items-center gap-4 py-2 px-4 w-48 rounded-md 
            ${showLanguageOption ? "bg-primaryTwo text-slate-50" : " bg-white mb-4"}`}
                  >
                    {/* Select Language */}
                    {values.notification_lang === "id" ? "Indonesia (ID)" : "English (EN)"}
                    <img
                      src={require("assets/img/order-edit/accordion-arrow.svg").default}
                      alt={"accordion-arrow"}
                      className={`w-4 ${showLanguageOption ? "rotate-180 svg_white" : ""}`}
                    />
                  </div>
                  {showLanguageOption && (
                    <div className=" rounded-md shadow w-48 bg-white mb-3">
                      <div
                        onClick={() => {
                          setValues({ ...values, notification_lang: "id" });
                          setShowLanguageOption((prev) => !prev);
                        }}
                        className="py-2 px-4 font-medium text-primaryTwo shadow"
                      >
                        Indonesia (ID)
                      </div>
                      <div
                        onClick={() => {
                          setValues({ ...values, notification_lang: "en" });
                          setShowLanguageOption((prev) => !prev);
                        }}
                        className="py-2 px-4 font-medium text-primaryTwo shadow"
                      >
                        English (EN)
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="flex flex-wrap w-full">
              <div className="w-full lg:w-12/12">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Image</label>
                  <input
                    name="notification_image"
                    type="file"
                    className={`border-0 px-3 py-3 placeholder-slate-300 ${
                      allowEdit ? "text-slate-600" : "text-gray-400"
                    } bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                    disabled={!allowEdit}
                    onChange={onFileChange}
                    accept="image/png, image/jpeg"
                  />
                  <p className="text-xs text-gray-400 mt-2">PNG, JPG up to 2MB</p>
                </div>
              </div>

              <div className="w-full lg:w-12/12">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Subject</label>
                  <input
                    name="notification_title"
                    type="text"
                    className={`border-0 px-3 py-3 placeholder-slate-300 ${
                      allowEdit ? "text-slate-600" : "text-gray-400"
                    } bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                    placeholder={defaultTitle[values.notification_lang]}
                    value={values.notification_title ? values.notification_title : ""}
                    disabled={!allowEdit}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="w-full lg:w-12/12">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Body</label>
                  <textarea
                    name="notification_body"
                    rows={5}
                    className={`border-0 px-3 py-3 placeholder-slate-300 ${
                      allowEdit ? "text-slate-600" : "text-gray-400"
                    } bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                    placeholder={defaultBody[values.notification_lang]}
                    value={values.notification_body ? values.notification_body : ""}
                    disabled={!allowEdit}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              <div className="w-full lg:w-12/12">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Action</label>
                  <input
                    name="notification_action"
                    type="text"
                    className={`border-0 px-3 py-3 placeholder-slate-300 ${
                      allowEdit ? "text-slate-600" : "text-gray-400"
                    } bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                    placeholder={defaultAction[values.notification_lang]}
                    value={values.notification_action ? values.notification_action : ""}
                    disabled={!allowEdit}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            {allowEdit ? (
              <button
                className="flex bg-primary text-white font-bold uppercase text-xs px-4 py-2 rounded shadow"
                type="submit"
                onClick={(e) => onSubmit(e)}
              >
                {loading && <Spinner size={4} />}Save
              </button>
            ) : (
              <div
                className="flex bg-sky-500 w-fit cursor-pointer text-white font-bold uppercase text-xs px-4 py-2 rounded shadow"
                onClick={() => setAllowEdit(true)}
              >
                Edit
              </div>
            )}
          </form>
        </div>
      </div>

      <div className="w-full md:w-2/5 px-4">
        <Preview data={preview} />
      </div>
    </div>
  );
}
