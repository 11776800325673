import { useEffect, useState } from "react";
import compress from "lib/compress";
import api from "lib/api";
import { useParams } from "react-router";
import { useError } from "contexts/error";
import Spinner from "components/Spinner";

export default function Information({ packages }) {
  const [tags, setTags] = useState([]);
  const [values, setValues] = useState({
    name: "",
    image_url: "",
    image: "",
    position: 0,
    tag_id: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const { handleError, handleSuccess } = useError();
  const { id } = useParams();

  let handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  let onFileChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files[0] });
  };

  let onSubmit = async (e) => {
    e.preventDefault();

    const compressedImage = values.image ? await compress(values.image) : null;
    setLoading(true);
    const { err } = await api.controllers.updatePackage(id, {
      name: values.name,
      tag_id: Number(values.tag_id),
      image: compressedImage,
      description: values.description,
      position: values.position,
    });
    setLoading(false);
    if (err) {
      handleError(err);
    } else {
      handleSuccess("Information updated");
      window.location && window.location.reload();
    }
  };

  useEffect(() => {
    const { name, image_url, tag_id, description, position } = packages;
    if (name) {
      setValues((values) => ({ ...values, name }));
    }
    if (image_url) {
      setValues((values) => ({ ...values, image_url }));
    }
    if (tag_id) {
      setValues((values) => ({ ...values, tag_id }));
    }
    if (description) {
      setValues((values) => ({ ...values, description }));
    }
    if (position) {
      setValues((values) => ({ ...values, position }));
    }
  }, [packages]);

  useEffect(() => {
    const get = async () => {
      const { data, err } = await api.controllers.getTags(1, -1);
      if (!err) {
        setTags(data.data.filter((tag) => tag.type === "package"));
      }
    };
    get();
  }, []);

  return (
    <>
      <form onSubmit={onSubmit} className="flex">
        <div className="w-full lg:w-1/2 bg-gray-100 rounded-lg p-4">
          <div className="w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
              Name{" "}
              <span className="text-red-500">
                <sup>*</sup>
              </span>
            </label>
            <input
              name="name"
              type="text"
              className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full placeholder:text-gray-300"
              placeholder="package-1"
              value={values.name}
              onChange={handleChange}
            />
          </div>

          <div className="flex gap-4">
            <div className="relative w-full mb-3">
              <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                Category{" "}
                <span className="text-red-500">
                  <sup>*</sup>
                </span>
              </label>
              {tags.length > 0 && values.tag_id && (
                <select
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  name="tag_id"
                  onChange={handleChange}
                  defaultValue={values.tag_id}
                  values={values.tag_id}
                >
                  {tags
                    .filter((tag) => tag.type === "package")
                    .map((tag, index) => (
                      <option key={`tag - ${index}`} value={tag.id}>
                        {tag.name}
                      </option>
                    ))}
                </select>
              )}
            </div>
            <div className="relative w-full mb-3">
              <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                Position{" "}
                <span className="text-red-500">
                  <sup>*</sup>
                </span>
              </label>
              <input
                name="position"
                type="number"
                min={0}
                className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full placeholder:text-gray-300"
                placeholder="123"
                value={values.position}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="relative w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
              Description{" "}
              <span className="text-red-500">
                <sup>*</sup>
              </span>
            </label>
            <textarea
              name="description"
              className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder="Deskripsi"
              value={values.description}
              onChange={handleChange}
              rows={8}
            ></textarea>
          </div>

          <div className="w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2">image</label>
            <input
              name="image"
              type="file"
              className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
              onChange={onFileChange}
              accept="image/png, image/jpeg"
            />
            <p className="text-xs text-gray-400 mt-2">PNG, JPG up to 2MB</p>

            {values.image === "" && values.image_url && (
              <img src={values.image_url} alt="package-cover" className={"h-24 aspect-square"} />
            )}
            {values.image && (
              <img
                src={values.image instanceof File ? URL.createObjectURL(values.image) : values.image}
                alt="category-icon"
                className="w-6/12 h-fit rounded-sm bg-white border border-gray-400"
              />
            )}
          </div>

          <div className="w-full mb-3">
            <button
              className="flex justify-center bg-sky-500 text-white font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md"
              type="submit"
            >
              {loading && <Spinner size={4} />} Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
