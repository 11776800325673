import React, { useEffect, useRef, useState } from "react";
// import axios from "axios";

import api from "lib/api";
import { useHistory } from "react-router";
import Spinner from "components/Spinner";
import compress from "lib/compress";
import { useError } from "contexts/error";
import ProductVariations from "./ProductVariations";
import ProductBulkPrice from "./ProductBulkPrice";
import ProductTags from "./ProductTags";
import Modal from "components/Modal/Modal";

export default function AddProduct() {
  const [values, setValues] = useState({
    name: "",
    client_id: "",
    sku: "",
    brand_id: "",
    category_id: "",
    provider: "iak",
    provider_product_code: "",
    description: "",
    internal_notes: "",
    images: [],
    weight: 0,
    min_quantity: 1,
    custom_design: false,
    personal: false,
    merchandise: false,
    digital: false,
    external_url: "",
    quantity: -1,
    purchase_price: 0,
    margin: 0,
    additional_margin: 0,
    price: 0,
    video_url: "",
    production_time: "",
    discount: 0,
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const imageRef = useRef(null);
  const { handleError } = useError();
  const [showModal, setShowModal] = useState(false);

  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [variations, setVariations] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [tags, setTags] = useState([]);
  const [clients, setClients] = useState([]);

  // for shopee
  const [shopeeLink, setShopeeLink] = useState("");
  const [shopeeloading, setShopeeLoading] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [externalUrl, setExternalUrl] = useState(false);
  const [shopeeVariation, setShopeeVariation] = useState(null);
  const [shopeeVariationPrice, setShopeeVariationPrice] = useState(null);

  const getBrands = async () => {
    const { data, err } = await api.controllers.getBrands({
      page: 1,
      limit: -1,
    });
    if (!err) {
      setBrands(data.data);
    }
  };

  let getClients = async () => {
    const { data, err } = await api.controllers.getClients(1, -1);
    if (!err) {
      setClients(data.data);
    }
  };

  let getCategories = async () => {
    const { data, err } = await api.controllers.getCategories({
      page: 1,
      limit: -1,
      parent: 0,
    });
    if (!err) {
      setCategories(data.data);
    }
  };

  useEffect(() => {
    getClients();
    getCategories();
    getBrands();
  }, []);

  useEffect(() => {
    let number = (values.purchase_price + values.additional_margin) / (1 - values.margin / 100);

    let decimal = (number / 1000).toFixed(3);
    let round = parseInt(Math.ceil(decimal).toString() + "000");

    setValues((values) => ({
      ...values,
      price: round,
    }));
  }, [values.additional_margin, values.margin, values.purchase_price]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleNumberChange = (e) => {
    setValues({ ...values, [e.target.name]: parseInt(e.target.value) });
  };
  const onFileChange = (e) => {
    // [...values.images, ...e.target.files]
    setValues({
      ...values,
      [e.target.name]: [...values.images, ...e.target.files],
    });
  };

  const onCheckboxClicked = (e) => {
    setValues({ ...values, [e.target.name]: !values[e.target.name] });
  };

  // const onImageClick = () => {
  //   imageRef && imageRef.current.click();
  // };

  const onVariationsChange = (_variations) => {
    setVariations(_variations);
  };
  const onDiscountsChange = (discounts) => {
    setDiscounts(discounts);
  };
  const onTagsChange = (_tags) => {
    setTags(_tags);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let compressedImages = [];
    for (let i = 0; i < values.images.length && i < 5; i++) {
      compressedImages.push(await compress(values.images[i]));
    }

    const payload = {
      client_id: values.client_id,
      name: values.name,
      sku: values.sku,
      description: values.description,
      brand_id: values.brand_id,
      category_id: values.category_id,
      images: compressedImages,
      price: values.price,
      weight: values.weight,
      min_quantity: values.min_quantity,
      production_time: values.production_time,
      ...(values.digital ? { provider: values.provider, provider_product_code: values.provider_product_code } : {}),
      custom_design: values.custom_design,
      digital: values.digital,
      personal: values.personal,
      merchandise: values.merchandise,
      variations: variations,
      tags: tags,
      external_url: values.external_url,
      quantity: values.quantity,
      purchase_price: values.purchase_price,
      margin: values.margin,
      additional_margin: values.additional_margin,
      internal_notes: values.internal_notes,
      video_url: values.video_url,
      discount: values.discount,
    };

    // if (payload.images.length > 5) {
    //   handleError({ message: "Maximum images uploaded is 5" });
    //   setLoading(false);
    // } else {

    const { data, err } = await api.controllers.createProduct(payload);

    if (err) {
      handleError(err);
      setLoading(false);
    } else if (data) {
      if (discounts.length > 0) {
        const { err } = await api.controllers.updateBulkPrice(data.data.id, { prices: discounts });
        if (values.images.length <= 5) {
          setLoading(false);
        }

        if (err) {
          handleError(err);
        } else {
          if (values.images.length <= 5) {
            setLoading(false);
            history.push("/products");
          }
        }
      }
      if (values.images.length > 5) {
        for (let i = 5; i < values.images.length; i++) {
          let compressedImages = await compress(values.images[i]);
          // const { err } =
          await api.controllers.addProductImage(data.data.id, {
            image: compressedImages,
          });
        }
        setLoading(false);
        history.push("/products");
      } else {
        setLoading(false);
        history.push("/products");
      }
      // }
    }
  };

  const handleImageDownload = async (urls) => {
    try {
      const fileList = [];
      for (const url of urls) {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], `${Math.random().toString(36).substring(2)}.jpg`, { type: "image/jpeg" });
        fileList.push(file);
      }
      setImageData(fileList);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (imageData) {
      setValues((values) => ({
        ...values,
        images: imageData,
      }));
    }
  }, [imageData]);

  const shopeeCrawl = async (e) => {
    e.preventDefault();
    setShopeeVariationPrice(null);
    // const shoppeUrl = new URL(shopeeLink);
    // const shoppeUrlId = shoppeUrl.pathname;
    // let resultUrl = shoppeUrlId.replace(/^\/|\/$/g, "");

    setShopeeLoading(true);
    const { data, err } = await api.controllers.getShopee({ url: shopeeLink });

    setShopeeLoading(false);
    if (err) {
      handleError({
        message: "An error occurred. Please make sure the link is valid & try again later.",
      });
    } else {
      setExternalUrl(true);
      handleImageDownload(data.data.images.map((image) => image.image_url));
      setValues({
        ...values,
        name: data.data.name,
        description: data.data.description,
        purchase_price: data.data.purchase_price,
        // min_quantity: data.data.min_purchase_limit,
        external_url: shopeeLink,
        quantity: data.data.quantity,
      });
      if (data.data.variations && data.data.variations.length > 0) {
        setShopeeVariation(data.data.variations);
      } else {
        setShopeeVariation(null);
      }
      setShowModal((prev) => !prev);
    }
  };

  const handleLinkChange = (e) => {
    setShopeeLink(e.target.value);
  };

  const onDeleteClicked = async (index) => {
    const updatedImages = [...values.images];
    updatedImages.splice(index, 1);
    setValues({ ...values, images: updatedImages });
  };

  useEffect(() => {
    setValues((values) => ({ ...values, provider: values.digital ? "iak" : null }));
  }, [values.digital]);

  const modalBody = (
    <form className="flex flex-col md:flex-row md:items-center gap-5" onSubmit={shopeeCrawl}>
      <label className="uppercase text-slate-600 text-xs font-bold flex">
        Link{" "}
        <span className="text-red-500">
          <sup>*</sup>
        </span>
      </label>
      <input
        name="name"
        type="text"
        className="border-0 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        placeholder="shopee.co.id/xxx"
        value={shopeeLink}
        onChange={handleLinkChange}
      />

      <button
        className="flex bg-sky-500 items-center justify-center text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="submit"
        disabled={shopeeloading}
      >
        {shopeeloading && <Spinner size={4} />} Submit
      </button>
    </form>
  );

  return (
    <div className="flex flex-wrap">
      {showModal && (
        <Modal
          title={`Import product from Shopee`}
          widthClass={`w-11/12 md:w-2/3`}
          body={modalBody}
          onClose={() => setShowModal((prev) => !prev)}
        />
      )}

      <div className="relative flex flex-col min-w-0 break-words w-full md:w-3/5 mb-6 shadow-lg h-fit rounded-lg bg-slate-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-slate-700 text-xl font-bold">Add Gift</h6>

            <div className="flex gap-2">
              <button
                className="flex items-center bg-white font-bold uppercase text-xs px-4 py-1 rounded shadow border hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit"
                disabled={loading}
                onClick={() => {
                  setShowModal((prev) => !prev);
                }}
              >
                <img alt="..." src={require("assets/img/shopee-logo-1.png").default} className="w-5 h-5 mr-2" />
                Import
              </button>

              <button
                className="flex bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit"
                // disabled={loading}
                onClick={(e) => onSubmit(e)}
              >
                {loading && <Spinner size={4} />} Save
              </button>
            </div>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={onSubmit}>
            <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">Gift Information</h6>
            <div className="flex flex-wrap w-full">
              <div className="w-full md:w-1/2 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Name{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="name"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Lanyard"
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="w-full md:w-1/2 px-4">
                <label className="block uppercase text-xs font-bold mb-2">Client</label>
                <select
                  name="client_id"
                  className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                  onChange={handleChange}
                >
                  <option value="">All Clients</option>
                  {clients.map((client) => (
                    <option key={client.id} value={client.id}>
                      {client.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full md:w-1/3 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    SKU{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="sku"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="ABC123"
                    value={values.sku}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-full md:w-1/3 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Category{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <select
                    name="category_id"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChange}
                  >
                    <option value="">Choose Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.parent_name} &gt; {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="w-full md:w-1/3 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Brand</label>
                  <select
                    name="brand_id"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChange}
                    value={values.brand_id ? values.brand_id : ""}
                  >
                    <option value="">Select Brand</option>
                    {brands.map((brand) => (
                      <option key={`brands-${brand.id}`} value={brand.id}>
                        {brand.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="w-full grid grid-cols-3 gap-4 mt-3 mb-5">
                <div className={`w-full px-4 flex items-center`}>
                  <div className="flex relative items-center mb-1">
                    <input
                      name="digital"
                      type="checkbox"
                      className="border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2"
                      checked={values.digital || values.digital === "true" ? true : false}
                      onChange={onCheckboxClicked}
                    />
                    <label className="block uppercase text-slate-600 text-xs font-bold">Digital?</label>
                  </div>
                </div>

                {(values.digital || values.digital === "true") && (
                  <div className="w-full px-4">
                    <label className="block uppercase text-xs font-bold mb-2">Provider</label>
                    <select
                      name="provider"
                      className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"
                      onChange={handleChange}
                    >
                      <option value="iak">IAK</option>
                    </select>
                  </div>
                )}

                {(values.digital || values.digital === "true") && (
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Product Code{" "}
                        <span className="text-red-500">
                          <sup>*</sup>
                        </span>
                      </label>
                      <input
                        name="provider_product_code"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="xxx"
                        value={values.provider_product_code}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Description{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <textarea
                    name="description"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Deskripsi"
                    value={values.description}
                    onChange={handleChange}
                    rows={8}
                  ></textarea>
                </div>
              </div>
              <div className="w-1/2 md:w-1/4 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Purchase (Rp){" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="purchase_price"
                    type="number"
                    min={0}
                    className={`border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                    placeholder="10000"
                    value={values.purchase_price}
                    onChange={handleNumberChange}
                  />
                </div>
              </div>
              <div className="w-1/2 md:w-1/4 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Additional (Rp){" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="additional_margin"
                    type="number"
                    min={0}
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="10000"
                    value={values.additional_margin}
                    onChange={handleNumberChange}
                  />
                </div>
              </div>
              <div className="w-1/2 md:w-1/4 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Margin (%){" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="margin"
                    type="number"
                    min={0}
                    max={100}
                    maxLength={300}
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="%"
                    value={values.margin}
                    onChange={handleNumberChange}
                  />
                </div>
              </div>

              <div className="w-1/2 md:w-1/4 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Price (total)</label>
                  <div className="border-0 select-none px-3 py-3 placeholder-slate-300 text-slate-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      maximumFractionDigits: 0,
                    }).format(values.price)}
                  </div>
                </div>
              </div>

              <div className="w-1/2 md:w-1/2 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Discount (RP)
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="discount"
                    type="number"
                    min={0}
                    className={`border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                    placeholder="10000"
                    value={values.discount}
                    onChange={handleNumberChange}
                  />
                </div>
              </div>

              <div className="w-1/2 md:w-1/2 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Discounted Price
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <div className="border-0 select-none px-3 py-3 placeholder-slate-300 text-slate-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      maximumFractionDigits: 0,
                    }).format(values.discount > 0 ? values.price - values.discount : 0)}
                  </div>
                </div>
              </div>

              <div className="w-1/3 md:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Weight (gram){" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="weight"
                    type="number"
                    min={0}
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="500"
                    value={values.weight}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-1/3 md:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    quantity{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="quantity"
                    type="number"
                    min={0}
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="500"
                    value={values.quantity}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-1/3 md:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Min order{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="min_quantity"
                    type="number"
                    min={0}
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="10000"
                    value={values.min_quantity}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-1/2 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Tags{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>

                  <ProductTags defaultTags={[]} onChange={onTagsChange} />
                </div>
              </div>

              <div className="w-1/2 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Production Time</label>
                  <select
                    name="production_time"
                    className="border-0 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChange}
                    value={values.production_time ? values.production_time : ""}
                  >
                    <option value="">Not specified</option>
                    <option value="below_7">Below 7 days</option>
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4 mb-3">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Images (Min 1)
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    ref={imageRef}
                    name="images"
                    type="file"
                    multiple={true}
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={onFileChange}
                    accept="image/png, image/jpeg"
                  />
                  <p className="text-xs text-gray-400 mt-2">PNG, JPG up to 2MB</p>
                </div>

                {values.images && values.images.length > 0 && (
                  <>
                    <div className={`grid grid-cols-5 gap-4 mb-4`}>
                      {Array.from(values.images).map((image, index) => (
                        <div className={"mr-2 relative border"} key={`images-${index}`}>
                          <span
                            className="absolute -top-3 -right-2 cursor-pointer"
                            onClick={() => onDeleteClicked(index)}
                          >
                            <i className="text-black text-xl fas fa-times-circle"></i>
                          </span>

                          <img
                            src={URL.createObjectURL(image)}
                            alt=""
                            className={"h-fit aspect-square cursor-pointer"}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>

              {/* {externalUrl && ()} */}
              <div className="w-full px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    External URL{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="external_url"
                    type="text"
                    className={`border-0 px-3 py-3 placeholder-slate-300 text-slate-600 ${
                      externalUrl ? "bg-gray-100" : "bg-white"
                    } rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                    placeholder="shopee url"
                    onChange={handleChange}
                    value={values.external_url}
                    readOnly={externalUrl ? true : false}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3">
                <div className="w-full px-4 mb-3">
                  <div className="flex relative items-center mb-1">
                    <input
                      name="custom_design"
                      type="checkbox"
                      className="border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2"
                      checked={values.custom_design || values.custom_design === "true" ? true : false}
                      onChange={onCheckboxClicked}
                    />
                    <label className="block uppercase text-slate-600 text-xs font-bold">Custom Design?</label>
                  </div>
                  <span className="text-xs text-gray-400">
                    Checking this option will allow client to upload custom design for branded campaign.
                  </span>
                </div>

                <div className="w-full px-4 mb-3">
                  <div className="flex relative items-center mb-1">
                    <input
                      name="merchandise"
                      type="checkbox"
                      className="border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2"
                      checked={values.merchandise || values.merchandise === "true" ? true : false}
                      onChange={onCheckboxClicked}
                    />
                    <label className="block uppercase text-slate-600 text-xs font-bold">Merchandise?</label>
                  </div>
                  <span className="text-xs text-gray-400">
                    Checking this option will allow this product to be chosen as Merchandide.
                  </span>
                </div>

                <div className="w-full px-4 mb-3">
                  <div className="flex relative items-center mb-1">
                    <input
                      name="personal"
                      type="checkbox"
                      className="border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2"
                      checked={values.personal || values.personal === "true" ? true : false}
                      onChange={onCheckboxClicked}
                    />
                    <label className="block uppercase text-slate-600 text-xs font-bold">Smart Gift?</label>
                  </div>
                  <span className="text-xs text-gray-400">
                    Checking this option will allow this product to be chosen as Smart gift.
                  </span>
                </div>
              </div>

              <div className="w-full px-4 mt-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Video URL</label>
                  <input
                    name="video_url"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="https://www.youtube.com/embed/xxx"
                    value={values.video_url}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4 mt-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Internal Notes</label>
                  <textarea
                    name="internal_notes"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Notes"
                    value={values.internal_notes}
                    onChange={handleChange}
                    rows={8}
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="w-full md:w-2/5 px-4">
        <ProductVariations
          onVariationsChange={onVariationsChange}
          shopeeVariation={shopeeVariation}
          shopeeVariationPrice={shopeeVariationPrice}
        />
        <ProductBulkPrice onDiscountsChange={onDiscountsChange} />
      </div>
    </div>
  );
}
