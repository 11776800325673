import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const MenuDropdown = ({ menu }) => {
  let pathName = window.location.pathname;
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const dropdownPopover = () => {
    setDropdownPopoverShow((prev) => !prev);
  };

  const submenusRef = useRef();

  useEffect(() => {
    const closeSubMenu = (e) => {
      if (!submenusRef?.current?.contains(e.target)) {
        setDropdownPopoverShow(false);
      }
    };

    document.body.addEventListener("click", closeSubMenu);

    return () => {
      document.body.removeEventListener("click", closeSubMenu);
    };
  }, [submenusRef]);

  return (
    <>
      <Link
        className={
          "flex items-center text-xs uppercase py-3 font-bold " +
          (pathName === menu.path ? "text-primary" : "text-slate-700 hover:text-primary")
        }
        to={"#"}
        ref={submenusRef}
        onClick={() => {
          dropdownPopover();
        }}
      >
        <i className={`fas fa-${menu.icon} mr-4 text-sm`}></i> {menu.label}
      </Link>

      <div
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 absolute"
        }
      >
        {menu.submenus &&
          menu.submenus.map((submenu, index) => (
            <Link
              key={`submenu-${index}`}
              to={submenu.path}
              onClick={() => setDropdownPopoverShow(false)}
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700 hover:text-primary"
              }
            >
              {submenu.label}
            </Link>
          ))}
      </div>
    </>
  );
};

export default MenuDropdown;
