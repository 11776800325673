import Table from "components/Table/Table";
import api from "lib/api";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddProposal from "./AddProposal";

export default function ProposalList() {
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);
  const [filterValues, setFilterValues] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const fetchProposals = useCallback(async () => {
    const { data, err } = await api.controllers.getProposals(pagination.page, pagination.limit, filterValues);
    if (!err) {
      setData(data);
    }
  }, [filterValues, pagination]);

  useEffect(() => {
    pagination && fetchProposals();
  }, [pagination, fetchProposals]);

  useEffect(() => {
    filterValues && fetchProposals();
  }, [filterValues, fetchProposals]);

  let onFilter = async (_values) => {
    setFilterValues(_values);
  };

  let onClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Table
            settings={{
              title: "Proposals",
              button: {
                label: "Add",
                onClick: () => {
                  setShowModal(true);
                },
              },
              headers: [
                {
                  label: "Name",
                  render: (element) => {
                    return (
                      <Link to={`/proposals/${element.id}`} className={"underline"}>
                        {element.name}
                      </Link>
                    );
                  },
                },
                {
                  label: "Client Name",
                  field: "client_name",
                },
                {
                  label: "Created",
                  render: (element) => {
                    return new Date(element.created_at).toLocaleDateString("id-ID");
                  },
                },
              ],
              filters: [
                {
                  label: "Name",
                  field: "name",
                },
                {
                  label: "Client",
                  field: "client_name",
                },
              ],
            }}
            data={data}
            setPagination={setPagination}
            onFilter={onFilter}
          />
        </div>
      </div>

      {showModal && <AddProposal onClose={onClose} />}
    </>
  );
}
