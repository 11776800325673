import React, { createContext, useContext } from 'react'
import toast, { Toaster } from 'react-hot-toast'

const ErrorContext = createContext({})

export const ErrorProvider = ({ children }) => {

    const handleError = err => {
        err && toast.error(err.message)
    }
    const handleSuccess = msg => {
        msg && toast.success(msg)
    }

    return (
        <ErrorContext.Provider value={{handleError, handleSuccess}}>
            <Toaster />

            {children}
        </ErrorContext.Provider>
    )
}

export const useError = () => useContext(ErrorContext)