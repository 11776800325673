import React, { useEffect, useRef, useState } from "react";

import api from "lib/api";
import { useHistory, useParams } from "react-router";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import compress from "../../../lib/compress";

export default function EditTag() {
  const [tag, setTag] = useState(null);
  const [values, setValues] = useState({ name: "", position: 1, active: false, icon: null, image: null });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const { handleError } = useError();
  const imageRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const get = async () => {
      const { data, err } = await api.controllers.getTag(id);
      if (!err) {
        const _tag = data.data;

        setTag(_tag);
        setValues({
          name: _tag.name,
          active: _tag.active,
          icon: _tag.icon_url,
          image: _tag.image_url,
          position: _tag.position,
        });
      }
    };
    get();
  }, [id]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const onCheckboxClicked = (e) => {
    setValues({ ...values, [e.target.name]: !values[e.target.name] });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let value = {
      name: values.name,
      active: values.active ? 1 : 0,
      position: values.position,
      icon: values.icon instanceof File ? await compress(values.icon) : values.icon,
      image: values.image_url instanceof File ? await compress(values.image_url) : values.image,
    };

    const { err } = await api.controllers.updateTag(id, value);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      history.push("/tags");
    }
  };

  const onIconClick = () => {
    iconRef && iconRef.current.click();
  };

  const onImageClick = () => {
    imageRef && imageRef.current.click();
  };

  const onFileChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files[0] });
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-slate-700 text-xl font-bold">Edit {tag && tag.name}</h6>
            <button
              className="flex bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              onClick={(e) => onSubmit(e)}
            >
              {loading && <Spinner size={4} />} Save
            </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={onSubmit}>
            <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">Tag Information</h6>
            <div className="flex flex-wrap w-full lg:w-6/12">
              <div className="flex gap-4 px-4 w-full">
                <div className="w-full">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                      Name{" "}
                      <span className="text-red-500">
                        <sup>*</sup>
                      </span>
                    </label>
                    <input
                      name="name"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Birthday"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="relative w-32 mb-3">
                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                    Position{" "}
                    <span className="text-red-500">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    name="position"
                    type="number"
                    min={1}
                    className="border-0 px-3 py-3 bg-white rounded text-sm shadow w-full placeholder:text-gray-300"
                    placeholder="123"
                    value={values.position}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4 flex items-center gap-5 mb-3">
                <label className="flex uppercase text-slate-600 text-xs font-bold">
                  Icon{" "}
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <div
                  className={`text-center ${
                    values.icon ? "" : "bg-gray-200"
                  } w-full cursor-pointer rounded-lg flex items-center`}
                  onClick={onIconClick}
                >
                  {values.icon && (
                    <img
                      src={values.icon instanceof File ? URL.createObjectURL(values.icon) : values.icon}
                      alt="tag-icon"
                      className="w-6/12 aspect-square my-5 object-contain rounded-sm bg-white border border-gray-400"
                    />
                  )}
                  {!values.icon && (
                    <>
                      <span className="mx-auto text-gray-400 text-4xl px-4">+</span>
                    </>
                  )}

                  <input
                    ref={iconRef}
                    type="file"
                    name="icon"
                    onChange={onFileChange}
                    className={"invisible"}
                    accept="image/png, image/jpeg"
                    style={{ width: "1px" }}
                  />
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4 flex items-center gap-5 mb-3">
                <label className="flex uppercase text-slate-600 text-xs font-bold">
                  Image
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <div
                  className={`text-center ${
                    values.image ? "" : "bg-gray-200"
                  } w-full cursor-pointer rounded-lg flex items-center`}
                  onClick={onImageClick}
                >
                  {values.image && (
                    <img
                      src={values.image instanceof File ? URL.createObjectURL(values.image) : values.image}
                      alt="tag-image_url"
                      className="w-6/12 aspect-square my-5 object-contain rounded-sm bg-white border border-gray-400"
                    />
                  )}
                  {!values.image && (
                    <>
                      <span className="mx-auto text-gray-400 text-4xl px-4">+</span>
                    </>
                  )}

                  <input
                    ref={imageRef}
                    type="file"
                    name="image"
                    onChange={onFileChange}
                    className={"invisible"}
                    accept="image/png, image/jpeg"
                    style={{ width: "1px" }}
                  />
                </div>
              </div>

              <div className="px-4">
                <div className="flex relative mb-3 items-center">
                  <input
                    name="active"
                    type="checkbox"
                    className="border-0 mr-3 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    checked={values.active || values.active === "true" ? true : false}
                    onChange={onCheckboxClicked}
                  />
                  <label className="block uppercase text-slate-600 text-xs font-bold">Active</label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
