import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Table from "components/Table/Table.js";
import api from "lib/api";
import TableDropdown from "components/Dropdowns/TableDropdown";
import { useError } from "contexts/error";
import OrderDuplicate from "./OrderDuplicate";
import OrderStats from "./OrderStats";
import OrderListRecipients from "./OrderListRecipients";
import OrderNew from "./OrderNew";
import Reviews from "components/Reviews/Reviews";

export default function OrderList() {
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);
  const [reviews, setReviews] = useState([]);
  const { handleError, handleSuccess } = useError();
  const [duplicateOrder, setDuplicateOrder] = useState(null);
  const [filterValues, setFilterValues] = useState(null);
  const [currentPage, setCurrentPage] = useState("campaign");
  const [showModalNewOrder, setShowModalNewOrder] = useState(false);

  const fetchOrders = useCallback(async () => {
    const { data, err } = await api.controllers.getOrders(pagination.page, pagination.limit, filterValues);
    if (!err) {
      setData(data);
    }
  }, [filterValues, pagination]);

  useEffect(() => {
    pagination && fetchOrders();
  }, [pagination, fetchOrders]);

  let onFilter = async (_values) => {
    setFilterValues(_values);
  };

  let getReviews = async () => {
    const { data, err } = await api.controllers.getOrderReviews(1, 2);
    if (!err) {
      setReviews(data.data);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  const statusMap = {
    waiting_payment: "Waiting Payment",
    draft: "Draft",
    processing: "Processing",
    completed: "Completed",
    expired: "Expired",
  };

  let onDelete = async (order) => {
    if (window.confirm(`Are you sure you want to delete campaign ${order.name}?`)) {
      const { err } = await api.controllers.deleteOrder(order.id);

      if (err) {
        handleError(err);
      } else {
        handleSuccess(`Order ${order.name} deleted`);
        fetchOrders();
      }
    }
  };

  return (
    <>
      {showModalNewOrder && (
        <OrderNew
          onClose={() => {
            setShowModalNewOrder((prev) => !prev);
          }}
        />
      )}

      <div className="mb-4">
        <div className="w-full mb-4">
          <OrderStats />
        </div>

        <div className="w-full mb-12 px-4">
          <div className="w-full flex shadow rounded-t-md">
            <div
              className={`w-1/2 text-center rounded-t-lg py-3 font-bold cursor-pointer ${
                currentPage === "campaign" ? "bg-white" : "bg-gray-200"
              }`}
              onClick={() => setCurrentPage("campaign")}
            >
              Campaign
            </div>
            <div
              className={`w-1/2 text-center rounded-t-lg py-3 font-bold cursor-pointer ${
                currentPage === "recipient" ? "bg-white" : "bg-gray-200"
              }`}
              onClick={() => setCurrentPage("recipient")}
            >
              Recipients
            </div>
          </div>

          {currentPage === "campaign" ? (
            /* campaigns  */
            <Table
              settings={{
                title: null,
                button: {
                  label: "Add",
                  onClick: () => {
                    setShowModalNewOrder((prev) => !prev);
                  },
                },
                headers: [
                  {
                    label: "Client",
                    render: (element) => {
                      return element.customer_name || element.client_name;
                    },
                  },
                  {
                    label: "Name",
                    render: (element) => {
                      return (
                        <div className="flex gap-2 items-center">
                          <p>{element.name}</p>
                          {element.recurring ? (
                            <p className="w-fit text-white bg-gray-400 px-2 pt-[2px] py-1 font-semibold rounded-md">
                              recurring
                            </p>
                          ) : element.free ? (
                            <p className="w-fit text-white bg-primary px-2 pt-[2px] py-1 font-semibold rounded-md">
                              Free
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    label: "Date",
                    render: (element) => {
                      return new Date(element.created_at).toLocaleDateString("id-ID");
                    },
                  },
                  {
                    label: "Status",
                    render: (element) => {
                      return statusMap[element.status];
                    },
                  },
                  {
                    label: "",
                    render: (element) => {
                      let unclaimed = element.recipient_count - element.address_completed_count;
                      let processing = element.address_completed_count - element.delivering_count;
                      let delivering = element.delivering_count - element.delivered_count;
                      let delivered = element.delivered_count;

                      return (
                        <div className="flex gap-x-4">
                          {element.status === "draft" && (
                            <div className="border border-gray-400 p-2 rounded-lg">
                              {element.recipient_count} recipient
                            </div>
                          )}
                          {element.status !== "draft" && (
                            <>
                              {unclaimed > 0 && (
                                <div className="border border-gray-400 p-2 rounded-lg">{unclaimed} unclaimed</div>
                              )}

                              {processing > 0 && (
                                <div className="border border-primaryTwo p-2 rounded-lg">{processing} processing</div>
                              )}

                              {delivering > 0 && (
                                <div className="border border-primaryTwo p-2 rounded-lg">{delivering} delivering</div>
                              )}

                              {delivered > 0 && (
                                <div className="border border-primaryTwo p-2 rounded-lg">{delivered} delivered</div>
                              )}
                            </>
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    label: "",
                    render: (element) => {
                      if (element.status === "draft") {
                        return (
                          <Link to={`/orders/edit/${element.id}`} className={"underline"}>
                            Continue
                          </Link>
                        );
                      }

                      return (
                        <Link to={`/orders/${element.id}`} className={"underline"}>
                          Detail
                        </Link>
                      );
                    },
                  },
                  {
                    label: "",
                    render: (element) => {
                      let items = [
                        {
                          label: "Duplicate",
                          onClick: (e) => setDuplicateOrder(element),
                        },
                      ];
                      if (element.status === "draft") {
                        items.push({
                          label: "Delete",
                          onClick: (e) => onDelete(element),
                        });
                      }

                      return <TableDropdown items={items} />;
                    },
                  },
                ],
                filters: [
                  {
                    label: "Client",
                    field: "client_name",
                  },
                  {
                    label: "Campaign Name",
                    field: "name",
                  },
                  {
                    label: "Status",
                    field: "status",
                    inputType: "select",
                    options: [
                      {
                        label: "Draft",
                        value: "draft",
                      },
                      {
                        label: "Processing",
                        value: "processing",
                      },
                      {
                        label: "Completed",
                        value: "completed",
                      },
                      {
                        label: "Expired",
                        value: "expired",
                      },
                    ],
                  },
                  {
                    label: "Type",
                    field: "type",
                    inputType: "select",
                    options: [
                      {
                        label: "Branded",
                        value: "branded",
                      },
                      {
                        label: "Smart Gift",
                        value: "personal",
                      },
                    ],
                  },
                ],
              }}
              data={data}
              setPagination={setPagination}
              onFilter={onFilter}
            />
          ) : (
            /* recepient  */
            <OrderListRecipients />
          )}
        </div>

        {/* reviews.length > 0 */}
        <div className="w-full px-4 mb-8">
          <div className="flex justify-between">
            <h2 className="text-3xl font-bold mb-5">Latest Reviews</h2>
          </div>
          <Reviews reviews={reviews} />

          <div className="flex justify-center mt-8">
            <Link to={`/reviews`}>
              <div className="font-bold py-2 px-4 text-primary bg-white border border-primary rounded-md">
                Show More &gt;&gt;
              </div>
            </Link>
          </div>
        </div>
      </div>

      {duplicateOrder ? (
        <OrderDuplicate
          order={duplicateOrder}
          onSuccess={() => fetchOrders()}
          onClose={() => setDuplicateOrder(null)}
        />
      ) : null}
    </>
  );
}
