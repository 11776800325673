import { useEffect, useState } from "react";

export default function Pagination({ pageCount, itemCount, onPageChange, currentPage, showAll }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const pages = Array(5)
    .fill(page)
    .map((x, idx) => {
      if (page + 4 > pageCount) {
        return x + idx - (page + 4 - pageCount);
      }

      return x + idx;
    })
    .filter((_page) => {
      return _page <= pageCount && _page > 0;
    });

  const startIndex = itemCount > 0 ? (page - 1) * limit + 1 : 0;
  const endIndex = limit === -1 ? itemCount : page * limit < itemCount ? page * limit : itemCount;

  const onNextPage = () => {
    if (page < pageCount) {
      setPage(page + 1);
    }
  };

  const onPrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  useEffect(() => {
    onPageChange && onPageChange(page, limit);
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    currentPage ? setPage(parseInt(currentPage)) : setPage(1);
  }, [pageCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLimitChange = (e) => {
    onPageChange && onPageChange(page, parseInt(e.target.value));
    setLimit(parseInt(e.target.value));
  };

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-5">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          type="button"
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={onPrevPage}
        >
          Previous
        </button>
        <button
          type="button"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={onNextPage}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="w-full pr-2 flex gap-4 items-center">
          <select
            className={`py-2 bg-white text-sm shadow w-fit `}
            name="limit"
            value={limit}
            onChange={handleLimitChange}
          >
            {showAll && <option value="-1">All</option>}
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <p className="">data per page</p>
        </div>

        <div className="flex items-center gap-5 md:min-w-fit">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{startIndex}</span> to <span className="font-medium">{endIndex}</span>{" "}
            of <span className="font-medium">{itemCount}</span> results
          </p>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            {page > 1 && (
              <>
                <button
                  type="button"
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  onClick={() => onPrevPage()}
                >
                  <span className="sr-only">Previous</span>
                  <i className="fas fa-angle-left"></i>
                </button>
              </>
            )}

            {pages.map((pageNumber) => {
              return (
                <button
                  type="button"
                  aria-current="page"
                  key={`page-${pageNumber}`}
                  className={
                    page === pageNumber
                      ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                      : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                  }
                  onClick={() => setPage(pageNumber)}
                >
                  {pageNumber}
                </button>
              );
            })}

            {page + 5 <= pageCount && (
              <>
                <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  ...
                </span>
              </>
            )}

            {page < pageCount && (
              <>
                <button
                  type="button"
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  onClick={() => onNextPage()}
                >
                  <span className="sr-only">Next</span>
                  <i className="fas fa-angle-right"></i>
                </button>
              </>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
}
