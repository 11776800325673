import React, { useCallback, useEffect, useState } from "react";

import Table from "components/Table/Table.js";
import api from "lib/api";
import { Link, useHistory } from "react-router-dom";

export default function ClientList() {
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);
  const [filterValues, setFilterValues] = useState(null);

  const history = useHistory();

  const fetchClients = useCallback(async () => {
    const { data, err } = await api.controllers.getClients(pagination.page, pagination.limit, filterValues);
    if (!err) {
      setData(data);
    }
  }, [filterValues, pagination]);

  useEffect(() => {
    pagination && fetchClients();
  }, [pagination, fetchClients]);

  let onFilter = async (_values) => {
    setFilterValues(_values);
  };

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Table
            settings={{
              title: "Clients",
              button: {
                label: "Add",
                onClick: () => {
                  history.push("/clients/new");
                },
              },
              headers: [
                {
                  label: "Name",
                  render: (element) => {
                    return (
                      <Link to={`/clients/${element.id}`} className={"underline"}>
                        {element.name}
                      </Link>
                    );
                  },
                },
                {
                  label: "Created",
                  render: (element) => {
                    return new Date(element.created_at).toLocaleDateString("id-ID");
                  },
                },
                {
                  label: "",
                  render: (element) => {
                    return (
                      <>
                        <Link to={`/clients/${element.id}/recipients`} className={"underline mr-4"}>
                          Recipients
                        </Link>
                        <Link to={`/clients/${element.id}/users`} className={"underline"}>
                          Users
                        </Link>
                      </>
                    );
                  },
                },
              ],
              filters: [
                {
                  label: "Name",
                  field: "name",
                },
              ],
            }}
            data={data}
            setPagination={setPagination}
            onFilter={onFilter}
          />
        </div>
      </div>
    </>
  );
}
