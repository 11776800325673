import Spinner from "components/Spinner";
import api from "lib/api";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Information from "./Edit/Information";
import Products from "./Edit/Products";
import { useError } from "contexts/error";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function EditPackages() {
  const [data, setData] = useState([]);
  const { handleSuccess } = useError();
  const history = useHistory();
  const { id } = useParams();

  const [step, setStep] = useState("information");

  let fetchData = async (e) => {
    const { err, data } = await api.controllers.getPackage(id);

    if (!err) {
      setData(data.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const deletePackage = async () => {
    if (window.confirm("Are you sure you want to delete this Package?")) {
      const { err } = await api.controllers.deletePackage(id);
      if (!err) {
        handleSuccess("Package successfully deleted");
        history.push("/packages");
      }
    }
  };

  return (
    <>
      {!data && (
        <div className="flex justify-center">
          <Spinner color={"black"} />
        </div>
      )}

      {data && (
        <div className="flex flex-wrap">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-lg bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-xl font-bold">Edit {data.name}</h6>
                <button
                  className="flex bg-gray-500 text-white active:bg-gray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit"
                  onClick={deletePackage}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>

          <div className="relative flex flex-col min-w-0 break-words mb-6 shadow-lg rounded-lg bg-white border-0 w-full">
            <div className="w-full flex justify-between border-b border-gray-200 bg-white rounded-tl-lg rounded-tr-lg">
              <ul className="flex flex-wrap">
                <li className="mr-2">
                  <div
                    onClick={() => setStep("information")}
                    className={`inline-block cursor-pointer ${
                      step === "information" ? "text-sky-500" : "text-gray-500 hover:text-gray-600 hover:bg-gray-50"
                    } rounded-t-lg py-4 px-4 text-sm font-medium text-center`}
                  >
                    Information
                  </div>
                </li>
                <li className="mr-2">
                  <div
                    onClick={() => setStep("products")}
                    className={`inline-block cursor-pointer ${
                      step === "products" ? "text-sky-500" : "text-gray-500 hover:text-gray-600 hover:bg-gray-50"
                    } rounded-t-lg py-4 px-4 text-sm font-medium text-center`}
                  >
                    Products
                  </div>
                </li>
              </ul>
            </div>

            <div className="p-4">
              {data && (
                <>
                  {step === "information" && <Information packages={data} />}
                  {step === "products" && <Products packages={data} id={id} reFetch={fetchData} />}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
