import React, { useEffect, useState } from "react";
import Spinner from "components/Spinner";
import api from "lib/api";
import { Link } from "react-router-dom";
import { useError } from "contexts/error";

let conditionOptions = {
  onboarding: {
    label: "Onboarding",
    field: "onboarding",
    type: "select",
    readonly: true,
    operators: [
      {
        label: "In",
        value: "in",
      },
    ],
    values: [
      {
        label: "1 week",
        value: "1_week",
      },
      {
        label: "2 week",
        value: "2_week",
      },
      {
        label: "1 month",
        value: "1_month",
      },
    ],
  },
  birthday: {
    label: "Birthday",
    field: "birthday",
    type: "select",
    readonly: true,
    operators: [
      {
        label: "In",
        value: "in",
      },
    ],
    values: [
      {
        label: "1 week",
        value: "1_week",
      },
      {
        label: "2 week",
        value: "2_week",
      },
      {
        label: "1 month",
        value: "1_month",
      },
    ],
  },
  anniversary: {
    label: "Anniversary",
    field: "anniversary",
    type: "select",
    readonly: true,
    operators: [
      {
        label: "In",
        value: "in",
      },
    ],
    values: [
      {
        label: "1 week",
        value: "1_week",
      },
      {
        label: "2 week",
        value: "2_week",
      },
      {
        label: "1 month",
        value: "1_month",
      },
    ],
  },
  tenure: {
    label: "Work Tenure",
    field: "tenure",
    type: "select",
    readonly: false,
    operators: [
      {
        label: ">=",
        value: ">=",
      },
      {
        label: ">",
        value: ">",
      },
      {
        label: "<",
        value: "<",
      },
      {
        label: "<=",
        value: "<=",
      },
    ],
    values: [
      {
        label: "3 months",
        value: 3,
      },
      {
        label: "6 months",
        value: 6,
      },
      {
        label: "1 year",
        value: 12,
      },
      {
        label: "2 years",
        value: 24,
      },
      {
        label: "3 years",
        value: 36,
      },
      {
        label: "4 years",
        value: 48,
      },
      {
        label: "5 years",
        value: 60,
      },
    ],
  },
  group: {
    label: "Group",
    field: "group",
    type: "select",
    readonly: false,
    operators: [
      {
        label: "=",
        value: "=",
      },
    ],
    values: [],
  },
};

const OrderRecurringRecipients = ({ order, setStep, isCreate }) => {
  // const [groups, setGroups] = useState([]);

  const [values, setValues] = useState({
    recurring_occasion: order.recurring_occasion || "onboarding",
    conditions: [],
  });
  const [loading, setLoading] = useState(false);
  const { handleError, handleSuccess } = useError();

  useEffect(() => {
    let getGroups = async () => {
      const { data, err } = await api.controllers.getGroups(order.client_id, 1, -1);
      if (!err) {
        let _groups = data.data;
        // setGroups(_groups);
        conditionOptions.group.values = _groups.map((group) => {
          return {
            label: group.name,
            value: group.id,
          };
        });
      }
    };
    getGroups();
  }, [order.client_id]);

  useEffect(() => {
    let getOrderConditions = async () => {
      let { err, data } = await api.controllers.getOrderConditions(order.id);
      if (!err) {
        let orderConditions = data.data;
        if (orderConditions.length > 0) {
          setValues((values) => ({ ...values, conditions: orderConditions }));
        }
      }
    };
    getOrderConditions();
  }, [order.id]);

  useEffect(() => {
    let conditions = [
      {
        field: values.recurring_occasion,
        operator: conditionOptions[values.recurring_occasion].operators[0].value,
        value: conditionOptions[values.recurring_occasion].values[0].value,
      },
    ];

    setValues((values) => ({ ...values, conditions: conditions }));
  }, [values.recurring_occasion]);

  let onOccasionChange = (occasion) => {
    setValues({ ...values, recurring_occasion: occasion });
  };

  let onFieldChange = (index, field) => {
    let newConditions = [...values.conditions];
    newConditions[index].field = field;
    if (field === "group") {
      newConditions[index].operator = "=";
      newConditions[index].value =
        conditionOptions.group.values.length > 0 ? conditionOptions.group.values[0].value : "";
    } else if (field === "tenure") {
      newConditions[index].operator = conditionOptions["tenure"].operators[0].value;
      newConditions[index].value = conditionOptions["tenure"].values[0].value;
    }

    setValues({ ...values, conditions: newConditions });
  };

  let onOperatorChange = (index, operator) => {
    let newConditions = [...values.conditions];
    newConditions[index].operator = operator;

    setValues({ ...values, conditions: newConditions });
  };

  let onValueChange = (index, value) => {
    let newConditions = [...values.conditions];
    newConditions[index].value = value;

    setValues({ ...values, conditions: newConditions });
  };

  let onConditionAdd = () => {
    let newConditions = [...values.conditions];
    newConditions.push({
      field: "tenure",
      operator: conditionOptions["tenure"].operators[0].value,
      value: conditionOptions["tenure"].values[0].value,
    });
    setValues({ ...values, conditions: newConditions });
  };

  let onConditionDelete = (index) => {
    let newConditions = values.conditions.filter((condition, _index) => _index !== index);
    setValues({ ...values, conditions: newConditions });
  };

  let onSubmit = async (e) => {
    setLoading(true);
    const conditionsWithEmptyString = values.conditions.filter((condition) => condition.value === "");

    if (conditionsWithEmptyString.length > 0) {
      handleError({ message: `${conditionsWithEmptyString[0].field} condition cannot be empty` });
      setLoading(false);
    } else {
      const { err } = await api.controllers.addOrderConditions(order.id, values);
      setLoading(false);

      if (err) {
        handleError(err);
      } else {
        if (isCreate) {
          order.deliver_to === "company" ? setStep("preview") : setStep("message");
        } else {
          handleSuccess("Update successful");
        }
      }
    }
  };

  // const handleEditChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const list = [...values];
  //   list[index][name] = value;
  //   setValues(list);
  // };

  let renderCondition = (condition, index) => {
    let field = condition.field;
    let operator = condition.operator;
    let value = condition.value;
    let option = conditionOptions[field];

    return (
      <div className="flex gap-x-4 mb-4 ml-6" key={`condition-${index}`}>
        <div className="relative w-40">
          <select
            className="border-0 px-3 py-3 placeholder-slate-300 rounded text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
            value={field}
            name="field"
            onChange={(e) => onFieldChange(index, e.target.value)}
          >
            {option.readonly && (
              <>
                <option value={field}>{option.label}</option>
              </>
            )}

            {!option.readonly && (
              <>
                {Object.entries(conditionOptions)
                  .filter((condition) => condition[1].readonly === false)
                  .map(([key, value]) => (
                    <option key={`field-${value.field}`} value={value.field}>
                      {value.label}
                    </option>
                  ))}
              </>
            )}
          </select>
        </div>

        <div className="relative w-40">
          <select
            className="border-0 px-3 py-3 placeholder-slate-300 rounded text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
            value={operator}
            name="operator"
            onChange={(e) => onOperatorChange(index, e.target.value)}
          >
            {conditionOptions[field].operators.map((operator) => (
              <option value={operator.value} key={`operator-${operator.value}`}>
                {operator.label}
              </option>
            ))}
          </select>
        </div>

        <div className="relative w-40">
          <select
            className="border-0 px-3 py-3 placeholder-slate-300 rounded text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
            value={value}
            name="value"
            onChange={(e) => onValueChange(index, e.target.value)}
          >
            {conditionOptions[field].values.map((value) => (
              <option value={value.value} key={`value-${value.value}`}>
                {value.label}
              </option>
            ))}
          </select>
        </div>

        {option.readonly && <div className="w-6"></div>}

        {!option.readonly && (
          <>
            <button onClick={() => onConditionDelete(index)}>
              <img
                src={require("assets/img/order-edit/cart-delete.svg").default}
                alt={"condition-delete"}
                className="w-6"
              />
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="w-full">
        {isCreate && (
          <p className="relative flex text-blue-500 bg-blue-100 rounded-md border border-blue-500 p-3 my-5 w-fit mx-auto">
            <img src={require("assets/img/order-edit/info.svg").default} alt={"info"} className="w-6 mr-2" />
            Make sure that you have uploaded your recipients{" "}
            <Link to={`/clients/${order.client_id}/recipients`} target={"_blank"} className="underline mx-1">
              here
            </Link>{" "}
            before you can use this feature.
          </p>
        )}

        <div className="px-6 py-11 rounded-lg flex flex-col items-center bg-white border border-gray-300">
          <h2 className="text-lg font-bold mb-4">Choose the Occasion</h2>

          {/* Onboarding */}
          <div className="flex justify-center w-full gap-x-10 mb-4">
            <div
              className={`bg-tertiary_gray_four w-56 py-6 flex items-center flex-col text-center rounded-lg cursor-pointer ${
                values.recurring_occasion === "onboarding" ? "border border-primary" : ""
              }`}
              onClick={() => onOccasionChange("onboarding")}
            >
              <img
                src={require("assets/img/onboarding.png").default}
                alt={"Onboarding"}
                className={"w-20 h-20 block mb-2"}
              />
              <h3 className="font-bold">Onboarding</h3>
            </div>

            {/* Birthday */}
            <div
              className={`bg-tertiary_gray_four w-56 py-6 flex items-center flex-col text-center rounded-lg cursor-pointer ${
                values.recurring_occasion === "birthday" ? "border border-primary" : ""
              }`}
              onClick={() => onOccasionChange("birthday")}
            >
              <img
                src={require("assets/img/birthday.png").default}
                alt={"Birthday"}
                className={"w-20 h-20 block mb-2"}
              />
              <h3 className="font-bold">Birthday</h3>
            </div>

            {/* Anniversary */}
            <div
              className={`bg-tertiary_gray_four w-56 py-6 flex items-center flex-col text-center rounded-lg cursor-pointer ${
                values.recurring_occasion === "anniversary" ? "border border-primary" : ""
              }`}
              onClick={() => onOccasionChange("anniversary")}
            >
              <img
                src={require("assets/img/anniversary.png").default}
                alt={"Anniversary"}
                className={"w-20 h-20 block mb-2"}
              />
              <h3 className="font-bold">Anniversary</h3>
            </div>
          </div>

          <h2 className="text-lg font-bold mb-4">Conditions</h2>
          {values.conditions.map((condition, index) => (
            <div key={`condition ${index}`}>{renderCondition(condition, index)}</div>
          ))}

          <div className={`${!isCreate ? "flex gap-4" : ""}`}>
            <button
              className="flex bg-primaryTwo text-white font-medium px-10 py-4 rounded-md shadow mt-4"
              onClick={onConditionAdd}
            >
              <img src={require("assets/img/add-icon.svg").default} alt={"add-icon"} className="w-6 mr-2" />
              Add Condition
            </button>

            {!isCreate && (
              <button
                className="flex bg-primary text-white font-medium px-10 py-4 rounded-md shadow mt-4"
                onClick={onSubmit}
              >
                {loading ? <Spinner /> : "Update"}
              </button>
            )}
          </div>
        </div>
      </div>

      {isCreate && (
        <div className="flex justify-end mt-10 mb-4">
          <div
            className="flex mb-2 py-3 px-8 font-bold text-white border w-fit bg-primary border-primary rounded-md cursor-pointer"
            onClick={onSubmit}
          >
            {loading && <Spinner />} {order.deliver_to === "company" ? "Preview" : "Message"}
            <img src={require("assets/img/arrow-right.svg").default} alt={"arrow-left"} className="w-6 ml-2" />
          </div>
        </div>
      )}
    </>
  );
};

export default OrderRecurringRecipients;
