import Spinner from "components/Spinner";
import React, { useState } from "react";
import Select from "react-select";

function FormDelivery({ values, handleChange, setFormBody, loading, onCheckboxClicked, setValues }) {
  const [setingOpen, setSetingOpen] = useState(false);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled
        ? "gray"
        : state.isSelected
        ? "#d1d5db"
        : state.isFocused
        ? "#d1d5db"
        : undefined,
    }),
  };

  return (
    <div className="w-11/12 mx-auto mb-4">
      <img
        src={require("assets/img/new-order/new-order-recipients-icon.png").default}
        alt={"new-order-recipients-icon"}
        className="w-32 mx-auto py-4"
      />

      <label className="block font-bold text-center text-3xl">How do we send the gifts?</label>

      {/* deliver_to */}
      {values.type === "branded" && (
        <>
          <div className="mt-5 px-4 mb-2 grid grid-cols-2 gap-x-5">
            <div
              className={`mb-2 mt-4 rounded-lg bg-neutral-100 ${
                values.deliver_to === "company" ? "border border-indigo-300" : ""
              }`}
            >
              <div className="flex items-center">
                <input
                  id="to-company"
                  type="radio"
                  name="deliver_to"
                  onChange={handleChange}
                  value="company"
                  checked={values.deliver_to === "company"}
                  className="h-4 w-4 mr-2 border-gray-300 focus:ring-2 focus:ring-blue-300 opacity-0"
                />
                <label htmlFor="to-company" className="block text-center -ml-5 cursor-pointer pb-3 px-3">
                  <img
                    src={require("assets/img/new-order/new-order-deliery-company.png").default}
                    alt={"new-order-deliery-company"}
                    className="w-12 mx-auto pt-4"
                  />
                  <h3 className="font-bold mb-2">Single Address</h3>
                  <p className="text-sm">
                    We will send all gifts to single address of your choice: office, warehouse or event venue. You will
                    handle the logistics to recipients.
                  </p>
                </label>
              </div>
            </div>

            <div
              className={`mb-2 mt-4 rounded-lg bg-neutral-100 ${
                values.deliver_to === "recipients" ? "border border-indigo-300" : ""
              }`}
            >
              <div className="flex items-center ">
                <input
                  id="to-recipients"
                  type="radio"
                  name="deliver_to"
                  onChange={handleChange}
                  value="recipients"
                  checked={values.deliver_to === "recipients"}
                  className="h-4 w-4 mr-2 border-gray-300 focus:ring-2 focus:ring-blue-300 opacity-0"
                />
                <label htmlFor="to-recipients" className="block text-center -ml-5 cursor-pointer pb-3 px-3">
                  <img
                    src={require("assets/img/new-order/new-order-deliery-recipients.png").default}
                    alt={"new-order-deliery-recipients"}
                    className="w-12 mx-auto pt-4"
                  />
                  <h3 className="font-bold mb-2">To Recipients</h3>
                  <p className="text-sm">
                    We will help you collect recipients addresses, sending it to them and delivery tracking. All we need
                    is their email addresses.
                  </p>
                </label>
              </div>
            </div>
          </div>
        </>
      )}

      {/* date  */}
      {!values.recurring ? (
        <div className="w-[97%] mx-auto pt-4 px-4 border border-blue-300 my-4 rounded-lg">
          <div className="grid grid-cols-2 gap-x-5">
            <div className="w-full mb-3">
              <label className="block font-bold">No. Recipients </label>
              <input
                name="recipient_count"
                type="number"
                min={0}
                className="border-0 px-3 py-3 mb-2 placeholder-slate-300 text-slate-600 bg-white border-b-2 border-primary focus:border-primary rounded w-full focus:ring-0"
                placeholder="recipients"
                value={values.recipient_count}
                onChange={handleChange}
              />
            </div>

            <div className="w-full mb-3">
              <label className="block font-bold">Expected Delivery Date</label>
              <input
                name="delivery_date"
                type="date"
                className="border-0 px-3 py-3 mb-2 placeholder-slate-300 text-slate-600 bg-white border-b-2 border-primary focus:border-primary rounded w-full focus:ring-0"
                placeholder="Date"
                value={values.delivery_date}
                onChange={handleChange}
              />
            </div>
          </div>

          {values.selected_countries && (
            <>
              <label className="block font-bold">Delivery Countries</label>
              <Select
                className="basic-single mb-3 py-3 rounded bg-white border-transparent"
                classNamePrefix="select"
                placeholder={"Choose available countries option"}
                onChange={(e) => {
                  setValues({ ...values, countries: e });
                }}
                defaultValue={values.countries[0]}
                isMulti
                styles={customStyles}
                isClearable={false}
                isSearchable={true}
                instanceId={"postType"}
                name="country"
                formatOptionLabel={(item) => <span className="text-black">{item.label}</span>}
                options={[
                  { value: "indonesia", label: "Indonesia" },
                  { value: "singapore", label: "Singapore" },
                  { value: "malaysia", label: "Malaysia" },
                  { value: "thailand", label: "Thailand" },
                  { value: "vietnam", label: "Vietnam" },
                  { value: "india", label: "India" },
                  { value: "philippines", label: "Philippines" },
                ]}
              />
            </>
          )}

          {/* Claimable */}
          {values.can_expire && (
            <div className="w-full mb-3">
              <label className="font-bold">
                Claimable Until <span className="text-red-500">*</span>
              </label>
              <input
                name="claimable_until"
                type="date"
                className="border-0 px-3 py-3 mb-2 placeholder-slate-300 text-slate-600 bg-white border-b-2 border-primary focus:border-primary rounded w-full focus:ring-0"
                placeholder="Date"
                value={values.claimable_until}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="px-4 my-4">
          <div className={`w-full p-3 bg-gray-50 border rounded-lg`}>
            {/* Recurring  */}
            <div className="">
              <label className="font-bold">Recurring Until</label>
              <p className="text-sm mb-4">
                Leave empty if you want to run this campaign continuously until manually deactivated.
              </p>
              <input
                name="recurring_until"
                type="date"
                className="border-0 px-3 py-3 mb-2 placeholder-slate-300 text-slate-600 bg-white border-b-2 border-primary focus:border-primary rounded w-full focus:ring-0"
                placeholder="Date"
                value={values.recurring_until}
                onChange={handleChange}
              />
            </div>

            {/* Claimable  */}
            {/* {values.can_expire && (
              <div className="flex flex-col justify-between">
                <label className="font-bold">
                  Claimable Until <span className="text-red-500">*</span>
                </label>    

                <input
                  name="claimable_until"
                  type="date"
                  className="border-0 px-3 py-3 mb-2 placeholder-slate-300 text-slate-600 bg-white border-b-2 border-primary focus:border-primary rounded w-full focus:ring-0"
                  placeholder="Date"
                  value={values.claimable_until}
                  onChange={handleChange}
                />
              </div>
            )} */}
          </div>
        </div>
      )}

      {values.deliver_to !== "company" && (
        <div className="px-4">
          {/* setting  */}
          <div
            onClick={() => setSetingOpen((prev) => !prev)}
            className="flex justify-between items-center bg-neutral-100 p-4 text-lg rounded-lg cursor-pointer"
          >
            <div className="flex items-center">
              <i className="mr-2 fas fa-cog"></i>
              <p className="font-medium">Settings</p>
            </div>
            <i className="fas fa-angle-down"></i>
          </div>

          <div className={`bg-neutral-100 text-lg rounded-lg mt-4 ${setingOpen ? "block" : "hidden"}`}>
            {/* skip_intro */}
            <div className="flex justify-between items-center p-4">
              <div className="flex items-center">
                <input
                  name="skip_intro"
                  type="checkbox"
                  className="border-gray-300 rounded cursor-pointer shadow mr-2"
                  checked={values.skip_intro || values.skip_intro === "true" ? true : false}
                  onChange={onCheckboxClicked}
                />
                <label className={`font-medium text-base`}>Skip Intro?</label>
              </div>

              <div className="relative group">
                <i className="far fa-question-circle text-blue-500"></i>
                <div
                  className={`absolute z-20 hidden group-hover:block right-0 shadow-lg rounded-lg text-sm bg-[#F0F5FA] p-4 w-96`}
                >
                  Checking this option means that your recipients will skip the accepted gift page and be redirected
                  immediately to choose a gift or variation (skipping the accepted gift page after the gift wrap
                  animation if you choose to use our wrap animation).
                </div>
              </div>
            </div>

            {/* Countries */}
            <div className="flex justify-between items-center p-4">
              <div className="flex items-center">
                <input
                  name="selected_countries"
                  type="checkbox"
                  className="border-gray-300 rounded cursor-pointer shadow mr-2"
                  checked={values.selected_countries || values.selected_countries === "true" ? true : false}
                  onChange={onCheckboxClicked}
                />
                <label className={`font-medium text-base`}>Countries?</label>
              </div>

              <div className="relative group">
                <i className="far fa-question-circle text-blue-500"></i>
                <div
                  className={`absolute z-20 hidden group-hover:block right-0 shadow-lg rounded-lg text-sm bg-[#F0F5FA] p-4 w-96`}
                >
                  Checking this option means that your recipients addresses countries options is limited to what you
                  have choosen.
                </div>
              </div>
            </div>

            {/* activate */}
            <div className="flex justify-between items-center p-4">
              <div className="flex items-center">
                <input
                  name="activate"
                  type="checkbox"
                  className="border-gray-300 rounded cursor-pointer shadow mr-2"
                  checked={values.activate || values.activate === "true" ? true : false}
                  onChange={onCheckboxClicked}
                />
                <label className={`font-medium text-base ${values.recurring ? "text-tertiary_gray_two" : ""}`}>
                  Activate?
                </label>
              </div>

              <div className="relative group">
                <i className="far fa-question-circle text-blue-500"></i>
                <div
                  className={`absolute z-20 hidden group-hover:block right-0 shadow-lg rounded-lg text-sm bg-[#F0F5FA] p-4 w-96 ${
                    values.recurring ? "text-tertiary_gray_two" : ""
                  }`}
                >
                  {" "}
                  Checking this option means that you won't enter your recipients detail. Gifts will be claimed via link
                  and information entered during claim process.
                </div>
              </div>
            </div>

            {/* recurring */}
            <div className="flex justify-between items-center p-4">
              <div className="flex items-center">
                <input
                  name="recurring"
                  type="checkbox"
                  className="border-gray-300 rounded cursor-pointer shadow mr-2"
                  checked={values.recurring || values.recurring === "true" ? true : false}
                  onChange={onCheckboxClicked}
                />
                <label className={`font-medium text-base ${values.activate ? "text-tertiary_gray_two" : ""}`}>
                  Recurring?
                </label>
              </div>

              <div className="relative group">
                <i className="far fa-question-circle text-blue-500"></i>
                <div
                  className={`absolute z-20 hidden group-hover:block right-0 shadow-lg rounded-lg text-sm bg-[#F0F5FA] p-4 w-96 ${
                    values.activate ? "text-tertiary_gray_two" : ""
                  }`}
                >
                  {" "}
                  Checking this option means that this campaign will be run continuously based on the conditions that
                  you set.
                </div>
              </div>
            </div>

            {/* Multiple */}
            <div className="flex justify-between items-center p-4">
              <div className="flex items-center">
                <input
                  name="multiple_items"
                  type="checkbox"
                  className="border-gray-300 rounded cursor-pointer shadow mr-2"
                  checked={values.multiple_items || values.multiple_items === "true" ? true : false}
                  onChange={onCheckboxClicked}
                  disabled={values.activate}
                />
                <label className={`font-medium text-base ${values.activate ? "text-tertiary_gray_two" : ""}`}>
                  Multiple items?
                </label>
              </div>

              <div className="relative group">
                <i className="far fa-question-circle text-blue-500"></i>
                <div
                  className={`absolute z-20 hidden group-hover:block right-0 shadow-lg rounded-lg text-sm bg-[#F0F5FA] p-4 w-96 ${
                    values.activate ? "text-tertiary_gray_two" : ""
                  }`}
                >
                  {" "}
                  Checking this option will allow recipients to choose multiple items as long as there is enough budget.
                </div>
              </div>
            </div>

            {/* Can Expire */}
            <div className="flex justify-between items-center p-4">
              <div className="flex items-center">
                <input
                  name="can_expire"
                  type="checkbox"
                  className="border-gray-300 rounded cursor-pointer shadow mr-2"
                  checked={values.can_expire || values.can_expire === "true" ? true : false}
                  onChange={onCheckboxClicked}
                  disabled={values.recurring}
                />
                <label className={`font-medium text-base ${values.recurring ? "text-tertiary_gray_two" : ""}`}>
                  Can Expire?
                </label>
              </div>

              <div className="relative group">
                <i className="far fa-question-circle text-blue-500"></i>
                <div
                  className={`absolute z-20 hidden group-hover:block right-0 shadow-lg rounded-lg text-sm bg-[#F0F5FA] p-4 w-96 ${
                    values.recurring ? "text-tertiary_gray_two" : ""
                  }`}
                >
                  Checking this option will make the campaign claimable until the date you set.
                </div>
              </div>
            </div>

            {/* Request Address */}
            {values.recurring || values.activate ? (
              <></>
            ) : (
              <div className="flex justify-between items-center p-4">
                <div className="flex items-center">
                  <input
                    name="request_address"
                    type="checkbox"
                    className="border-gray-300 rounded cursor-pointer shadow mr-2"
                    checked={values.request_address || values.request_address === "true" ? true : false}
                    onChange={onCheckboxClicked}
                  />
                  <label className={`font-medium text-base`}>Request Address?</label>
                </div>

                <div className="relative group">
                  <i className="far fa-question-circle text-blue-500"></i>
                  <div
                    className={`absolute z-20 hidden group-hover:block right-0 shadow-lg rounded-lg text-sm bg-[#F0F5FA] p-4 w-96`}
                  >
                    {" "}
                    Checking this option will allow recipients to be notified to update their address. If unchecked, the
                    current address in database will be used.
                  </div>
                </div>
              </div>
            )}

            {/* Request Location */}
            <div className="flex justify-between items-center p-4">
              <div className="flex items-center">
                <input
                  name="request_location"
                  type="checkbox"
                  className="border-gray-300 rounded cursor-pointer shadow mr-2"
                  checked={values.request_location || values.request_location === "true" ? true : false}
                  onChange={onCheckboxClicked}
                />
                <label className={`font-medium text-base`}>Request Location?</label>
              </div>
              <div className="relative group">
                <i className="far fa-question-circle text-blue-500"></i>
                <div
                  className={`absolute z-20 hidden group-hover:block right-0 shadow-lg rounded-lg text-sm bg-[#F0F5FA] p-4 w-96`}
                >
                  Checking this option will require recipients to pinpoint their location using map.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-center items-center gap-x-5 w-fit mx-auto mb-2 mt-8">
        <div
          className="py-3 px-10 w-fit bg-white text-primary font-bold cursor-pointer mx-auto flex items-center rounded-lg border border-primary"
          onClick={() => {
            setFormBody("type");
            setValues({
              ...values,
              activate: false,
              recipient_count: 0,
              request_address: true,
              recurring: false,
              can_expire: false,
            });
          }}
        >
          <img
            src={require("assets/img/arrow-right.svg").default}
            alt={"home-no-campaign-icon"}
            className="w-4 h-4 mr-2 svg_orange rotate-180"
          />
          <p>back</p>
        </div>

        <button
          className="py-3 px-10 w-fit bg-primary text-white font-bold cursor-pointer mx-auto flex items-center rounded-lg"
          type="submit"
        >
          {loading && <Spinner size={4} />} Create
        </button>
      </div>
    </div>
  );
}

export default FormDelivery;
