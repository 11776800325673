import React, { useCallback, useEffect, useState } from "react";

import Table from "components/Table/Table.js";
import api from "lib/api";
import { useHistory, useParams } from "react-router-dom";

export default function ClientUserList() {
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  const fetchClientUsers = useCallback(async () => {
    const { data, err } = await api.controllers.getClientUsers(id, pagination.page, pagination.limit);
    if (!err) {
      setData(data);
    }
  }, [id, pagination]);

  useEffect(() => {
    pagination && fetchClientUsers();
  }, [pagination, fetchClientUsers]);

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Table
            settings={{
              title: `Users`,
              button: {
                label: "Add",
                onClick: () => {
                  history.push(`/clients/${id}/users/new`);
                },
              },
              headers: [
                {
                  label: "Name",
                  field: "name",
                },
                {
                  label: "Email",
                  field: "email",
                },
                {
                  label: "Phone Number",
                  field: "phone_number",
                },
                {
                  label: "Status",
                  render: (element) => {
                    return element.active ? "Active" : "Not Active";
                  },
                },
              ],
            }}
            data={data}
            setPagination={setPagination}
          />
        </div>
      </div>
    </>
  );
}
