import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Table from "components/Table/Table.js";
import api from "lib/api";

export default function TagList() {
  const [data, setData] = useState({});
  const history = useHistory();
  const [pagination, setPagination] = useState(null);
  const [filterValues, setFilterValues] = useState(null);

  const getTags = useCallback(async () => {
    const { data, err } = await api.controllers.getTags(pagination.page, pagination.limit, filterValues);
    if (!err) {
      setData(data);
    }
  }, [filterValues, pagination]);

  useEffect(() => {
    pagination && getTags();
  }, [pagination, getTags]);

  useEffect(() => {
    filterValues && getTags();
  }, [filterValues, getTags]);

  let onFilter = async (_values) => {
    setFilterValues(_values);
  };

  // useEffect(() => {
  //   const get = async () => {
  //     const { data, err } = await api.controllers.getTags(pagination.page, pagination.limit);
  //     if (!err) {
  //       setData(data);
  //     }
  //   };
  //   pagination && get();
  // }, [pagination]);

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Table
            settings={{
              title: "Tags",
              button: {
                label: "Add",
                onClick: () => {
                  history.push("/tags/new");
                },
              },
              headers: [
                {
                  label: "Name",
                  render: (element) => {
                    return (
                      <Link to={`/tags/${element.id}`} className={"underline"}>
                        {element.name}
                      </Link>
                    );
                  },
                },
                {
                  label: "Position",
                  field: "position",
                },
                {
                  label: "Type",
                  field: "type",
                },
                {
                  label: "Status",
                  render: (element) => {
                    return element.active ? "Active" : "Not Active";
                  },
                },
              ],
              filters: [
                {
                  label: "Name",
                  field: "name",
                },
                {
                  label: "Type",
                  field: "type",
                  inputType: "select",
                  options: [
                    {
                      label: "Occasion",
                      value: "occasion",
                    },
                    {
                      label: "Gender",
                      value: "gender",
                    },
                    {
                      label: "Age",
                      value: "age",
                    },
                    {
                      label: "Level",
                      value: "level",
                    },
                    {
                      label: "Package",
                      value: "package",
                    },
                  ],
                },
              ],
            }}
            data={data}
            setPagination={setPagination}
            onFilter={onFilter}
          />
        </div>
      </div>
    </>
  );
}
